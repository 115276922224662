<template>
  <div class="flex flex-col xs:flex-row h-full overflow-hidden" :class="{ 'cursor-wait': loading }">
    <aside
      class="bg-white xs:shadow-2xl w-full xs:w-1/2 sm:w-1/3 xs:flex flex-col relative xs:p-4 overflow-y-auto scrolling-touch"
      :class="{ 'flex flex-grow': mobile_show_options, hidden: !mobile_show_options }"
    >
      <div
        id="designer_sidebar_scrolling_container"
        ref="scroll-container"
        class="xs:flex-shrink-0 xs:flex-grow xs:h-px md:p-6 scrolling-touch relative"
        style="scroll-behavior: smooth"
      >
        <template v-if="item && currentImageType">
          <issues
            v-if="hasIssues"
            :issues="item.issues"
            class="bg-red-600 text-white xs:-m-6 mb-6 relative z-10 xs:hidden"
            @show-invalidated-popup="showInvalidSelectionPopup"
          ></issues>

          <div v-if="somethingIsSelected" class="py-3">
            <button
              class="w-full text-center text-2xs cursor-pointer border p-2 pr-8 rounded relative uppercase tracking-widest"
              @click="designerStore.CLEAR_SELECTION()"
            >
              Finished Editing Components
              <div class="absolute right-0 top-0 bottom-0 p-2">
                <i class="fal fa-check-square"></i>
              </div>
            </button>
          </div>
          <div v-if="sandboxStore.sandboxIsActive" class="bg-yellow-400 p-2 rounded">
            <span>YOU ARE NOW PROCESSING IN <strong>{{ sandboxStore.sandboxName }}</strong> SANDBOX</span>
          </div>
          <div v-if="!somethingIsSelected" class="2xs:flex sm:flex-col md:flex-row my-3">
            <div
              :class="{
                'text-white bg-black': showSizePanel,
                'text-gray-700': !showSizePanel,
              }"
              class="text-center text-2xs cursor-pointer border p-2 pr-8 flex-1 2xs:mr-1 sm:mr-0 md:mr-1 rounded relative uppercase tracking-widest"
              @click.prevent="designerStore.SET_OPEN_MENU_TYPE('sizes')"
            >
              Edit Measurements
              <div class="absolute right-0 top-0 bottom-0 p-2">
                <i class="fal fa-tape"></i>
              </div>
            </div>
            <div
              :class="{
                'text-white bg-black': showSpecificationPanel,
                'text-gray-700': !showSpecificationPanel,
              }"
              class="text-center text-2xs cursor-pointer border p-2 pr-8 flex-1 2xs:ml-1 sm:ml-0 md:ml-1 rounded relative mt-1 2xs:mt-0 sm:mt-1 md:mt-0 uppercase tracking-widest"
              @click.prevent="designerStore.SET_OPEN_MENU_TYPE('specification')"
            >
              Customise Product
              <div class="absolute right-0 top-0 bottom-0 p-2">
                <i class="fal fa-swatchbook"></i>
              </div>
            </div>
          </div>
          <animation-staggered-fade>
            <dimension
              v-for="(dimension, index) in item.dimensions"
              v-show="showSizePanel"
              :key="dimension.id"
              :ref="`dimension-${dimension.id}`"
              :dimension-highlight="dimensionHighlight"
              :data-index="index"
              :dimension="dimension"
              :please-wait="loading"
              @change-dimension="changeDimension(dimension.id, $event, index)"
              @dimension-highlight="dimensionHighlight = $event"
            />
            <specification-group
              v-for="(specGroup, key) in specificationGroupsLocationOnly"
              v-show="showSizePanel"
              :key="key"
              :selection-mode="somethingIsSelected"
              :group="specGroup"
              :data-index="key"
              :loading="loading"
              :no-collapse="true"
              @change="
                updateOption(
                  $event.heading,
                  $event.value,
                  $event.components,
                  $event.members,
                  $event.text,
                  $event.parameters,
                )"
              @component-highlight="componentHighlight = $event"
            />
          </animation-staggered-fade>
          <animation-staggered-fade>
            <specification-group
              v-for="(specGroup, key) in specificationGroupsWithoutLocation"
              v-show="showSpecificationPanel"
              :key="key"
              :selection-mode="somethingIsSelected"
              :group="specGroup"
              :data-index="key"
              :loading="loading"
              @change="
                updateOption(
                  $event.heading,
                  $event.value,
                  $event.components,
                  $event.members,
                  $event.text,
                  $event.parameters,
                )"
              @component-highlight="componentHighlight = $event"
            />
          </animation-staggered-fade>
          <slot name="extras"></slot>
        </template>
        <div class="pb-20"></div>
      </div>
    </aside>
    <main
      class="bg-gray-200 w-full flex flex-col flex-grow xs:block xs:w-1/2 sm:w-2/3 overflow-y-auto scrolling-touch p-6 relative"
      :class="{ hidden: !mobile_show_image }"
    >
      <div v-if="item" class="text-center flex flex-col space-around h-full xs:pb-10">
        <issues
          v-if="hasIssues"
          :issues="item.issues"
          class="bg-red-600 text-white -m-6 text-center relative z-10 hidden xs:block"
          @show-invalidated-popup="showInvalidSelectionPopup"
        ></issues>
        <transition name="fade-in" appear>
          <div class="flex flex-col h-full justify-center">
            <div
              class="w-full xl:w-5/6 xs:pb-10 flex flex-col mx-auto h-full"
              :class="{ 'xs:pt-12': hasIssues }"
            >
              <div
                v-if="isQuickTip"
                class="quick-tip bg-touch-purple rounded absolute left-1/2 transform p-4 text-left max-w-xs -mt-40 md:mt-0"
                :style="{ top: '25%', translate: 'transform(50%, 25%)' }"
              >
                <div class="flex items-center mb-2">
                  <strong class="text-white">Quick Tip</strong>
                  <div
                    class="pl-6 flex flex-col text-xl justify-around text-white cursor-pointer ml-auto"
                    @click="designerStore.setIsQuickTip(false), (isQuickTip = false)"
                  >
                    <i class="fal fa-times"></i>
                  </div>
                </div>
                <div class="text-white text-thin">
                  Click on areas of the image to customise specific parts of the product. Clickable areas include  frame,
                  glass and others parts of this product.
                  <br><br>
                  For example, select the glass to change its type, add decorations, spacers, or more.
                </div>
              </div>
              <ShowSvg
                v-if="current_image"
                :permission-highlight-areas="true"
                :svg="current_image.image"
                :show-dimensions="true"
                :dimension-highlight="dimensionHighlight"
                :interaction-highlight="componentHighlight"
                class="mt-auto h-full flex flex-col"
                :show-change-bay-designs="changeBayDesignsMode"
                @dimension-highlight="dimensionHighlight = $event"
                @change-dimension="changeDimension($event.dimensionId, $event.value)"
                @design-selector-clicked="designSelectorClicked($event)"
                @selector-click="selectorClicked($event)"
                @show-quick-tip="$emit('showQuickTip')"
              />
            </div>
            <div class="text-xs">
              Images are for illustration purposes only and are not to scale
            </div>
            <div class="mt-1 flex justify-center">
              <template v-if="(current_image || canChangeBayDesigns)">
                <buttons-for-image
                  :can-change-bay-designs="canChangeBayDesigns"
                  :changing-design="changeBayDesignsMode"
                  :images="item.images"
                  :current-image-type="currentImageType"
                  @show-image-type="$emit('setCurrentImageTypeParent', $event);"
                  @change-design="changeBayDesignsMode ? (changeBayDesignsMode = false) : activateChangeBays()"
                ></buttons-for-image>
                <info-popup
                  :id="'designer:switch_view'"
                  :info_message="'See both the inside and outside of the product'"
                  :next_id="'designer_header:basket'"
                ></info-popup>
              </template>
            </div>
          </div>
        </transition>
      </div>
      <div class="text-center hidden xs:block fixed bottom-0 right-0 m-3">
        <a
          :href="$t(store_url)"
          target="_blank"
          class="inline-flex bg-white border border-solid border-gray-400 rounded p-1"
        >
          <div class="p-1">
            <img src="/images/touch-logo.svg" width="50px" />
          </div>
          <div class="">
            <img src="/images/touch-portal-icon.png" style="max-height: 22px" />
          </div>
          <div class="p-1 text-2xs">By BM Group</div>
        </a>
      </div>
    </main>
    <div
      class="p-4 flex justify-between xs:hidden bg-gray-200 border-t border-gray-400 flex-shrink-0"
    >
      <div>
        <div
          v-if="mobile_show_options"
          class="border text-center cursor-pointer transition duration-500 ease-in-out bg-white rounded-full flex flex-col justify-around text-base w-10 h-10 hover-bg-brand-primary_light"
          @click.prevent="(mobile_show_image = true), (mobile_show_options = false)"
        >
          <i class="fal fa-th-large"></i>
        </div>
        <div
          v-if="mobile_show_image"
          class="border text-center cursor-pointer transition duration-500 ease-in-out bg-white rounded-full flex flex-col justify-around text-base w-10 h-10 hover-bg-brand-primary_light"
          @click.prevent="(mobile_show_options = true), (mobile_show_image = false)"
        >
          <i class="fal fa-list"></i>
        </div>
      </div>
      <div>
        <router-link
          to="/basket"
          type="button"
          class="btn-action btn-lg"
          :class="{ 'pointer-events-none': loading }"
        >
          Save To {{ basketStore.buttonName }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useBasketStore } from '@/pinia/basket';
import { useSandboxStore } from '@/pinia/sandbox';
import { useDesignerStore } from '@/pinia/designer';
import ButtonsForImage from '@/components/shared/designer/ButtonsForImage.vue';
import Dimension from '@/components/shared/Dimension.vue';
import Issues from '@/components/shared/designer/Issues.vue';
import SpecificationGroup from '@/components/shared/designer/SpecificationGroup.vue';
import ShowSvg from '@/components/shared/ShowSvg.vue';
import _ from 'lodash';

export default {
  components: {
    'specification-group': SpecificationGroup,
    'buttons-for-image': ButtonsForImage,
    dimension: Dimension,
    issues: Issues,
    ShowSvg
  },
  props: {
    customer: {
      default: null,
      type: Object,
    },
    currentImageType: Number,
    item: Object,
    somethingIsSelected: [Boolean, String]
  },
  data() {
    return {
      changeBayDesignsMode: false,
      loading: true,
      dimensionToFocus: undefined,
      componentHighlight: null,
      dimensionHighlight: null,
      open_search: null,
      image_toggle_hover: false,
      reset_hover: false,
      mobile_show_image: false,
      mobile_show_options: true,
      option_hovered: -1,
      extras_open: false,
      isQuickTip: false
    };
  },
  computed: {
    ...mapStores(useBasketStore, useSandboxStore, useDesignerStore),
    defaultInputDimension() {
      try {
        const match =
          this.item.dimensions.find((element) => element.requiresInput && !element.hasBeenInput) ||
          this.item.dimensions[0];
        return match.id;
      } catch (e) {
        return '';
      }
    },
    customerName() {
      try {
        return this.customer.company.name;
      } catch (e) {
        return 'Loading';
      }
    },
    customerId() {
      try {
        return this.customer.customerId;
      } catch (e) {
        return 'Loading';
      }
    },
    specificationGroupsLocationOnly() {
      return this.designerStore.specificationGroups.filter((group) => group[0] === 'Location');
    },
    specificationGroupsWithoutLocation() {
      return this.designerStore.specificationGroups.filter((group) => group[0] !== 'Location');
    },
    showSpecificationPanel() {
      return this.somethingIsSelected || this.designerStore.openMenuType === 'specification';
    },
    showSizePanel() {
      return !this.somethingIsSelected && this.designerStore.openMenuType === 'sizes';
    },
    extrasForThisItem() {
      return this.basketStore.basket.filter(
        (item) =>
          item.inputType === window.enum.inputType.CUSTOMER &&
          item.parentItemKey === parseInt(this.$route.params.id, 10),
      );
    },
    hasIssues() {
      return this.item && this.item.issues.length > 0;
    },
    canChangeBayDesigns() {
      return _.find(
        this.item.images,
        (image) => image.type === window.enum.imageType.EXTERNAL,
      ).image.includes('svg_Design_Selector_Click');
    },
    current_image() {
      if (this.currentImageType === null) {
        return null;
      }

      for (let i = 0; i < this.item.images.length; i += 1) {
        if (this.currentImageType === this.item.images[i].type) {
          return this.item.images[i];
        }
      }

      throw new Error(`Unable to find image for type: ${this.currentImageType}`);
    },
  },
  watch: {
    loading(isLoading) {
      try {
        if (!isLoading && this.dimensionToFocus) {
          this.$nextTick(() => {
            this.$refs[`dimension-${this.dimensionToFocus}`][0].$el.focus();
            this.dimensionToFocus = undefined;
          });
        }
      } catch (e) {
        // do nothing if fails
      }
    },
    currentImageType(newType) {
      if (newType !== window.enum.imageType.EXTERNAL) {
        this.changeBayDesignsMode = false;
      }
    },
    'designerStore.specificationGroups'(is) {
      if (is.length === 0) {
        this.designerStore.CLEAR_SELECTION();
      }
    },
    'designerStore.openMenuType': {
      handler(menuType) {
        if (menuType === 'sizes') {
          this.$nextTick(() => {
            const ref = this.$refs[`dimension-${this.defaultInputDimension}`];
            if (ref && ref[0]?.$el) {
              ref[0].$el.focus();
            }
          });
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    this.loading = false;
    this.showInvalidSelectionIssue();
    if (this.$route.query.tab === 'measurements') {
      this.designerStore.SET_OPEN_MENU_TYPE('sizes');
    }
    this.showQuickTip();
  },
  methods: {
    tableOfOptions(specs) {
      const prefix = '<ul class="pt-3">';
      const suffix = '</ul>';

      const rows = specs
        .map((spec) => {
          const options = spec.options.filter((option) => option.isCurrentOption);
          let selectedOptionValue = 'No Selection';

          if (options.length === 1) {
            selectedOptionValue = options[0].description;
          }

          return `<li class="w-full"><strong>${spec.name}</strong> is now <strong>(${selectedOptionValue})</strong></li>`;
        })
        .join('');

      return `${prefix}${rows}${suffix}`;
    },
    showInvalidSelectionIssue() {
      const invalidatedOptions = this.item.specification.filter(
        (spec) =>
          spec.optionSetByType === this.enums.optionSetByTypes.PREVIOUS_SELECTION_INVALIDATED,
      );

      if (invalidatedOptions.length === 0) {
        return;
      }

      this.item.issues.push({
        componentId: '',
        description: '',
        severityType: 'Inform',
        severityTypeId: this.enums.IssueSeverityEnum.INFORM,
        type: 'InvalidatedOptions',
        typeId: -1,
      });
    },
    showInvalidSelectionPopup() {
      const invalidatedOptions = this.item.specification.filter(
        (spec) =>
          spec.optionSetByType === this.enums.optionSetByTypes.PREVIOUS_SELECTION_INVALIDATED,
      );

      if (invalidatedOptions.length === 0) {
        return;
      }

      this.alertBox().fire({
        title: 'Some options were invalidated',
        html: `<p>Please review following options</p> ${this.tableOfOptions(invalidatedOptions)}`,
        icon: 'warning',
        showCancelButton: true,
        showConfirmButton: false,
        confirmButtonText: 'Accept Defaults',
        cancelButtonText: 'Back',
      });
    },
    componentsToModify(component) {
      if (!this.somethingIsSelected) {
        return true;
      }

      if (this.designerStore.interactiveHighlightState.currentSelectionType === 'component') {
        return this.designerStore.interactiveHighlightState.selectedComponents
          .map((selectedComponent) => selectedComponent.id)
          .includes(component);
      }

      return false;
    },
    membersToModify(member) {
      if (!this.somethingIsSelected) {
        return true;
      }

      if (this.designerStore.interactiveHighlightState.currentSelectionType === 'component') {
        return this.designerStore.interactiveHighlightState.selectedComponents
          .flatMap((selectedComponent) => selectedComponent.memberIds)
          .includes(member);
      }

      if (this.designerStore.interactiveHighlightState.currentSelectionType === 'member') {
        return member === this.designerStore.interactiveHighlightState.clickedMemberId;
      }

      return false;
    },
    deleteLineItem(lineItemId) {
      this.alertBox()
        .fire({
          title: 'Are you sure you want to remove this item?',
          text: 'This action cannot be undone.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.basketStore.deleteLineItem({
              lineItemId,
            })
              .then(() => {
                this.alertBox().fire({
                  title: 'Item deleted',
                  icon: 'success',
                });
              });
            this.$emit('setBreakdown');
          }
        });
    },
    activateChangeBays() {
      this.$emit('setCurrentImageTypeParent', window.enum.imageType.EXTERNAL);
      this.$nextTick(() => {
        this.changeBayDesignsMode = true;
      });
    },
    async selectorClicked(data) {
      this.designerStore.selectorClicked(data);
    },
    async designSelectorClicked(componentId) {
      if (!this.loading) {
        const { width, height } = await this.basketStore.getComponentDimensions(componentId);
        if (this.touch_business_installation) {
          this.routerPush(
            `/customer/${this.$route.params.customerId}/sales-dashboard/switch-design/${this.$route.params.id}/${componentId}?width=${width}&height=${height}`,
          );
        } else {
          this.routerPush(
            `/switch-design/${this.$route.params.id}/${componentId}?width=${width}&height=${height}`,
          );
        }
      }

      this.loading = false;
    },
    async changeDimension(dimensionId, value, index) {
      if (index !== undefined) {
        const nextDimension = this.item.dimensions[index + 1];
        if (nextDimension) {
          this.dimensionToFocus = nextDimension.id;
        }
      }

      if (!this.loading) {
        this.loading = true;
        const item = await this.basketStore.updateItemDimension({
          itemKey: this.$route.params.id,
          dimensionId,
          value,
        });
        await this.$emit('setBreakdown', item);
      }
      this.loading = false;
      this.showInvalidSelectionIssue();
    },
    async applyOptionUpdate(heading, value, components, members, text, rule = 0, parameters) {
      this.loading = true;
      const item = await this.basketStore.updateItemOption({
        itemKey: this.$route.params.id,
        heading,
        value,
        components: components.filter(this.componentsToModify),
        parameters,
        members: members.filter(this.membersToModify),
        text,
        rule,
      });

      await this.$emit('setBreakdown', item);

      this.loading = false;
      this.showInvalidSelectionIssue();

      if (!this.somethingIsSelected) {
        setTimeout(() => {
          const specificationGroupElement = document.querySelectorAll(
            `[data-specification-group-id="${this.designerStore.openSpecificationGroup}"]`,
          )[0];
          if (specificationGroupElement) {
            document.getElementById('designer_sidebar_scrolling_container').scrollTop =
              specificationGroupElement.offsetTop;
          }
        }, 100);
      }
    },
    async updateOption(heading, value, components, members, text, parameters) {
      if (!this.loading) {
        const foundSpecification = this.item.specification.find(
          (spec) => spec.optionHeadingId === heading,
        );

        if (!this.somethingIsSelected && foundSpecification.rules.length > 0) {
          if (foundSpecification.rules.length === 1) {
            return this.applyOptionUpdate(
              heading,
              value,
              components,
              members,
              text,
              foundSpecification.rules[0].id,
              parameters,
            );
          }

          const foundOption = foundSpecification.options.find((option) => option.id === value);

          if (!foundOption.omitRules) {
            window.actOnRule = (id) => {
              this.applyOptionUpdate(heading, value, components, members, text, id, parameters);
              this.alertBox().close();
            };

            return this.alertBox().fire({
              title: 'Select where to apply the change',
              html: foundSpecification.rules
                .map(
                  (currentRule) =>
                    `<button onclick="actOnRule(${currentRule.id})" class="btn btn-action m-2">${currentRule.description}</button>`,
                )
                .join(''),
              showCancelButton: true,
              showConfirmButton: false,
            });
          }
        }

        return this.applyOptionUpdate(heading, value, components, members, text, 0, parameters);
      }

      return false;
    },
    designAnother() {
      this.alertBox()
        .fire({
          customClass: {
            cancelButton: 'absolute top-0 right-0 p-3',
            confirmButton: 'btn-action m-1',
            denyButton: 'btn m-1',
          },
          title: 'Switch Product',
          text: 'Would you like to go back and select a different product?',
          icon: 'question',
          className: 'relative',
          showCancelButton: true,
          showDenyButton: true,
          denyButtonText: 'Yes, and delete my current product',
          confirmButtonText: 'Yes, but save my current product',
          cancelButtonText: '<i class="fal fa-times"></i>',
        })
        .then(async (result) => {
          if (result.isDismissed) {
            return;
          }

          if (!result.isConfirmed) {
            this.basketStore.deleteLineItem({
              lineItemId: this.$route.params.id,
            });
          }
          if (this.touch_business_installation) {
            this.routerPush(`/customer/${this.customerId}/sales-dashboard/choose-product`);
          } else {
            this.routerPush('/choose-product');
          }
        });
    },
    showQuickTip() {
      if (this.designerStore.isQuickTip) {
        setTimeout(() => {
          this.isQuickTip = this.designerStore.isQuickTip;
        }, 1500);
      } else {
        const now = new Date();
        const inputDate = new Date(this.designerStore.quickTipClosed);
        const diffTime = now - inputDate;
        const diffMonths = diffTime / (1000 * 60 * 60 * 24 * 30);
        if (diffMonths > 1) {
          this.setIsQuickTip(true)
          this.showQuickTip()
        }
      }
    },
  },
};
</script>
<style scoped>
.button-3d-active {
  background-color: #d6f0df;
  border-color: theme("colors.green.400");
}

.quick-tip:before {
  content: "";
  position: absolute;
  top: 99%;
  left: 20px;
  width: 0;
  border-top: 20px solid #6f4a97;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
}
</style>
