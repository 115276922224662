<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Customer Management', '/customer'],
          [`Customer (${customerName})`, `/customer/${customerId}`],
          ['Sales Dashboard', `/customer/${customerId}/sales-dashboard`],
          ['Basket'],
        ]"
      />
    </template>
    <template #header_page_actions>
      <div class="px-3 md:px-10 flex items-center gap-3">
        <div v-if="availableBranches.length > 0">
          <FilterDropdown
            label="Branch"
            query-label="branchFilter"
            :filters="availableBranches"
            :default-required="true"
          />
        </div>
      </div>
    </template>
    <Basket
      ref="basket"
      :customer="customer"
      :customer-id="customerId"
      :can-skip-sop="canSkipSop"
      :show-place-order-button="showPlaceOrderButton"
      :show-save-product-button="showSaveProductButton"
      :url="`/customer/${basketStore.customerId}/sales-dashboard`"
      :basket-items="basketStore.basket"
      :basket-main-items="basketMainItems"
      :hide-virtual-home-banner="hideVirtualHomeBanner"
      breakdown="Price"
      @place-order-process="placeOrderProcess"
      @save-quote="saveQuote"
      @set-available-branches="setAvailableBranches"
    >
      <template #pricing="{ override, overridePrice, itemKey, basketCurrency, price, quantity, title }">
        <ManualPricing
          :class="[quantity ? 'text-left' : 'text-right ml-auto']"
          :title="title"
          :basket-main-items="basketMainItems"
          :override="override"
          :override-price="overridePrice"
          :item-key="itemKey"
          :basket-currency="basketCurrency"
          :price="price"
          :quantity="quantity"
          :sales-sector-type="customer.customer.salesSectorType"
          :delivery="customer.delivery"
        ></ManualPricing>
      </template>
      <template v-if="isExtras" #extras>
        <div v-if="$refs.basket">
          <div
            class="flex justify-between border-t items-center border-gray-300 p-3 mt-6"
          >
            <div>Extras</div>
            <button
              key="add-contract-extra"
              class="btn-action btn-sm ml-3"
              data-index="0"
              @click.prevent="addExtrasToQuote()"
            >
              <span v-if="extrasForThisJob.length === 0">Add</span>
              <span v-else>Edit</span>
              Extras
            </button>
          </div>
          <div
            v-for="extra in extrasForThisJob"
            :key="extra.key"
            class="flex border-t border-gray-300 p-3"
          >
            <div>
              <span>{{ extra.quantity }} x {{ extra.description }}</span>
              <span v-if="extra.optionDescription" class="ml-1">
                ({{ extra.optionDescription }})
              </span>
              <span v-if="extra.size" class="ml-1">{{ extra.size }}</span>
              <span v-if="extra.unitOfMeasure === enums.unitOfMeasure.LENGTH" class="ml-1">
                ({{ extra.length }}mm)
              </span>
              <span v-else-if="extra.unitOfMeasure === enums.unitOfMeasure.WIDTH" class="ml-1">
                ({{ extra.width }}mm)
              </span>
              <span v-else-if="extra.unitOfMeasure === enums.unitOfMeasure.HEIGHT" class="ml-1">
                ({{ extra.height }}mm)
              </span>
              <span v-else-if="extra.unitOfMeasure === enums.unitOfMeasure.AREA" class="ml-1">
                ({{ (((extra.width / 1000) * extra.height) / 1000).toFixed(2) }}m<sup>2</sup>)
              </span>
              <span v-else-if="extra.unitOfMeasure === enums.unitOfMeasure.PERIMETER" class="ml-1">
                ({{ ((extra.width / 1000) * 2 + (extra.height / 1000) * 2).toFixed(2) }}m)
              </span>
              <span v-else-if="extra.unitOfMeasure === enums.unitOfMeasure.VOLUME" class="ml-1">
                ({{
                  (((((extra.length / 1000) * extra.width) / 1000) * extra.height) / 1000).toFixed(
                    2,
                  )
                }}m<sup>3</sup>)
              </span>
            </div>
            <div v-if="basketStore.salesSectorType === enums.salesSectorType.RETAIL" class="ml-auto" v-html="formatCurrency(basketStore.getCurrency, extra.priceIncTax)"></div>
            <div v-else class="ml-auto" v-html="formatCurrency(basketStore.getCurrency, extra.price)"></div>
          </div>
        </div>
      </template>
    </Basket>
  </touch-layout>
</template>

<script>

import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';
import { useBasketStore } from '@/pinia/basket';
import { formatCurrency } from '@/composables/currency';
import Basket from '@/components/shared/basket/Basket.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import ManualPricing from '@/components/shared/basket/ManualPricing.vue';
import FilterDropdown from '@/components/shared/FilterDropdown.vue';

export default {
  components: {
    Breadcrumbs,
    Basket,
    ManualPricing,
    FilterDropdown
  },
  props: {
    customer: Object,
  },
  setup() {
    return { formatCurrency }
  },
  data() {
    return {
      customerId: undefined,
      customerName: undefined,
      isExtras: false,
      availableBranches: []
    };
  },
  computed: {
    ...mapStores(useAuthStore, useBasketStore),
    basketMainItems() {
      return this.basketStore.basket.filter(
        (item) => item.inputType === window.enum.inputType.FENESTRATION,
      );
    },
    canSkipSop() {
      return this.customer?.customer?.skipSopOrderAcceptance;
    },
    showPlaceOrderButton() {
      return this.customer?.customer?.skipSopOrderAcceptance;
    },
    showSaveProductButton() {
      return !this.customer?.customer?.skipSopOrderAcceptance;
    },
    extrasForThisJob() {
      return this.$refs.basket.basketItems;
    },
    hideVirtualHomeBanner() {
      return (
        this.basketStore.basket.filter((x) => x.omitFromVisualiser).length === this.basketStore.basket.length ||
        !this.authStore.isDealerNetworkModeAvailable ||
        this.basketMainItems.length === 0
      );
    },
  },
  async mounted() {
    this.customerId = this.customer.customerId;
    this.customerName = this.customer?.company?.name;

    const { groups } = await window.touch.customerGetAllExtraItems();
    const extras = [].concat(...groups.map((x) => x.items));
    this.isExtras =
      (extras.length &&
        ![this.enums.salesSectorType.TRADE, this.enums.salesSectorType.RETAIL].includes(
          this.customer.customer.salesSectorType,
        )) ||
      (this.customer.customer.salesSectorType === this.enums.salesSectorType.TRADE &&
        extras.filter((x) => x.type === this.enums.fittingType.SUPPLY_ONLY).length > 0) ||
      (this.customer.customer.salesSectorType === this.enums.salesSectorType.RETAIL &&
        extras.length > 0);
  },
  methods: {
    async placeOrderProcess() {
      const businessBypassSopUrl = `/customer/${this.basketStore.customerId}/sales-dashboard/checkout`;
      if (this.basketStore.skipSop) {
        await this.routerPush(businessBypassSopUrl);
      } else {
        await this.routerPush(`/customer/${this.basketStore.customerId}/sales-dashboard?type=` + this.basketStore.buttonName.toLowerCase());
      }
    },
    async saveQuote() {
      await this.routerPush(`/customer/${this.basketStore.customerId}/sales-dashboard?type=` + this.basketStore.buttonName.toLowerCase());
    },
    addExtrasToQuote() {
      this.itemIndex = undefined;
      this.loadedItem = undefined;
      this.$refs.basket.showExtras = true;
    },
    setAvailableBranches(availableBranches) {
      this.availableBranches = availableBranches
      if (this.availableBranches.length > 0 && !this.$route.query.branchFilter) {
        const staffBranches = this.authStore.branches
        const customerBranches = this.customer.branches
        let primaryBranchId
        if (this.availableBranches.some(x => x.id === staffBranches?.find(x => x.isPrimary)?.branchCompanyId)) {
          primaryBranchId = this.availableBranches.find(x => x.id === staffBranches?.find(x => x.isPrimary).branchCompanyId).id
        } else if (this.availableBranches.some(x => x.id === customerBranches?.find(x => x.isPrimary)?.branchCompanyId)) {
          primaryBranchId = this.availableBranches.find(x => x.id === customerBranches?.find(x => x.isPrimary).branchCompanyId).id
        }
        this.$router.replace({ query: { branchFilter: primaryBranchId } }).catch(() => { })
      }

      setTimeout(() => {
        this.$watch('$route.query.branchFilter', async (branchCompanyId) => {
          await window.touch.UpdateBranch({
            BranchCompanyId: branchCompanyId, ContractId: this.basketStore.contractIdentifier.contractId, CustomerId: this.customer?.customerId
          })
        });
      }, 0);
    }
  },
};
</script>
