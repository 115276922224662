import { createRouter as router } from '@/router/index'
import { errorCallback, successCallback } from '@/composables/callback';

export async function processingBasketSummary(
  CustomerId,
  ContractId,
  JobKey,
  ProcessingLevel,
) {
  return this.parseResponse(
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/BasketSummary'),
      {
        CustomerId,
        ContractId,
        JobKey,
        ProcessingLevel,
      },
    )
  )
}

export async function processingAddStockItem(
  ContractId,
  JobKey,
  ItemId,
  Quantity,
  LinkToItemKey,
) {
  return (
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/AddStockItem'),
      {
        ContractId,
        JobKey,
        ItemId,
        LinkToItemKey,
        Quantity,
      },
    )
  ).data;
}

export async function processingContract(contractId) {
  return this.parseResponse(
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/Contract'),
      {
        ContractId: contractId,
      },
    ),
    errorCallback,
  );
}

export async function processingSetVisualisations(
  contractId,
  jobKey,
  visualisations,
) {
  return (
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/SetVisualisations'),
      {
        ContractId: contractId,
        JobKey: jobKey,
        Visualisations: visualisations,
      },
    )
  ).data;
}

export async function processingGetAvailableProductsToSelect(typeId, groupId, customerId, Sandbox) {
  return (
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/GetAvailableProductsToSelect'),
      {
        TypeId: typeId,
        GroupId: groupId,
        CustomerId: customerId,
        Sandbox
      },
    )
  ).data;
}

export async function processingGetAvailableProductSelectionGroups(customerId) {
  return this.parseResponse(
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/GetAvailableProductSelectionGroups'),
      {
        CustomerId: customerId,
      },
    ),
    errorCallback,
  );
}

export async function processingGetAvailableProductSelectionTypes(customerId, groupId) {
  return (
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/GetAvailableProductSelectionTypes'),
      {
        GroupId: groupId,
        CustomerId: customerId,
      },
    )
  ).data;
}

export async function getProducts() {
  return (
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/Products'),
    )
  ).data;
}

export async function processingGetCustomerPriceBreakdown(contractId, jobId, itemId) {
  return (
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/GetCustomerPriceBreakdown'),
      {
        ContractId: contractId,
        JobKey: jobId,
        ItemKey: itemId,
      },
    )
  ).data;
}

export async function productStoredDesigns(
  customerId,
  processingLevel,
  productId,
  width = undefined,
  height = undefined,
  bayBowOnly = false,
  limit = 1000000,
  offset = 0,
  squaresQty = undefined,
  coupledOnly = false,
) {
  return this.parseResponse(
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/ProductStoredDesigns'),
      {
        ProductId: productId,
        OnlyShowBayBowCompatible: bayBowOnly,
        OnlyShowCoupledCompatible: coupledOnly,
        TargetWidth: width ? Number(width) : undefined,
        TargetHeight: height ? Number(height) : undefined,
        ProcessingLevel: processingLevel,
        Limit: limit,
        Offset: offset,
        QtySquares: squaresQty,
        CustomerId: customerId,
      },
    ),
    [],
  );
}

export async function processingGetProcessingLevel() {
  return (
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/GetProcessingLevel'),
    )
  ).data.processingLevelId;
}

export async function createJobLineItem(
  customerId,
  contractId,
  jobKey,
  designId,
  productId,
  processingLevel,
) {
  return this.parseResponse(
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/CreateJobLineItem'),
      {
        ContractId: contractId,
        CustomerId: customerId,
        JobKey: jobKey,
        ProductStoredDesignId: designId,
        ProductId: productId,
        ProcessingLevel: processingLevel,
      },
    ),
  );
}

export async function updateJobLineItemDimension(
  customerId,
  contractId,
  jobKey,
  itemKey,
  processingLevel,
  dimensionId,
  value,
) {
  return this.parseResponse(
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/UpdateJobLineItemDimension'),
      {
        CustomerId: customerId,
        ContractId: contractId,
        JobKey: jobKey,
        ItemKey: parseInt(itemKey, 10),
        ProcessingLevel: processingLevel,
        DimensionId: dimensionId,
        Value: parseInt(value, 10),
      },
    ),
    errorCallback,
  );
}

export async function updateJobLineItemQuantity(
  customerId,
  contractId,
  jobKey,
  itemKey,
  processingLevel,
  qty,
) {
  return (
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/UpdateJobLineItemQuantity'),
      {
        CustomerId: customerId,
        ContractId: contractId,
        JobKey: jobKey,
        ItemKey: parseInt(itemKey, 10),
        ProcessingLevel: processingLevel,
        NewQuantity: parseInt(qty, 10),
      },
    )
  ).data;
}

export async function UpdateJobLineStockItemQuantity(
  contractId,
  jobKey,
  itemKey,
  processingLevel,
  qty,
) {
  return (
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/UpdateJobLineStockItemQuantity'),
      {
        ContractId: contractId,
        JobKey: jobKey,
        ItemKey: parseInt(itemKey, 10),
        ProcessingLevel: processingLevel,
        NewQuantity: parseInt(qty, 10),
      },
    )
  ).data;
}

export async function UpdateJobLineItemOption(
  customerId,
  contractId,
  jobKey,
  itemKey,
  processingLevel,
  heading,
  value,
  components,
  members,
  parameters,
  text,
  rule,
) {
  return this.parseResponse(
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/UpdateJobLineItemOption'),
      {
        CustomerId: customerId,
        ContractId: contractId,
        JobKey: jobKey,
        ItemKey: parseInt(itemKey, 10),
        ProcessingLevel: processingLevel,
        OptionHeadingId: heading,
        NewOptionId: value,
        OnlyTheseComponents: components,
        OnlyTheseMembers: members,
        Text: text,
        Parameters: parameters,
        Rule: parseInt(rule, 10),
      },
    ),
  );
}

export async function createTradeQuote(customerId) {
  return this.parseResponseWith400(
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/createTradeQuote'),
      {
        CustomerId: customerId,
      },
    ),
  );
}

export async function processingGetEarliestDeliveryDate(contractId, jobKey) {
  return this.parseResponse(
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/GetEarliestDeliveryDate'),
      {
        ContractId: contractId,
        JobKey: jobKey,
      },
    ),
  );
}

export async function processingForceDefaults(
  contractId,
  jobKey,
  itemKey,
  processingLevel,
) {
  return this.parseResponse(
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/ForceDefaults'),
      {
        ContractId: contractId,
        JobKey: jobKey,
        ItemKey: itemKey,
        ProcessingLevel: processingLevel,
      },
    ),
  );
}

export async function createTradeOrder(customerId) {
  return this.parseResponseWith400(
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/createTradeOrder'),
      {
        CustomerId: customerId,
      },
    ),
  );
}

export async function jobLineItem(
  customerId,
  contractId,
  jobKey,
  itemKey,
  processingLevel,
  recalculate = false,
  visualiserImages = false,
  exchangeRate = 1,
) {
  const item = this.parseResponse(
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/JobLineItem'),
      {
        CustomerId: customerId,
        ContractId: parseInt(contractId, 10),
        JobKey: jobKey,
        ItemKey: parseInt(itemKey, 10),
        ProcessingLevel: processingLevel,
        Recalculate: recalculate,
        FormatImagesForVisualiser: visualiserImages,
      },
    ),
  );

  item.price *= exchangeRate;
  item.tax *= exchangeRate;

  if (item.priceBreakdown) {
    item.priceBreakdown = item.priceBreakdown.map((breakdownRow) => ({
      ...breakdownRow,
      discount: breakdownRow.discount * exchangeRate,
      gross: breakdownRow.gross * exchangeRate,
      nett: breakdownRow.nett * exchangeRate,
      rate: breakdownRow.rate * exchangeRate,
      value: breakdownRow.value * exchangeRate,
    }));
  }

  return item;
}

export async function processingJob(
  contractId,
  jobKey,
  recalculate = false,
) {
  const rawJob = this.parseResponse(
    await this.authenticatedPost(await this.sandboxStore.processingUrl('processing/Job'), {
      ContractId: parseInt(contractId, 10),
      JobKey: parseInt(jobKey, 10),
      Recalculate: recalculate,
    }),
    () => {
      window.alertBox
        .fire({
          customClass: {
            cancelButton: 'absolute top-0 right-0 p-3',
            confirmButton: 'btn-action m-1',
            denyButton: 'btn m-1',
          },
          title: 'Unable to load contract',
          text: 'We were unable to load this contract from the database, Would to like to re-try?',
          icon: 'question',
          className: 'relative',
          showCancelButton: true,
          showDenyButton: true,
          denyButtonText: 'No, Clear my basket',
          confirmButtonText: 'Yes, Retry',
          cancelButtonText: '<i class="fal fa-times"></i>',
        })
        .then(async (result) => {
          if (result.isDismissed) {
            window.location.reload();
            return;
          }

          if (!result.isConfirmed) {
            this.basketStore.clearBasket()
            await router.push('/');
            return;
          }

          window.location.reload();
        });
    },
  );

  if (rawJob === undefined) {
    throw new Error('Unable to load Job');
  }

  if (rawJob.exchangeRate === null || rawJob.exchangeRate === 0) {
    rawJob.exchangeRate = 1;
  }

  return rawJob;
}

export async function processingUpdateJobLineItemNestedDesign(
  customerId,
  contractId,
  jobKey,
  itemKey,
  processsingLevel,
  productId,
  designId,
  containerId,
) {
  return (
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/UpdateJobLineItemNestedDesign'),
      {
        CustomerId: customerId,
        ContractId: parseInt(contractId, 10),
        JobKey: parseInt(jobKey, 10),
        ItemKey: parseInt(itemKey, 10),
        ProcessingLevel: processsingLevel,
        ProductId: parseInt(productId, 10),
        StoredDesignId: designId,
        ContainerId: containerId,
      },
    )
  ).data;
}

export async function processingGetChildProducts(
  contractId,
  jobKey,
  itemKey,
  width,
  height,
  customerId,
) {
  return (
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/GetChildProducts'),
      {
        CustomerId: customerId,
        ContractId: parseInt(contractId, 10),
        JobKey: parseInt(jobKey, 10),
        ItemKey: parseInt(itemKey, 10),
        TargetWidth: parseInt(width, 10),
        TargetHeight: parseInt(height, 10),
      },
    )
  ).data;
}

export async function processingSaveContract(
  customerId,
  contractId,
  skipAcceptance = false,
  placeTradeOrder = false,
  useLazySaving = true,
) {
  await this.parseResponse(
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/SaveContract'),
      {
        CustomerId: customerId,
        ContractId: parseInt(contractId, 10),
        useLazySaving,
        PlaceTradeOrder: placeTradeOrder,
        SkipAcceptance: skipAcceptance,
      },
    ),
    errorCallback
  );
}

export async function processingRemoveContract(contractId) {
  return (
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/RemoveContract'),
      {
        ContractId: parseInt(contractId, 10),
      },
    )
  ).data;
}

export async function processingGet3DModel(
  contractId,
  itemKey,
  jobKey,
  customerId,
) {
  return this.parseResponse(
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/Get3dModel'),
      {
        ContractId: parseInt(contractId, 10),
        JobKey: parseInt(jobKey, 10),
        ItemKey: parseInt(itemKey, 10),
        CustomerId: parseInt(customerId, 10),
      },
    ),
    (r) => {
      if (r.data && r.data.message) {
        throw new Error(r.data.message);
      }

      throw new Error('Failed to load 3D Model');
    },
  );
}

export async function processingUpdateContract(
  contractId,
  payload,
) {
  await this.authenticatedPost(
    await this.sandboxStore.processingUrl('processing/UpdateContract'),
    {
      ContractId: parseInt(contractId, 10),
      ...payload,
    },
  );
}

export async function processingUpdateNote(params) {
  await this.authenticatedPost(
    await this.sandboxStore.processingUrl('processing/UpdateNote'),
    params,
  );
}

export async function processingUpdateFittingType(
  contractId,
  jobId,
  isFit,
) {
  await this.authenticatedPost(
    await this.sandboxStore.processingUrl('processing/UpdateFittingType'),
    {
      ContractId: parseInt(contractId, 10),
      JobKey: parseInt(jobId, 10),
      FittingType: isFit
        ? window.enum.fittingType.SUPPLY_AND_FIT
        : window.enum.fittingType.SUPPLY_ONLY,
    },
  );
}

export async function processingUpdateJob(
  contractId,
  jobId,
  payload,
) {
  await this.authenticatedPost(
    await this.sandboxStore.processingUrl('processing/UpdateJob'),
    {
      ContractId: parseInt(contractId, 10),
      JobKey: parseInt(jobId, 10),
      ...payload,
    },
  );
}

export async function processingUpdateJobDelivery(
  customerId,
  contractId,
  jobId,
  payload,
) {
  const params = {
    ContractId: parseInt(contractId, 10),
    JobKey: parseInt(jobId, 10),
    ...payload,
  };

  if (window.VITE_APP_INSTALLATION_TYPE === 'business') {
    params.CustomerId = parseInt(customerId, 10);
  }

  await this.authenticatedPost(
    await this.sandboxStore.processingUrl('processing/UpdateJobDelivery'),
    params,
  );
}

export async function processingUpdateCustomerPONumber(
  contractId,
  poNumber,
) {
  const params = {
    ContractId: parseInt(contractId, 10),
    CustomerPONumber: poNumber,
  };

  await this.authenticatedPost(
    await this.sandboxStore.processingUrl('processing/UpdateCustomerPONumber'),
    params,
  );
}

export async function processingUpdateExistingExtraItem(contractId, jobId, itemId, params) {
  return (
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/UpdateExistingExtraItem'),
      {
        ...params,
        ContractId: parseInt(contractId, 10),
        JobKey: parseInt(jobId, 10),
        ItemKey: itemId,
      },
    )
  ).data;
}

export async function processingUpdateNewExtraItem(
  contractId,
  jobId,
  itemId,
  params,
) {
  return (
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/UpdateNewExtraItem'),
      {
        ...params,
        ContractId: parseInt(contractId, 10),
        JobKey: parseInt(jobId, 10),
        ItemKey: itemId,
      },
    )
  ).data;
}

export async function processingAddExistingExtraItem(
  contractId,
  jobId,
  extraItemId,
  itemId = undefined,
  params = {},
) {
  return this.parseResponse(
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/AddExistingExtraItem'),
      {
        ...params,
        ContractId: parseInt(contractId, 10),
        JobKey: parseInt(jobId, 10),
        ItemId: extraItemId,
        LinkToItemKey: itemId,
      },
    ),
    (error) => ({
      status: false,
      message: error.data.message,
    }),
  );
}

export async function processingJobDelivery(
  customerId,
  contractId,
  jobId,
  monthsAhead = 3,
) {
  return this.parseResponse(
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/JobDelivery'),
      {
        CustomerId: customerId,
        ContractId: parseInt(contractId, 10),
        JobKey: parseInt(jobId, 10),
        MonthsAhead: parseInt(monthsAhead, 10),
      },
    ),
    errorCallback,
    (response) => ({ status: true, ...response.data }),
  );
}

export async function processingUpdateJobPriceOverride(
  contractId,
  jobId,
  overridePrice,
  overrideFitPrice,
) {
  return this.authenticatedPost(
    await this.sandboxStore.processingUrl('processing/UpdateJobPriceOverride'),
    {
      ContractId: parseInt(contractId, 10),
      JobKey: parseInt(jobId, 10),
      OverrideCalculatedPrice: true,
      OverrideCalculatedFittingPrice: true,
      OverridePriceTaxable: parseFloat(overridePrice),
      OverrideFittingPriceTaxable: parseFloat(overrideFitPrice),
      OverrideFittingPriceTaxExempt: 0,
      OverridePriceTaxExempt: 0,
    },
  );
}

export async function processingUpdateJobLineItemRequote(contractId, jobId, processingLevel, items) {
  return this.authenticatedPost(
    await this.sandboxStore.processingUrl('processing/UpdateJobLineItemRequote'),
    {
      ContractId: parseInt(contractId, 10),
      JobKey: parseInt(jobId, 10),
      ProcessingLevel: processingLevel,
      Items: items.map((item) => ({
        Override: true,
        ItemKey: parseInt(item.itemKey, 10),
        Value: parseFloat(item.price),
        TaxExempt: false,
      })),
    },
  );
}

export async function processingReloadContract(
  customerId,
  contractId,
) {
  return this.parseResponse(
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/ReloadContract'),
      {
        CustomerId: customerId,
        ContractId: parseInt(contractId, 10),
      },
    ),
    (error) => {
      throw new Error(error.data.message);
    },
    () => ({
      status: true,
    }),
  );
}

export async function processingDeleteJob(contractId, jobId) {
  await this.authenticatedPost(
    await this.sandboxStore.processingUrl('processing/DeleteJob'),
    {
      ContractId: parseInt(contractId, 10),
      JobKey: parseInt(jobId, 10),
    },
  );
}

export async function processingDeleteLineItem(
  contractId,
  jobId,
  lineItemId,
) {
  await this.authenticatedPost(
    await this.sandboxStore.processingUrl('processing/DeleteJobLineItem'),
    {
      ContractId: parseInt(contractId, 10),
      JobKey: parseInt(jobId, 10),
      ItemKey: parseInt(lineItemId, 10),
    },
  );
}

export async function processingDuplicateItem(
  customerId,
  contractId,
  jobId,
  lineItemId,
) {
  return (
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/DuplicateItem'),
      {
        CustomerId: customerId,
        ContractId: parseInt(contractId, 10),
        JobKey: parseInt(jobId, 10),
        ItemKey: parseInt(lineItemId, 10),
      },
    )
  ).data;
}

export async function processingCopyJob(contractId, jobId) {
  return (
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/CopyJob'),
      {
        ContractId: parseInt(contractId, 10),
        JobKey: parseInt(jobId, 10),
      },
    )
  ).data.jobKey;
}

export async function resetJobLineItem(
  contractId,
  jobId,
  itemKey,
  processingLevel,
) {
  return this.authenticatedPost(
    await this.sandboxStore.processingUrl('processing/ResetJoblineItem'),
    {
      ContractId: Number(contractId),
      JobKey: Number(jobId),
      ItemKey: Number(itemKey),
      ProcessingLevel: Number(processingLevel),
    },
  );
}

export async function getEnquiryStatuses() {
  return (
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/GetEnquiryStatuses'),
    )
  ).data;
}

export async function updateQuoteStatus(contractId, status) {
  return (
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/UpdateQuoteStatus'),
      {
        ContractId: Number(contractId),
        Status: Number(status),
      },
    )
  ).data;
}

export async function updateEnquiryStatus(contractId, status) {
  return (
    await this.authenticatedPost(
      await this.sandboxStore.processingUrl('processing/UpdateEnquiryStatus'),
      {
        ContractId: Number(contractId),
        Status: Number(status),
      },
    )
  ).data;
}

export async function updateDealerEnquiryStatus(
  dealerNetworkAssignmentId,
  status,
  refusalReason = '',
) {
  return (
    await this.authenticatedPost('contract/UpdateDealerEnquiryStatus', {
      dealerNetworkAssignmentId: Number(dealerNetworkAssignmentId),
      DealerStatus: Number(status),
      RefusalReason: refusalReason,
    })
  ).data;
}

export async function getDocsToAccept() {
  const response = await this.authenticatedPost(
    await this.sandboxStore.processingUrl('processing/GetDocsToAccept'),
  );
  return response.data;
}

export async function processingProductSelectionStage(
  selectedStages,
) {
  const response = await this.authenticatedPost(
    await this.sandboxStore.processingUrl('processing/ProductSelectionStage'),
    {
      SelectedStages: selectedStages,
    },
  );

  return response.data;
}

export async function processingConvertDealerEnquiry(
  contractId,
  jobIds,
  processingLevel,
) {
  const { data } = await this.authenticatedPost('processing/ConvertDealerEnquiry', {
    ContractId: Number(contractId),
    JobKeys: jobIds.map((jobId) => Number(jobId)),
    ProcessingLevelId: processingLevel,
  });

  return data;
}

export async function processingImage(
  contractId,
  jobKey,
  itemKey,
  imageType,
) {
  const { data } = await this.authenticatedPost('processing/Image', {
    contractId,
    jobKey,
    itemKey,
    imageType,
  });
  return data;
}

export async function processingAddStaffToContract(
  ContractId,
  JobKey,
  Role,
  StaffId,
) {
  return this.parseResponse(
    await this.authenticatedPost('processing/AddStaffToContract', {
      ContractId,
      JobKey,
      Role,
      StaffId,
    }),
  );
}

export async function processingGetItemPriceBreakdown(
  CustomerId,
  ContractId,
  JobKey,
  ItemKey,
  ProcessingLevel,
) {
  return this.parseResponse(
    await this.authenticatedPost('processing/GetItemPriceBreakdown', {
      CustomerId,
      ContractId,
      JobKey,
      ItemKey,
      ProcessingLevel,
    }),
  );
}

export async function UpdateFabricatorJobPriceOverride(
  CustomerId, ContractId, JobKey, OverrideCalculatedPrice, OverridePriceExTax
) {
  return this.parseResponse(await this.authenticatedPost('processing/UpdateFabricatorJobPriceOverride', {
    CustomerId, ContractId, JobKey, OverrideCalculatedPrice, OverridePriceExTax
  }), errorCallback, () => successCallback('Save'));
}


export async function UpdateFabricatorItemPriceOverride(
  CustomerId, ContractId, JobKey, ItemKey, OverrideItemCalculatedPrice, OverrideItemPriceExTax
) {
  return this.parseResponse(await this.authenticatedPost('processing/UpdateFabricatorItemPriceOverride', {
    CustomerId, ContractId, JobKey, ItemKey, OverrideItemCalculatedPrice, OverrideItemPriceExTax
  }), errorCallback, () => successCallback('Save'));
}


export async function ApplyDiscountVoucher(discount) {
  return this.parseResponse(await this.authenticatedPost('processing/ApplyDiscountVoucher', discount), errorCallback, () => successCallback('Save'));
}

export async function AvailableBranchesForContract(query) {
  const response = await this.authenticatedPost('processing/AvailableBranchesForContract', query);
  return response.data;
}

export async function UpdateBranch(query) {
  return this.parseResponse(await this.authenticatedPost('processing/UpdateBranch', query), errorCallback, () => successCallback('Save'));
}

export async function UpdateIssuePresentComfirmed({ ContractId, IssuePresentAccepted }) {
  return this.parseResponse(
    await this.authenticatedPost('processing/UpdateIssuePresentComfirmed', { ContractId, IssuePresentAccepted })
  );
}