<template>
  <Teleport defer to="#portal_popup"> 
    <modal-window :modal_open="true" title="Account Settings" @close="$emit('close')">
      <div class="flex flex-col container">
        <div class="flex flex-col flex-wrap">
          <div class="w-full xs:w-1/2 xs:pr-5 mt-4">
            <div class="font-medium">Fitting Markup</div>
            <input v-model.number="fittingMarkup" type="number" class="form-control" />
          </div>
          <div class="w-full xs:w-1/2 xs:pr-5 mt-4">
            <div class="font-medium">Survey Cost</div>
            <input v-model.number="surveyCost" type="number" class="form-control" />
          </div>
          <div class="w-full xs:w-1/2 xs:pr-5 mt-4">
            <div class="font-medium">Survey Markup</div>
            <input v-model.number="surveyMarkup" type="number" class="form-control" />
          </div>
        </div>
        <div class="text-right mt-auto flex flex-col 2xs:block">
          <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
            Cancel
          </button>
          <button class="btn-action btn-lg" @click.prevent.stop="UpdateOrganisation">
            Save Changes
          </button>
        </div>
      </div>
    </modal-window>
  </Teleport>
</template>

<script>
import validate from 'validate.js';

export default {
  props: {
    organisation: Object,
  },
  data() {
    return {
      fittingMarkup: this.organisation.fittingMarkup,
      surveyCost: this.organisation.surveyCost,
      surveyMarkup: this.organisation.surveyMarkup,
    };
  },
  methods: {
    async UpdateOrganisation() {
      const validationErrors = validate(
        {
          fittingMarkup: this.fittingMarkup,
          surveyCost: this.surveyCost,
          surveyMarkup: this.surveyMarkup,
        },
        {
          fittingMarkup: {
            presence: { allowEmpty: false },
          },
          surveyCost: {
            presence: { allowEmpty: false },
          },
          surveyMarkup: {
            presence: { allowEmpty: false },
          },
        },
      );

      if (validationErrors) {
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        return;
      }

      await window.touch.UpdateOrganisation({
        Id: this.organisation.id,
        CompanyId: this.organisation.companyId,
        FittingMarkup: this.fittingMarkup,
        SurveyCost: this.surveyCost,
        SurveyMarkup: this.surveyMarkup,
      })

      this.$emit('close');
    },
  },
};
</script>