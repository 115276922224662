<template>
  <div>
    <router-view v-slot="{ Component }">
      <component :is="Component">
        <Menu></Menu>
      </component>
    </router-view>
  </div>
</template>

<script>
import Menu from '@/components/business/customers/Menu.vue';

export default {
  components: {
    Menu
  },
};
</script>