<template>
  <Teleport defer to="#portal_popup"> 
    <modal-window :modal_open="true" title="Edit Category" :loading="saving" @close="$emit('close')">
      <div class="font-medium">Category Name</div>
      <div>
        <input v-model="category.name" class="form-control" />
      </div>
      <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
        <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click="$emit('close')">Cancel</button>
        <button class="btn-danger btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click="deleteCategory()">
          Delete
        </button>
        <button class="btn-action btn-lg" @click.prevent.stop="updateCategory()">
          Save Changes
        </button>
      </div>
    </modal-window>
  </Teleport>
</template>

<script>

export default {
  props: ['category'],
  data() {
    return {
      saving: false,
    };
  },
  methods: {
    async updateCategory() {
      if (!this.saving) {
        this.saving = true;

        if (this.category.name === '') {
          this.saving = false;
          this.alertBox().fire({
            title: 'Category Name Missing',
            text: 'Please enter a category name.',
            icon: 'error',
          });
        }

        await window.touch.updateNewsCategory(this.category);

        this.$emit('category-updated');
        this.$emit('close');
      }
    },
    async deleteCategory() {
      this.alertBox()
        .fire({
          title: 'Are you sure you want to delete this category?',
          text: 'This action cannot be undone.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.saving = true;
            const resp = await window.touch.deleteNewsCategories([this.category.id]);
            if (resp && resp.status === 400) {
              this.saving = false;
              this.alertBox().fire({
                title: 'Category could not be deleted',
                icon: 'error',
                text: resp.data.message,
              });
              return;
            }
            this.saving = false;
            this.$emit('category-updated');
            this.$emit('close');
          }
        });
    },
  },
};
</script>
