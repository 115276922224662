<template>
  <touch-layout>
    <!-- top layer with three segments -->
    <div
      v-if="item && open_question"
      class="flex flex-col flex-grow xs:h-full sm:h-screen sm:overflow-hidden bg-gray-200 xs:relative"
      :class="{ 'cursor-wait': loading }"
    >
      <!-- action bar with questions and other options for small screens -->
      <div
        class="flex flex-col xs:bg-transparent sm:overflow-y-hidden sm:overflow-y-auto sm:scrolling-touch sm:relative z-20 xs:hidden"
        :class="{ 'bottom-0': main_menu_open, 'sm:hidden': full_image_view }"
      >
        <div class="xs:p-3 sm:pt-5 sm:pl-5 max-h-full flex flex-col">
          <div class="flex justify-between bg-white xs:rounded-t-lg border-b xs:border border-gray-300 border-b-0 flex-shrink-0">
            <div class="text-center xs:text-left flex-1 flex">
              <div
                class="xs:hidden p-3 bg-green-600 text-white"
                :class="{ 'border-r': open_question.questionNumber > 1 }"
              >
                <div
                  v-if="open_question.questionNumber > 1"
                  class="w-6 3xs:w-16 cursor-pointer"
                  @click="
                    prevQuestion(),
                    (main_menu_open = false),
                    (mobile_show_options = true),
                    (mobile_show_image = false),
                    (full_image_view = false)"
                >
                  <i class="fa fa-chevron-left 3xs:pr-2"></i>
                  <span class="hidden 3xs:inline">Prev</span>
                </div>
                <div v-else class="w-6 3xs:w-16"></div>
              </div>
              <div
                class="flex-grow p-3"
                @click="
                  (main_menu_open = !main_menu_open),
                  (mobile_show_options = true),
                  (mobile_show_image = false),
                  (full_image_view = false)"
              >
                <span class="font-medium"><i class="fal fa-bars fa-sm sm:hidden pr-3"></i></span>
                <span class="font-medium sm:text-lg hidden sm:inline">{{ item.description }}</span>
                <span class="font-medium sm:text-lg sm:hidden">{{ open_question.name }}</span>
              </div>
              <div class="xs:hidden border-l p-3 bg-green-600 text-white">
                <div
                  v-if="open_question.questionNumber < sorted_sidebar_questions.length"
                  class="w-6 3xs:w-16 cursor-pointer"
                  @click="
                    nextQuestion(),
                    (main_menu_open = false),
                    (mobile_show_options = true),
                    (mobile_show_image = false),
                    (full_image_view = false)"
                >
                  <span class="hidden 3xs:inline">Next</span>
                  <i class="fa fa-chevron-right 3xs:pl-2"></i>
                </div>
                <div v-else class="w-6 3xs:w-16"></div>
              </div>
            </div>
          </div>
          <div
            :class="{ 'hidden sm:block': !main_menu_open }"
            class="pb-12 xs:pb-0 overflow-x-auto scrolling-touch sm:overflow-x-visible max-h-full"
          >
            <div class="bg-white xs:rounded-b-lg border border-gray-300 border-t-0 sm:mb-5">
              <div v-show="basketStore.contractHasSizing" class="py-3 px-6">
                <dimension
                  v-for="(dimension, index) in item.dimensions"
                  :key="dimension.id"
                  :data-index="index"
                  :dimension="dimension"
                  @change-dimension="changeDimension(dimension.id, $event)"
                />
              </div>
              <div
                v-for="(question, index) in sorted_sidebar_questions"
                :key="index"
                class="py-3 px-6 hover-bg-brand-primary_light cursor-pointer whitespace-nowrap flex justify-between"
                :class="{ 'bg-brand-primary_light': open_question === question }"
                @click.stop="(questionNumber = index), (main_menu_open = false)"
              >
                <div class="flex flex-col justify-around">
                  <div>{{ index + 1 }}. {{ question.name }}</div>
                </div>
                <div v-if="open_question === question" class="flex flex-col justify-around">
                  <i class="fa fa-caret-right"></i>
                </div>
              </div>
              <div
                class="py-3 px-6 hover-bg-brand-primary_light cursor-pointer whitespace-nowrap flex xs:hidden justify-between"
                @click="designAnother()"
              >
                <div class="flex flex-col justify-around">
                  <div>Switch Product</div>
                </div>
                <div class="flex flex-col justify-around">
                  <span><i class="fal fa-repeat-alt fa-xs leading-loose"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--extra options buttons for ipad portrait-->
      <div class="hidden xs:flex sm:hidden justify-center">
        <div class="mx-1 mt-auto">
          <div
            v-for="(image, key) in item.images"
            v-show="next_image_type == image.type"
            :key="key"
            class="cursor-pointer flex justify-between bg-white py-2 px-3 rounded border mt-3 w-56 text-center"
            @click.prevent.stop="current_image_type = image.type"
          >
            <div class="flex flex-col justify-around flex-grow text-sm capitalize">
              Show {{ image_types[image.type] }} View
            </div>
          </div>
        </div>
        <div class="mx-1 mt-auto">
          <div
            class="cursor-pointer flex justify-between bg-white py-2 px-3 rounded border mt-3 w-56 text-center"
            @click.prevent.stop="full_image_view = !full_image_view"
          >
            <div class="flex flex-col justify-around flex-grow text-sm capitalize">
              <span v-if="!full_image_view">Full Screen Image</span>
              <span v-if="full_image_view">Show Menus</span>
            </div>
          </div>
        </div>
        <div class="mx-1 mt-auto">
          <div
            class="cursor-pointer flex justify-between bg-white py-2 px-3 rounded border mt-3 w-56 text-center"
            @click.prevent.stop="designAnother()"
          >
            <div class="flex flex-col justify-around flex-grow text-sm capitalize">
              <span>Switch Product</span>
            </div>
          </div>
        </div>
      </div>
      <!-- image -->
      <loading v-if="loading" :loading="true" :class="{ 'hidden xs:flex': !mobile_show_image }"></loading>
      <div
        v-else
        class="flex h-full z-10 xs:w-full pt-10 pb-5 xs:pb-10 sm:pb-0 xs:pt-0"
        :class="{ 'hidden xs:flex': !mobile_show_image }"
      >
        <div v-if="item" class="text-center flex flex-col mx-auto xs:w-full">
          <transition name="fade-in" appear>
            <div class="flex flex-col h-full justify-center">
              <div class="w-full flex flex-col mx-auto h-full xs:flex-row xs:w-full">
                <div
                  class="mt-auto h-full flex xs:mx-auto xs:w-full px-3"
                  :style="margin_style_for_item_box"
                  :class="{ 'xs:h-auto': !full_image_view }"
                >
                  <ShowSvg
                    v-if="current_image_type"
                    :branded-mode="true"
                    :svg="current_image.image"
                    :show-decoration-overrides="false"
                    :show-sizes="basketStore.contractHasSizing"
                    :show-dimensions="true"
                    :show-issues="false"
                    :mobile-show-image="mobile_show_image"
                    class="w-full"
                    @change-dimension="changeDimension($event.dimensionId, $event.value)"
                  />
                </div>
              </div>
              <div class="xs:hidden">
                <div class="text-xs">
                  Images are for illustration purposes only and are not to scale
                </div>
                <div v-if="current_image_type" class="flex justify-center pb-4">
                  <div
                    v-for="(image, key) in item.images"
                    :key="key"
                    :class="{
                      'text-black': current_image.type == image.type,
                      'text-gray-600': current_image.type != image.type,
                      'border-l': key != 0,
                    }"
                    class="text-center capitalize tracking-widest text-sm px-3 py-1 cursor-pointer hover:text-blue-600"
                    @click.prevent="current_image_type = image.type"
                  >
                    {{ image_types[image.type] }} View
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <!-- action bar with questions and other options for bigger screens -->
    <div class="text-xs w-full bg-gray-200 text-center hidden xs:block pb-2">
      Images are for illustration purposes only and are not to scale
    </div>
    <div
      v-if="open_question"
      class="justify-between bg-gray-200 relative hidden xs:flex flex-shrink-0"
    >
      <div class="px-4 lg:text-base" :class="{ 'absolute bottom-0 left-0 z-10': main_menu_open }">
        <div
          v-show="!full_image_view"
          class="bg-white border-t border-l border-r rounded-t-lg flex cursor-pointer"
          style="height: 57px"
        >
          <div
            v-if="questionNumber"
            class="bg-green-600 text-white border-l p-2 md:px-4 flex flex-col justify-around font-medium rounded-tl border-green-600"
            @click="prevQuestion"
          >
            <div>
              <i class="fal fa-chevron-left md:pr-2"></i>
              <span class="hidden md:inline">Previous Question</span>
            </div>
          </div>
          <div
            class="flex"
            @click="(main_menu_open = !main_menu_open), (other_options_dropdown_open = false)"
          >
            <div
              class="flex flex-col justify-around p-4 font-medium lg:text-base"
              style="width: 225px"
            >
              {{ open_question.name }}
            </div>
            <div class="flex flex-col justify-around p-4 border-l border-r">
              {{ open_question.questionNumber }} of {{ sorted_sidebar_questions.length }}
            </div>
            <div class="p-4 text-gray-700 flex flex-col justify-around">
              <i class="fal fa-bars"></i>
            </div>
          </div>
          <router-link
            v-if="open_question.questionNumber === sorted_sidebar_questions.length"
            :to="brandedStore.basketURL"
            class="bg-green-600 text-white border-l p-2 px-4 flex flex-col justify-around font-medium rounded-tr border-green-600"
          >
            Finish <span class="hidden md:inline">Designing</span>
          </router-link>
          <div
            v-else
            class="bg-green-600 text-white border-l p-2 md:px-4 flex flex-col justify-around font-medium rounded-tr border-green-600"
            @click="nextQuestion()"
          >
            <div>
              <span class="hidden md:inline">Next Question</span>
              <i class="fal fa-chevron-right md:pl-2"></i>
            </div>
          </div>
        </div>
        <div v-if="main_menu_open && !full_image_view">
          <div
            class="bg-white border border-gray-300 border-b-0 overflow-auto"
            style="height: 50vh"
          >
            <div
              v-for="(question, index) in sorted_sidebar_questions"
              :key="index"
              class="py-3 px-6 hover-bg-brand-primary_light cursor-pointer whitespace-nowrap flex justify-between"
              :class="{ 'bg-brand-primary_light': open_question === question }"
              @click.stop="(questionNumber = index), (main_menu_open = false)"
            >
              <div class="flex flex-col justify-around">
                <div>{{ index + 1 }}. {{ question.name }}</div>
              </div>
              <div v-if="open_question === question" class="flex flex-col justify-around">
                <i class="fa fa-caret-right"></i>
              </div>
            </div>
            <div
              class="py-3 px-6 hover-bg-brand-primary_light cursor-pointer whitespace-nowrap flex xs:hidden justify-between"
              @click="designAnother()"
            >
              <div class="flex flex-col justify-around">
                <div>Switch Product</div>
              </div>
              <div class="flex flex-col justify-around">
                <span><i class="fal fa-repeat-alt fa-xs leading-loose"></i></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--placeholder div to stop the height jumping when menus go absolute positioned-->
      <div style="height: 57px"></div>
      <!--other options buttons for above ipad portrait-->
      <div class="flex ml-auto mr-2" style="height: 57px">
        <div class="hidden sm:flex mx-1 mt-auto">
          <div
            v-for="(image, key) in item.images"
            v-show="next_image_type == image.type"
            :key="key"
            class="cursor-pointer flex justify-between bg-white py-2 px-3 border rounded mb-3 lg:w-48 xl:w-56 text-center"
            @click.prevent.stop="current_image_type = image.type"
          >
            <div class="flex flex-col justify-around flex-grow text-sm capitalize">
              Show {{ image_types[image.type] }} View
            </div>
          </div>
        </div>
        <div class="hidden sm:flex mx-1 mt-auto">
          <div
            class="cursor-pointer flex justify-between bg-white py-2 px-3 border rounded mb-3 lg:w-48 xl:w-56 text-center"
            @click.prevent.stop="full_image_view = !full_image_view"
          >
            <div class="flex flex-col justify-around flex-grow text-sm capitalize">
              <span v-if="!full_image_view">Full Screen Image</span>
              <span v-if="full_image_view">Show Menus</span>
            </div>
          </div>
        </div>
        <div class="hidden sm:flex mx-1 mt-auto">
          <div
            class="cursor-pointer flex justify-between bg-white py-2 px-3 border rounded mb-3 lg:w-48 xl:w-56 text-center"
            @click.prevent.stop="designAnother()"
          >
            <div class="flex flex-col justify-around flex-grow text-sm capitalize">
              <span>Switch Product</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- question tiles -->
    <div
      v-if="item && open_question"
      class="bg-gray-200 xs:border-t xs:border-gray-300 flex-col overflow-y-scroll scrolling-touch xs:overflow-y-visible flex-grow z-1 h-full xs:h-auto"
      :class="{ hidden: !mobile_show_options || full_image_view }"
    >
      <div class="xs:max-w-lg pt-2">
        <search-bar
          v-if="open_question.options.length > 10"
          v-model="options_filter"
          :filters="open_question.filters"
          :filter-id="filterId"
          @set-filter-id="filterId = $event"
        />
      </div>
      <div class="flex">
        <div class="overflow-hidden flex-grow">
          <div class="flex flex-wrap xs:flex-nowrap xs:overflow-x-scroll scrolling-touch">
            <answer-tile
              v-for="option in filtered_options"
              :key="option.id"
              :loading="loading"
              :option="option"
              :title="open_question.name"
              :text="open_question.text"
              :text-validation="open_question.validation"
              @update-option="updateOption(open_question.optionHeadingId, $event)"
              @set-text="updateOption(open_question.optionHeadingId, option.id, $event)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-between xs:hidden bg-gray-200 border-t border-gray-400 flex-shrink-0 cursor-pointer">
      <div class="flex-1 p-3 flex flex-col justify-around items-center text-center bg-white">
        <div
          v-if="mobile_show_options"
          @click.prevent="
            (main_menu_open = false),
            (mobile_show_image = true),
            (mobile_show_options = false),
            (full_image_view = true)"
        >
          <i class="fal fa-window-frame pr-1"></i> View Product
        </div>
        <div
          v-if="mobile_show_image"
          @click.prevent="
            (main_menu_open = false),
            (mobile_show_options = true),
            (mobile_show_image = false),
            (full_image_view = false)"
        >
          <i class="fal fa-list pr-1"></i> View Options
        </div>
      </div>
      <div
        class="flex-1 p-3 flex flex-col justify-around items-center text-center bg-green-600 text-white"
      >
        <router-link :to="brandedStore.basketURL" :class="{ 'pointer-events-none': loading }">
          <i class="fal fa-check-square pr-1"></i> Finish Designing
        </router-link>
      </div>
    </div>
  </touch-layout>
</template>

<script>

import _ from 'lodash';
import { mapStores } from 'pinia'
import { useBrandedStore } from '@/pinia/branded';
import { useBasketStore } from '@/pinia/basket';
import AnswerTile from '@/components/branded/designer/AnswerTile.vue';
import Dimension from '@/components/shared/Dimension.vue';
import SearchBar from '@/components/shared/designer/SpecificationGroup/SearchBar.vue';
import ShowSvg from '@/components/shared/ShowSvg.vue';

export default {
  components: {
    'answer-tile': AnswerTile,
    dimension: Dimension,
    SearchBar,
    ShowSvg
  },
  // eslint-disable-next-line consistent-return
  beforeRouteLeave(to, from, next) {
    if (this.do_before_route_leave) {
      this.dimensionBoxesHighlight(false);
      if (this.requiresMeasurements) {
        if (window.innerWidth < 768) {
          this.mobile_show_options = false;
          this.mobile_show_image = true;
        }
        this.alertBox()
          .fire('You must supply dimensions before saving')
          .then(() => {
            this.dimensionBoxesHighlight(true);
          });
        next(false);
        return false;
      }
    }
    next();
  },
  data() {
    return {
      loading: true,
      current_image_type: null,
      image_toggle_hover: false,
      reset_hover: false,
      mobile_show_image: false,
      mobile_show_options: true,
      full_image_view: false,
      questionNumber: 0,
      image_types: window.imageTypes,
      main_menu_open: false,
      other_options_dropdown_open: false,
      do_before_route_leave: true,
      options_filter: '',
      filterId: undefined,
      item: undefined,
      isMobile: false
    };
  },
  computed: {
    ...mapStores(useBrandedStore, useBasketStore),
    open_question() {
      return this.sorted_sidebar_questions[this.questionNumber];
    },
    previous_question() {
      let previousQuestionNumber = this.questionNumber - 1;
      if (previousQuestionNumber < 0) {
        previousQuestionNumber = this.sorted_sidebar_questions.length - 1;
      }
      const question = this.sorted_sidebar_questions[previousQuestionNumber];
      return question;
    },
    next_question() {
      let nextQuestionNumber = this.questionNumber + 1;

      if (nextQuestionNumber === this.sorted_sidebar_questions.length) {
        nextQuestionNumber = 0;
      }
      return this.sorted_sidebar_questions[nextQuestionNumber];
    },
    current_image() {
      for (let i = 0; i < this.item.images.length; i += 1) {
        if (this.current_image_type === this.item.images[i].type) {
          return this.item.images[i];
        }
      }

      throw new Error(`Unable to find image for type: ${this.current_image_type}`);
    },
    next_image_type() {
      let { images } = this.item;

      if (!this.basketStore.contractHasSizing) {
        images = this.item.images.filter((image) => image.type !== window.enum.imageType.PLAN);
      }

      let currentImageIndex = -1;

      for (let i = 0; i < images.length; i += 1) {
        if (this.current_image_type === images[i].type) {
          currentImageIndex = i;
        }
      }

      if (currentImageIndex + 1 < images.length) {
        return images[currentImageIndex + 1].type;
      }

      return images[0].type;
    },
    sorted_sidebar_questions() {
      if (!this.item) {
        return [];
      }

      const sortedList = Object.values(
        _.groupBy(
          this.item.specification.filter(
            (specification) =>
              specification.visibilityTypeId !==
              window.enum.visibilityType.ONLY_FOR_DESIGN_SELECTION,
          ),
          (spec) => `${spec.optionGroupName}---${spec.optionGroupOrder}`,
        ),
      )
        .sort((a, b) => a[0].optionGroupOrder - b[0].optionGroupOrder)
        .flatMap((option) => option.sort((a, b) => a.listOrder - b.listOrder));

      return sortedList.map((question, index) => ({ ...question, questionNumber: index + 1 }));
    },
    margin_style_for_item_box() {
      if (this.full_image_view && window.innerWidth < 768 && window.innerWidth >= 1024) {
        return {
          paddingTop: '110px',
        };
      }
      if (!this.full_image_view) {
        return {
          marginTop: '0px',
        };
      }
      return {};
    },
    requiresMeasurements() {
      try {
        const needsDimensionInput = this.item.dimensions.some(
          (dimension) => dimension.requiresInput && !dimension.hasBeenInput,
        );
        return this.basketStore.contractHasSizing && needsDimensionInput;
      } catch (e) {
        return false;
      }
    },
    filtered_options() {
      let options = this.open_question.options;
      if (this.options_filter) {
        options = options.filter(
          (option) =>
            option.description.toLowerCase().indexOf(this.options_filter.trim().toLowerCase()) !==
            -1 || option.isCurrentOption,
        );
      }

      if (this.filterId) {
        options = options.filter((option) =>
          option.filterId === this.filterId,
        );
      }
      return options
    },
  },
  watch: {
    open_question(question) {
      this.options_filter = '';
      this.filterId = undefined
      if (question && question.drawingType && question.drawingType !== this.current_image_type) {
        this.current_image_type = question.drawingType;
      }
    },
    sorted_sidebar_questions(newList) {
      if (this.questionNumber >= newList.length) {
        this.questionNumber = 0;
      }
    },
  },
  created() {
    window.addEventListener('resize', this.resize);
    this.resize()
  },
  unmounted() {
    window.removeEventListener('resize', this.resize);
  },
  async mounted() {
    if (!this.basketStore.hasContract) {
      this.routerPush(this.brandedStore.launchURL());
      return
    }

    this.loading = true;
    this.loadItem();
    this.loading = false;
  },
  methods: {
    resize() {
      if (window.innerWidth >= 768) {
        this.full_image_view = false;
        this.mobile_show_options = true
        this.mobile_show_image = false
        this.isMobile = false
      } else {
        this.full_image_view = true;
        this.mobile_show_image = true
        this.mobile_show_options = false
        this.isMobile = true
      }
    },
    async loadItem() {
      const item = await this.basketStore.loadItem({
        itemKey: this.$route.params.id,
        customerID: this.$route.params.customerId,
      });
      this.item = await this.setCurrentItem(item);

      if (!this.item) {
        this.routerPush(this.brandedStore.basketURL);
        return;
      }

      this.item.images = this.item.images.filter(
        (image) => image.type !== window.enum.imageType.GLASS_SCHEMATIC,
      );

      this.current_image_type = this.item.images[0].type;

      if (
        this.basketStore.contractHasSizing &&
        this.item.images.find((image) => image.type === window.enum.imageType.PLAN) !== undefined
      ) {
        this.current_image_type = window.enum.imageType.PLAN;
      }
    },
    async changeDimension(dimensionId, value) {
      if (!this.loading) {
        this.loading = true;
        const item = await this.basketStore.updateItemDimension({
          itemKey: this.$route.params.id,
          dimensionId,
          value,
        });
        this.item = await this.setCurrentItem(item);
      }
      this.loading = false;
    },
    async updateOption(heading, value, text = undefined) {
      const components = this.open_question.components.map((x) => x.id);
      if (this.isMobile) {
        this.main_menu_open = false
        this.mobile_show_image = true
        this.mobile_show_options = false
        this.full_image_view = true
      }
      if (!this.loading) {
        this.loading = true;
        const item = await this.basketStore.updateItemOption({
          itemKey: this.$route.params.id,
          heading,
          value,
          text,
          components,
          rule: 0,
        });
        this.item = await this.setCurrentItem(item);
      }
      this.loading = false;
    },
    toggleSidebarControl(controlName) {
      if (this.open_sidebar_control === controlName) {
        this.open_sidebar_control = '';
      } else {
        this.open_sidebar_control = controlName;
      }
    },
    prevQuestion() {
      this.questionNumber -= 1;

      if (this.questionNumber === -1) {
        this.questionNumber = this.sorted_sidebar_questions.length - 1;
      }
    },
    nextQuestion() {
      this.questionNumber += 1;

      if (this.questionNumber === this.sorted_sidebar_questions.length) {
        this.questionNumber = 0;
      }
    },
    designAnother() {
      this.alertBox()
        .fire({
          customClass: {
            cancelButton: 'absolute top-0 right-0 p-3',
            confirmButton: 'btn-action m-1',
            denyButton: 'btn m-1',
          },
          title: 'Switch Product',
          text: 'Would you like to go back and select a different product?',
          icon: 'question',
          className: 'relative',
          showCancelButton: true,
          showDenyButton: true,
          denyButtonText: 'Yes, and delete my current product',
          confirmButtonText: 'Yes, but save my current product',
          cancelButtonText: '<i class="fal fa-times"></i>',
        })
        .then(async (result) => {
          if (result.isDismissed) {
            return;
          }

          if (!result.isConfirmed) {
            this.do_before_route_leave = false;
            this.basketStore.deleteLineItem({
              lineItemId: this.$route.params.id,
            });
          }

          this.routerPush(this.brandedStore.chooseProductURL);
        });
    },
    async setCurrentItem(item) {
      this.basketStore.SET_CURRENT_ITEM(item);
      return item;
    },
    dimensionBoxesHighlight(highlight) {
      const inputBoxes = document.querySelectorAll('.input-wrapper');
      inputBoxes.forEach((inputBox) => {
        if (highlight) {
          inputBox.classList.add('highlighted');
        } else {
          inputBox.classList.remove('highlighted');
        }
      });
    },
  },
};
</script>
<style scoped>
.button-3d-active {
  background-color: #d6f0df;
  border-color: theme('colors.green.400');
}
</style>
