<template>
  <div class="w-full">
    <div class="bg-white overflow-x-auto pl-3 md:pl-10">
      <div class="flex bg-white gap-10 border-t-4 border-white">
        <router-link
          :to="`/customers/portal`"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black"
          :class="{
            'border-black': $route.name === 'Customers',
            'border-white': $route.name !== 'Customers',
          }"
        >
          Portal Customers
        </router-link>
        <router-link
          v-if="is_admin_user"
          :to="`/customers/registration-requests`"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black"
          :class="{
            'border-black': $route.name === 'Registration Requests',
            'border-white': $route.name !== 'Registration Requests',
          }"
        >
          Registration Requests
        </router-link>
        <router-link
          v-if="is_admin_user"
          :to="`/customers/branded-mode`"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black"
          :class="{
            'border-black': $route.name === 'Branded Mode Customers',
            'border-white': $route.name !== 'Branded Mode Customers',
          }"
        >
          Branded Mode Subscriptions
        </router-link>
        <div class="pr-5"></div>
      </div>
    </div>
  </div>
</template>
