<template>
  <Teleport defer to="#portal_popup">
    <modal-window :modal_open="true" title="Edit File" :loading="saving" @close="$emit('close')">
      <div class="flex -mx-3">
        <div class="p-3 w-1/2">
          <div class="font-medium">Title</div>
          <div>
            <input v-model="document.name" class="form-control" />
          </div>
        </div>
        <div class="p-3 w-1/2">
          <div class="font-medium">Category</div>
          <div>
            <select v-model="document.categoryId" class="form-control">
              <option v-for="category in categories" :key="category.id" :value="category.id">
                {{ category.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <Dropzone
        label="file"
        :current_file="document.url"
        :removable="false"
        :compatible="['.pdf', '.png', '.jpg', '.jpeg']"
        :max-size="100000000"
        @add-file="document.File = $event"
        @remove-file="document.File = null"
        @ignore-file="document.File = undefined"
      ></Dropzone>
      <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
        <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
          Cancel
        </button>
        <button v-if="!saving" class="btn-action btn-lg" @click.prevent.stop="save()">Save</button>
        <button v-else class="btn-action btn-lg btn-disabled">Saving</button>
      </div>
    </modal-window>
  </Teleport>
</template>

<script>
import { mapStores } from 'pinia'
import { useCustomerStore } from '@/pinia/customer';
import Dropzone from '@/components/shared/Dropzone.vue';

export default {
  components: {
    Dropzone,
  },
  props: ['categories', 'document'],
  data() {
    return {
      saving: false,
      errors: [],
      file_url: null,
    };
  },
  computed: {
    ...mapStores(useCustomerStore),
  },
  methods: {
    async save() {
      if (!this.saving) {
        this.saving = true;

        if (this.document.CategoryId === null) {
          this.alertBox().fire({
            title: 'No Category Chosen',
            text: 'Please choose a Document Category.',
            icon: 'error',
          });
          this.saving = false;
          return;
        }

        if (this.document.File === null && this.file_url === null) {
          this.alertBox().fire({
            title: 'No File Chosen',
            text: 'Please choose a File or add a file URL.',
            icon: 'error',
          });
          this.saving = false;
          return;
        }

        if (this.file_url === null) {
          const formData = new FormData();
          formData.append('Id', this.document.id);
          formData.append('Name', this.document.name);
          formData.append('CategoryId', this.document.categoryId);
          formData.append('Type', this.enums.documentType.RESOURCE_FILE.toString());

          if (this.document.File) {
            formData.append('File', this.document.File);
          }

          await window.touch.uploadDocumentForm(formData).then(() => {
            this.saving = false;
            this.$emit('close');
          });
        } else {
          if (!this.document.url.includes('//')) {
            this.document.url = `http://${this.document.url}`;
          }
          const document = {
            id: this.document.id,
            name: this.document.Name,
            type: this.enums.documentType.RESOURCE_FILE.toString(),
            categoryId: this.document.categoryId,
            url: this.document.url,
          };
          await this.customerStore.uploadDocumentWithUrl(document).then(() => {
            this.saving = false;
            this.$emit('added');
            this.$emit('close');
          });
        }
      }
    },
  },
};
</script>
