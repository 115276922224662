<template>
  <div>
    <div class="text-brand-primary hidden sm:block">
      <div class="absolute top-0 right-0 -z-1 left-0">
        <img :src="$t('/images/login-background.png')" class="ml-auto w-1/2" />
      </div>
    </div>
    <header
      class="flex justify-between sm:border-none bg-white sm:bg-transparent"
      style="height: 71px"
    >
      <a :href="$t(store_url)" class="inline-block">
        <img :src="style.fabricator_logo" class="max-h-full sm:mx-auto" />
      </a>
      <div class="flex">
        <div v-if="style.back_to_site_link" class="flex flex-col">
          <a
            :href="style.back_to_site_link"
            class="bg-white rounded-full hover:bg-gray-200 my-auto mx-3 sm:py-2 sm:pr-6 sm:pl-5 sm:m-5 sm:font-medium"
          >
            <i class="fal fa-browser mr-3"></i> <span>Back to site</span>
          </a>
        </div>
        <div class="flex flex-col justify-around sm:block">
          <a
            :href="$t(store_url)"
            target="_blank"
            class="whitespace-nowrap block border border-brand-primary sm:border-white text-brand-primary sm:text-white m-1 xs:m-3 sm:m-6 rounded-full p-2 xs:py-3 xs:px-6 hover:bg-brand-primary hover:text-white sm:hover:text-brand-primary sm:hover:bg-white"
            v-text="$t('Touch Store')"
          >
          </a>
        </div>
      </div>
    </header>

    <div class="flex flex-col h-full bg-gray-200 sm:bg-transparent">
      <div class="mx-auto w-full flex justify-around" style="max-width: 1600px">
        <div class="login-form-wrapper">
          <transition name="fade-in" appear mode="out-in">
            <div v-if="token">
              <div class="text-center mb-4 bg-white py-4 sm:hidden">
                <div class="text-xl sm:text-4xl mb-2 sm:font-medium">Set your password</div>
                <div class="sm:text-xl">Enter a password.</div>
                <p class="pt-6 login-form">
                  Please provide a password for the account. This must be 8 characters in length as
                  a minimum with at least one upper case letter, one lower case and one number.
                </p>
              </div>

              <div class="login-form">
                <div class="text-center mb-4 bg-white py-0 hidden sm:block">
                  <div class="text-4xl mb-2 font-medium">Set your password</div>
                  <div class="text-xl">Enter a password.</div>
                  <p class="mt-4">
                    Please provide a password for the account. This must be 8 characters in length
                    as a minimum with at least one upper case letter, one lower case and one number.
                  </p>
                </div>

                <ErrorBox v-if="login_error" :message="login_error"></ErrorBox>

                <div class="mb-4">
                  <label for="newPassword" class="block w-full">Password</label>
                  <input
                    id="newPassword"
                    v-model="newPassword"
                    type="password"
                    class="border border-solid border-gray-400 rounded block w-full p-2"
                    autocomplete="newPassword"
                  />
                </div>

                <div class="mb-4">
                  <label for="newPasswordVerify" class="block w-full">Confirm Password</label>
                  <input
                    id="newPasswordVerify"
                    v-model="newPasswordVerify"
                    type="password"
                    class="border border-solid border-gray-400 rounded block w-full p-2"
                    autocomplete="newPasswordVerify"
                  />
                </div>

                <div
                  class="btn-primary btn-lg w-full"
                  :class="{ 'pointer-events-none opacity-50': loading }"
                  @click.prevent.stop="setNewPassword()"
                >
                  Set Password
                </div>

                <div class="text-center mt-2">
                  <a
                    :href="$t(store_url)"
                    target="_blank"
                    class="inline-flex bg-white border border-solid border-gray-400 rounded p-1"
                  >
                    <div class="p-1">
                      <img src="../../assets/images/touch-logo.svg" width="75px" />
                    </div>
                    <div class="">
                      <template v-if="touch_portal_installation">
                        <img src="/images/touch-portal-icon.png" style="max-height: 27px" />
                      </template>
                    </div>
                    <div class="p-1 text-xs">By BM Group</div>
                  </a>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';
import ErrorBox from '@/components/shared/Error.vue';

export default {
  components: {
    ErrorBox
  },
  data() {
    return {
      login_error: false,
      email: null,
      newPassword: null,
      newPasswordVerify: null,
      token: this.$route.query.token,
      loading: true,
    };
  },
  computed: {
    ...mapStores(useAuthStore),
  },
  async mounted() {
    await this.checkTokenIsValid();
    this.loading = false;
  },
  methods: {
    async checkTokenIsValid() {
      return this.authStore.checkPasswordResetToken(this.token).then((resp) => {
        if (!resp.valid) {
          this.alertBox().fire({
            title: 'Invalid Token',
            icon: 'error',
            html: `<div>${resp.message}</div>`,
          });
          this.routerPush('/login');
          return;
        }
        this.email = resp.email;
      });
    },
    async setNewPassword() {
      this.login_error = false;
      const { token, newPassword, newPasswordVerify } = this;
      if (newPassword !== newPasswordVerify) {
        this.login_error = 'Passwords do not match';
        return false;
      }
      if (!this.email || !this.newPassword || !this.newPasswordVerify) {
        this.login_error = 'All fields are required';
        return false;
      }
      this.loading = true;
      return this.authStore.resetPassword({ token, newPassword })
        .then(() => {
          this.attemptLogin().then(async (loggedIn) => {
            if (loggedIn) {
              this.login_error = false;
              this.routerPush(this.$route.query.redirect ?? '/');
              return;
            }

            this.login_error = 'Unable to login, Please check your username and try again';
            this.loading = false;
          });
        })
        .catch((error) => {
          this.login_error = error.data.message;
          this.loading = false;
        });
    },
    attemptLogin() {
      const details = {
        username: this.email,
        password: this.newPassword,
      };
      if (this.touch_portal_installation) {
        return this.authStore.attemptPortalLogin(details);
      }
      return this.authStore.attemptBusinessLogin(details);
    },
  },
};
</script>
