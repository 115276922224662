<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <slot name="header_page_breadcrumb"></slot>
    </template>
    <template #header_page_title>
      <slot name="header_page_title"></slot>
    </template>
    <template #header_page_actions>
      <div v-if="authStore.superuser" class="px-3 md:px-10 flex items-center">
        <button class="btn-action" @click="add_discount_open = true">Add Discount</button>
      </div>
    </template>
    <slot name="menu"></slot>
    <div class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch overflow-x-hidden"> 
      <create-discount
        v-if="add_discount_open"
        @close="add_discount_open = false"
        @saved="loadDiscounts"
      ></create-discount>

      <div class="bg-white border m-5 p-5">
        <table v-if="discounts.length > 0" class="table-lined xs:table-unstacked _with-hover w-full">
          <thead>
            <tr>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(discount, index) in discounts"
              :key="index"
              :class="{ _hovered: hovered === index }"
              @click.stop.prevent="editing_discount = discount.id"
              @mouseover="hovered = index"
              @mouseleave="hovered = null"
            >
              <td>
                <div class="td-title w-24">Description:</div>
                <div class="td-content">{{ discount.description }}</div>
              </td>
              <td class="td-action-buttons">
                <div v-if="touch_business_user || loggedInUser.isAdmin" class="table-edit-btn">
                  <i class="fa fa-pencil"></i>
                </div>
                <edit-discount
                  v-if="editing_discount === discount.id"
                  :discount="discount"
                  @close="(editing_discount = null), loadDiscounts()"
                  @updated="loadDiscounts"
                ></edit-discount>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else>No Discounts to Show</div>
      </div>
    </div>
  </touch-layout>
</template>

<script>

import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';
import { useTouchStore } from '@/pinia/touch';
import EditDiscount from '@/components/business/productSetup/discounts/Edit.vue';
import CreateDiscount from '@/components/business/productSetup/discounts/Create.vue';

export default {
  name: 'ManageDiscounts',
  components: {
    CreateDiscount,
    EditDiscount,
  },
  data() {
    return {
      discounts: [],
      editing_discount: null,
      hovered: null,
      add_discount_open: false,
    };
  },
  computed: {
    ...mapStores(useAuthStore, useTouchStore),
  },
  mounted() {
    this.loadDiscounts();
  },
  methods: {
    async loadDiscounts() {
      this.discounts = (await this.touchStore.staffListDiscountCategories())
        .slice()
        .sort((a, b) => a.listIndex - b.listIndex);
    },
  },
};
</script>

<style scoped></style>
