import EditDetailsRequoteSettings from '@/views/Customer/EditDetails/RequoteSettings.vue';
<template>
  <Teleport v-if="modal_open" defer to="#portal_popup">
    <modal-window :modal_open="true" title="Edit Pricing For Customers" @close="$emit('close')">
      <div>Part Name: {{ partDescription }}</div>
      <div>Item Colour: {{ colour }}</div>
      <div>Item Code: {{ itemCode }}</div>
      <div>Global Price: {{ globalPrice }}</div>
      <div class="bg-white border m-3 md:m-10 p-3 flex items-center justify-center">
        <table class="table-lined xs:table-unstacked">
          <thead class="top-0 bg-white">
            <tr>
              <td>Customer</td>
              <td>Customer Price</td>
              <td>FOC</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id" class="mr-2">
              <td>{{ item.customerName }}</td>
              <td>
                <div class="inline-block">
                  <div class="absolute py-2 px-3 border rounded-l-lg">£</div>
                  <input
                    v-model="item.nettPrice"
                    :class="{ 'cursor-not-allowed text-gray-400': item.foc }"
                    :disabled="item.foc"
                    type="text"
                    class="flex-grow bg-white p-2 rounded-lg pl-16 w-32 border border-l-0"
                    @input="hasChangedItems = true"
                  />
                </div>
              </td>
              <td>
                <input v-model="item.foc" type="checkbox" @input="hasChangedItems = true" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <button class="btn-action btn-lg" @click="saveSettings">Save Changes</button>
    </modal-window>
  </Teleport>
</template>
<script>
import { mapStores } from 'pinia'
import { useCustomerStore } from '@/pinia/customer';

export default {
  props: {
    itemId: {
      type: Number,
      required: true,
    },
    isModalOpen: {
      type: Boolean,
      default: false,
    },
    globalPrice: {
      type: Number,
    },
    itemCode: {
      type: String,
    },
    colour: {
      type: String,
    },
    partDescription: {
      type: String,
    },
  },
  data() {
    return {
      items: [],
      hasChangedItems: false,
      modal_open: false,
    };
  },
  computed: {
    ...mapStores(useCustomerStore),
  },
  async mounted() {
    this.items = await window.Touch.stockListStockCustomerPrice(null, this.itemId);
    this.items.forEach(async (x) => {
      // eslint-disable-next-line
      const name = (await this.customerStore.customerGet(x.customerId))?.company?.name;
      x.customerName = name
    });
    this.modal_open = true;
  },
  methods: {
    async saveSettings() {
      if (this.hasChangedItems) {
        await window.Touch.stockUpdateStockCustomerPrice(
          this.items
            .filter((x) => x.nettPrice !== undefined || x.foc !== undefined)
            .map((x) => ({
              foc: x.foc,
              nettPrice: x.nettPrice,
              customerId: this.$route.params.id,
              stockId: x.stockItemId,
              id: x.id,
            })),
        );
        this.alertBox().fire({
          title: 'Changes Saved',
        });
      }
    },
  },
};
</script>
