<template>
  <div class="">
    <div class="2xs:flex relative">
      <Dropzone
        :current_file="image"
        :orientation="'portrait'"
        :removable="false"
        @add-file="image = $event"
        @remove-file="image = null"
        @ignore-file="image = undefined"
      ></Dropzone>
      <div class="flex-grow 2xs:ml-3 2xs:pl-3 2xs:border-l 2xs:border-gray-200">
        <div class="flex flex-col xs:flex-row xs:items-center mb-2">
          <label class="xs:w-32" for="name"> Title </label>
          <input id="name" v-model="title" type="text" class="form-control" />
        </div>
        <div class="flex flex-col xs:flex-row xs:items-center mb-2">
          <label class="w-32" for="description"> Description </label>
          <textarea id="description" v-model="description" rows="5" class="form-control"></textarea>
        </div>
        <div class="flex flex-col xs:flex-row xs:items-center mb-2">
          <label class="w-32" for="listOrder"> List Order </label>
          <input id="listOrder" v-model="listOrder" class="form-control" />
        </div>
      </div>
    </div>
    <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
      <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
        Cancel
      </button>
      <button
        class="btn-danger btn-lg 2xs:mr-1 mb-1 2xs:mb-0"
        @click.prevent.stop="deleteOption(group.id)"
      >
        Delete
      </button>
      <button class="btn-action btn-lg" @click.prevent.stop="saveOption()">Update</button>
    </div>
  </div>
</template>

<script>
import validate from 'validate.js';
import Dropzone from '@/components/shared/Dropzone.vue';

export default {
  components: {
    Dropzone
  },
  props: ['group'],
  data() {
    return {
      title: undefined,
      description: undefined,
      image: undefined,
      listOrder: undefined
    };
  },
  created() {
    this.title = this.group.title
    this.description = this.group.description
    this.image = `${window.touch.imageBaseURL + this.group.imageBlob}?${new Date().getTime()}`;
    this.listOrder = this.group.listOrder
  },
  methods: {
    inputIsValid() {
      return validate(
        {
          title: this.title,
          description: this.description,
        },
        {
          title: {
            presence: { allowEmpty: false },
          },
          description: {
            presence: { allowEmpty: false },
          },
        },
      );
    },
    saveOption() {
      const validationErrors = this.inputIsValid();

      if (validationErrors) {
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        return;
      }

      this.$emit('update-product-group', {
        title: this.title,
        description: this.description,
        ImageBlob: this.image,
        listOrder: this.listOrder,
      });

      this.image = null;
      this.title = '';
      this.description = null;
    },
    deleteOption(optionId) {
      this.alertBox()
        .fire({
          title: 'Delete this group, are you sure?',
          text: 'This action cannot be undone.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$emit('delete', { groupId: optionId });
          }
        });
    },
  },
};
</script>

<style scoped></style>
