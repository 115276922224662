<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Staff'],
        ]"
      />
    </template>
    <template #header_page_title> Staff </template>
    <Teleport defer to="#portal_search">
      <SearchBox class="flex" placeholder="Search for staff..." />
    </Teleport>
    <template #header_page_actions>
      <div class="px-3 md:px-10 flex items-center">
        <button class="btn-action" @click="add_staff_popup_open = true">Add Staff</button>
      </div>
    </template>
    <loading
      :loading="false"
      class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch overflow-x-hidden"
    >
      <Staff
        :add_staff_popup_open="add_staff_popup_open"
        @close-add-staff-popup="add_staff_popup_open = false"
      ></Staff>
    </loading>
  </touch-layout>
</template>

<script>
import Staff from '@/components/business/manageTouchAccount/Staff.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import SearchBox from '@/components/shared/SearchBox.vue';

export default {
  components: {
    Staff,
    Breadcrumbs,
    SearchBox
  },
  data() {
    return {
      add_staff_popup_open: false,
    };
  },
};
</script>
