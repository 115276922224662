<template>
  <div
    class="h-full hover-bg-brand-primary_light shadow-md bg-white text-center rounded-lg p-4 cursor-pointer"
    :class="{ 'cursor-wait': loading, 'opacity-25': loading }"
  >
    <label class="cursor-pointer" :class="{ 'cursor-wait': loading }">
      <input
        :key="option.id"
        type="radio"
        :value="option.id"
        :name="spec.optionHeadingId"
        class="hidden"
        @click="
          $emit('change-value', { heading: spec.optionHeadingId, value: $event.target.value })
        "
      />
      <div
        class="cursor-pointer flex flex-col justify-around"
        :class="{ 'cursor-wait': loading }"
        style="min-height: 140px"
      >
        <div v-if="option.imageUrl != ''" class="relative inline">
          <api-img
            :src="option.imageUrl"
            class="mx-auto"
            style="max-height: 75px; width: auto; height: auto"
          />
          <div
            v-if="optionTicked"
            class="rounded-full text-white border absolute bg-brand-primary text-xs flex flex-col justify-around"
            style="width: 20px; height: 20px; bottom: -5px; right: -5px"
          >
            <i class="fal fa-check"></i>
          </div>
        </div>
        <template v-if="option.imageUrl == ''">
          <div
            class="rounded-full text-white border mx-auto p-1 flex flex-col justify-around"
            :class="{
              'bg-brand-primary': optionTicked,
              'bg-gray-200': !optionTicked,
            }"
            style="width: 35px; height: 35px"
          >
            <i v-if="optionTicked" class="fal fa-check"></i>
          </div>
        </template>
        <span class="inline-block flex flex-col justify-around text-xs">
          {{ option.description }}
        </span>
      </div>
    </label>
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useDesignerStore } from '@/pinia/designer';

export default {
  props: ['loading', 'option', 'spec', 'hideTick'],
  computed: {
    ...mapStores(useDesignerStore),
    optionTicked() {
      return (
        this.option.isCurrentOption &&
        this.hideTick !== true &&
        !this.designerStore.itemHasUnansweredForcedQuestions
      );
    },
  },
};
</script>
