<template>
  <div class="flex flex-col">
    <Teleport v-if="popupOpen" defer to="#portal_popup">
      <note-popup
        v-show="popupOpen"
        :note="note"
        :note-type="noteType"
        @change="save($event)"
        @close="popupOpen = false"
      >
        <slot></slot>
      </note-popup>
    </Teleport>
    <div class="font-bold flex justify-between">
      <div>
        <slot></slot>
      </div>
      <div>
        <div
          v-if="!demo_mode && !readOnly"
          class="px-2 py-1 text-2xs rounded text-green-600 border border-green-600 cursor-pointer inline-block"
          @click="createNote()"
        >
          <i class="fa fa-pencil"></i>
        </div>
      </div>
    </div>
    <div v-if="note" class="border-t mt-3 break-words pt-3">{{ note.text }}</div>
    <div v-else class="border-t mt-3 pt-3 text-gray-300">No Notes.</div>
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useTouchStore } from '@/pinia/touch';
import NotePopup from '@/components/shared/NotePopup.vue';

export default {
  components: {
    'note-popup': NotePopup,
  },
  props: ['readOnly', 'contractId', 'jobId', 'noteType', 'note'],
  data() {
    return {
      popupOpen: false,
    };
  },
  computed: {
    ...mapStores(useTouchStore),
  },
  methods: {
    async save(text) {
      if (this.note) {
        await this.touchStore.updateNote({
          contractId: this.contractId,
          jobId: this.jobId,
          noteType: this.noteType,
          noteId: this.note.id,
          text,
          deleteNote: text.length === 0,
        });
      } else {
        await this.touchStore.createNote({
          contractId: this.contractId,
          jobId: this.jobId,
          noteType: this.noteType,
          text,
        });
      }
      this.$emit('loadNotes');
    },
    createNote() {
      this.popupOpen = true;
    },
  },
};
</script>
<style scoped>
/* this css property is deprecated however it is the only thing I could find that would solve
an annoying issue where the page overflowed horizontally due to the word breaking. Seems like it's something
to do with how the different word-break/overflow-wrap/word-wrap properties calculate minimum widths. */
.break-words {
  word-break: break-word;
}
</style>
