<template>
  <Teleport defer to="#portal_popup"> 
    <modal-window
      :modal_open="true"
      :title="isCreatingNewRack ? 'Add Rack Type' : 'Update Rack Type'"
      @close="$emit('close')"
    >
      <div class="text-green-600 pl-3 flex flex-col justify-around font-medium text-base">
        * Required Fields
      </div>
      <div class="flex flex-wrap">
        <div class="w-full mt-5 xs:w-1/2 xs:pr-5 xs:mt-8">
          <div class="font-medium">Rack Type Name<span class="text-green-600">*</span></div>
          <input v-model="name" type="text" class="form-control" />
        </div>
      </div>
      <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
        <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
          Cancel
        </button>
        <button class="btn-action btn-lg" @click.prevent.stop="validateAndSave">Save</button>
      </div>
    </modal-window>
  </Teleport>
</template>
<script>
import validate from 'validate.js';

export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
    initialName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      name: this.initialName,
    };
  },
  computed: {
    isCreatingNewRack() {
      return !this.id;
    },
  },
  methods: {
    validateAndSave() {
      const validationErrors = validate(
        {
          name: this.name,
        },
        {
          name: {
            presence: { allowEmpty: false },
          },
        },
      );
      if (validationErrors) {
        this.alertBox().fire({
          title: 'Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        return;
      }
      this.$emit('save', {
        id: this.id,
        name: this.name,
      });
    },
  },
};
</script>
