<template>
  <div class="visualiser-uploaded-image-tile" :class="{ 'pb-20 mb-10': last_image }">
    <div class="visualiser-uploaded-image-tile-img">
      <api-img :src="image.url" class="mx-auto mb-3 md:mb-0" />
    </div>
    <div class="flex flex-col justify-end flex-grow w-full xs:w-auto sm:w-full md:w-auto">
      <div class="flex justify-between">
        <div class="mt-auto flex-grow">
          <div class="flex flex-col justify-around pb-2 text-xs pt-2 md:text-sm">
            <template v-if="first_image">
              Enter a reference for this image (e.g. Kitchen, Patio)
            </template>
            <template v-else> Reference </template>
          </div>
          <div class="flex-grow">
            <input
              v-model="newReference"
              type="text"
              class="bg-gray-200 rounded p-2 text-xs w-full"
              :class="{ 'bg-red-100': modified }"
            />
          </div>
        </div>
        <div class="absolute top-0 right-0">
          <button class="cursor-pointer hover:text-gray-600" @click="deleteImage()">
            <i class="fal fa-trash"></i>
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="last_image"
      class="fixed right-0 bottom-0 p-3 sm:p-6 bg-white text-center border-t w-full sm:w-1/3 md:w-2/5 lg:w-1/3"
    >
      <button class="btn-action btn-lg" @click="goToPlace()">Start Visualising</button>
    </div>
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useVisualiserStore } from '@/pinia/visualiser';
import _ from 'lodash';

export default {
  props: ['image', 'last_image', 'first_image'],
  data() {
    return {
      newReference: this.image.reference,
    };
  },
  computed: {
    ...mapStores(useVisualiserStore),
    modified() {
      return this.image.reference !== this.newReference;
    },
  },
  watch: {
    newReference() {
      this.debouncedUpdateReference();
    },
  },
  created() {
    this.debouncedUpdateReference = _.debounce(this.updateReference, 500);
  },
  methods: {
    goToPlace() {
      this.visualiserStore.selectImage(this.image.id);
      this.$router.push({
        name: this.branded_mode_user ? 'Design Online - Visualiser' : 'Visualiser',
        params: { id: this.$route.params.id }
      });
    },
    async updateReference() {
      if (this.modified) {
        await this.visualiserStore.updateReference({
          id: this.image.id,
          reference: this.newReference,
        });

        this.visualiserStore.loadImages();
      }
    },
    async deleteImage() {
      await this.visualiserStore.deleteImage({
        id: this.image.id,
      });

      this.visualiserStore.loadImages();
    },
  },
};
</script>
