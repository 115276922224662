<template>
  <Teleport defer to="#portal_popup">
    <modal-window
      v-if="!loading"
      :modal_open="true"
      title="Edit Delivery Days"
      @close="$emit('close')"
    >
      <loading :loading="loading">
        <table class="w-full">
          <thead>
            <tr class="">
              <th class="text-left p-3">Mon</th>
              <th class="text-left p-3">Tues</th>
              <th class="text-left p-3">Wed</th>
              <th class="text-left p-3">Thurs</th>
              <th class="text-left p-3">Fri</th>
            </tr>
          </thead>
          <tbody>
            <tr class="cursor-pointer border-t">
              <td class="px-3 py-1 md:p-3">
                <input v-model="customer.delivery.onMonday" type="checkbox" />
              </td>
              <td class="px-3 py-1 md:p-3">
                <input v-model="customer.delivery.onTuesday" type="checkbox" />
              </td>
              <td class="px-3 py-1 md:p-3">
                <input v-model="customer.delivery.onWednesday" type="checkbox" />
              </td>
              <td class="px-3 py-1 md:p-3">
                <input v-model="customer.delivery.onThursday" type="checkbox" />
              </td>
              <td class="px-3 py-1 md:p-3">
                <input v-model="customer.delivery.onFriday" type="checkbox" />
              </td>
            </tr>
          </tbody>
        </table>
        <div class="text-right">
          <button class="btn-action btn-lg" @click.prevent="save()">Save</button>
        </div>
      </loading>
    </modal-window>
  </Teleport>
</template>

<script>
import { mapStores } from 'pinia'
import { useCustomerStore } from '@/pinia/customer';

export default {
  props: ['prop_customer', 'loggedInUser'],
  data() {
    return {
      customer: {
        delivery: {},
      },
      loading: true,
    };
  },
  computed: {
    ...mapStores(useCustomerStore),
  },
  mounted() {
    this.customer = this.prop_customer;
    this.loading = false;
  },
  methods: {
    async save() {
      if (this.touch_business_user) {
        await this.customerStore.customerUpdateDeliveryDays(this.customer);
        this.$emit('close');
      }
    },
  },
};
</script>
