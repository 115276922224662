<template>
  <Teleport defer to="#portal_popup">
    <modal-window :modal_open="true" title="Add New File" @close="$emit('close')">
      <loading :loading="saving">
        <div class="flex flex-wrap -mx-3">
          <div class="p-3 w-full 2xs:w-1/2">
            <div class="font-medium">Name</div>
            <div>
              <input v-model="document.Name" class="form-control" />
            </div>
          </div>
          <div class="p-3 w-full 2xs:w-1/2">
            <div class="font-medium">Category</div>
            <div>
              <select v-model="document.CategoryId" class="form-control">
                <option v-for="category in categories" :key="category.id" :value="category.id">
                  {{ category.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap -mx-3 mb-3">
          <div class="p-3 w-full">
            <div class="font-medium">Visible to?</div>
            <div>
              <select v-model="document.DealerNetworkPartnerOnly" class="form-control">
                <option :value="false">All Customers</option>
                <option :value="true">Dealer Network Partner Only</option>
              </select>
            </div>
          </div>
        </div>
        <Dropzone
          label="file"
          :removable="false"
          :compatible="['.pdf', '.png', '.jpg', '.jpeg']"
          :max-size="100000000"
          @add-file="document.File = $event"
          @remove-file="document.File = null"
        ></Dropzone>
        <div class="flex flex-wrap -mx-3 mb-3">
          <div class="p-3 w-full">
            <div class="font-medium">Or use a file URL</div>
            <div>
              <input v-model="file_url" class="form-control" />
            </div>
          </div>
        </div>
        <div class="text-red-600 flex flex-col justify-around font-medium text-base">
          Max File Size: 100MB
        </div>
        <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
          <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
            Cancel
          </button>
          <button v-if="!saving" class="btn-action btn-lg" @click.prevent.stop="save()">Save</button>
          <button v-else class="btn-action btn-lg btn-disabled">Saving</button>
        </div>
      </loading>
    </modal-window>
  </Teleport>
</template>

<script>
import { mapStores } from 'pinia'
import { useCustomerStore } from '@/pinia/customer';
import Dropzone from '@/components/shared/Dropzone.vue';

export default {
  components: {
    Dropzone
  },
  props: ['categories'],
  data() {
    return {
      document: {
        Name: '',
        CategoryId: null,
        File: null,
        DealerNetworkPartnerOnly: false,
      },
      saving: false,
      file_url: null,
    };
  },
  computed: {
    ...mapStores(useCustomerStore),
  },
  methods: {
    async save() {
      if (!this.saving) {
        this.saving = true;

        if (this.document.CategoryId === null) {
          this.saving = false;
          this.alertBox().fire({
            title: 'No Category Chosen',
            text: 'Please choose a Document Category.',
            icon: 'error',
          });
          return;
        }

        if (this.document.File === null && this.file_url === null) {
          this.alertBox().fire({
            title: 'No File Chosen',
            text: 'Please choose a File or add a file URL.',
            icon: 'error',
          });
          this.saving = false;
          return;
        }

        if (this.file_url === null) {
          const formData = new FormData();
          formData.append('Name', this.document.Name);
          formData.append('CategoryId', this.document.CategoryId);
          formData.append('File', this.document.File);
          formData.append('Type', this.enums.documentType.RESOURCE_FILE.toString());
          formData.append('DealerNetworkPartnerOnly', this.document.DealerNetworkPartnerOnly);
          await window.touch.uploadDocumentForm(formData).then(() => {
            this.saving = false;
            this.$emit('added');
            this.$emit('close');
          });
        } else {
          if (!this.file_url.includes('//')) {
            this.file_url = `http://${this.file_url}`;
          }

          const document = {
            name: this.document.Name,
            type: this.enums.documentType.RESOURCE_FILE.toString(),
            categoryId: this.document.CategoryId,
            url: this.file_url,
            ImageBlob: this.document.File ? this.document.File.dataURL : undefined,
          };
          await this.customerStore.uploadDocumentWithUrl(document).then(() => {
            this.saving = false;
            this.$emit('added');
            this.$emit('close');
          });
        }
      }
    },
  },
};
</script>
