import moment from 'moment';
import { defineStore } from 'pinia';
import { useAuthStore } from '@/pinia/auth';
import { useSandboxStore } from '@/pinia/sandbox';
import { useVisualiserStore } from '@/pinia/visualiser';
import { useTouchStore } from '@/pinia/touch';

export const useBasketStore = defineStore('basket', {
  persist: {
    omit: ['basket']
  },
  state: () => ({
    testModeContract: false,
    basket: [],
    customerId: undefined,
    salesSectorType: undefined,
    buttonName: 'Quote',
    job: null,
    reference: '',
    deliveryNotes: '',
    supplierNotes: '',
    poNumber: '',
    deliveryDate: '',
    contractIdentifier: null,
    use_manual_address: false,
    delivery: {},
    isEnquiry: false,
    isFit: true,
    isOrder: false,
    currentItem: undefined,
    contractData: undefined,
    imageType: null,
    skipSop: false,
    isPaymentGateway: false,
  }),
  actions: {
    SET_TEST_MODE_CONTRACT(setting) {
      this.testModeContract = setting;
    },
    SET_IMAGE_TYPE(imageType) {
      this.imageType = imageType;
    },
    setIsFit(isFit) {
      this.isFit = isFit;
    },
    setButtonName(name) {
      this.buttonName = name;
    },
    setContractData(contractData) {
      this.contractData = contractData;
    },
    SET_CUSTOMER_ID(value) {
      this.customerId = value;
    },
    SET_SALES_SECTOR_TYPE(value) {
      this.salesSectorType = value;
    },
    SET_CURRENT_ITEM(value) {
      this.currentItem = value;
    },
    APPEND_BASKET_ITEM(item) {
      this.basket.push({
        ...item,
        key: item.key ?? item.itemKey,
        _uuid: new Date().getTime(),
      });
    },
    REPLACE_BASKET_ITEM({ itemKey, item }) {
      const itemIndex = this.basket.findIndex(
        (basketItem) => basketItem.itemKey === Number(itemKey),
      );
      this.basket[itemIndex] = {
        ...item,
        key: item.key ?? item.itemKey,
        _uuid: new Date().getTime(),
      }
    },
    DELETE_BASKET_ITEM(itemKey) {
      this.basket = this.basket.filter((basketItem) => basketItem.itemKey !== Number(itemKey));
    },
    setDeliveryDate(value) {
      this.deliveryDate = value;
    },
    setPONumber(value) {
      this.poNumber = value;
    },
    setDeliveryNotes(value) {
      this.deliveryNotes = value;
    },
    setSupplierNotes(value) {
      this.supplierNotes = value;
    },
    saveJobDelivery(value) {
      this.delivery = value;
    },
    setReference(value) {
      this.reference = value;
    },
    clearBasket() {
      this.basket = [];
      this.reference = '';
      this.job = undefined;
      this.contractIdentifier = null;
      this.isEnquiry = false;
      this.skipSop = false;
      this.testModeContract = false;
    },
    setBasket(items) {
      // Sort child items in order
      items.sort((a, b) => {
        return a.parentItemKey - b.parentItemKey;
      });
      // Get all parent items that are not job extras
      const parentItems = items.filter(x => x.parentItemKey === 0 && !x.extraItemId)
      for (let i = 0; i < parentItems.length; i++) {
        // Remove from current location
        const parentIndexOld = items.findIndex(item => item.itemKey === parentItems[i].itemKey);
        items.splice(parentIndexOld, 1);     
        // Add above first child element
        const parentIndexNew = items.findIndex(item => item.parentItemKey === parentItems[i].itemKey);
        items.splice(parentIndexNew, 0, parentItems[i]);
      }
      // Sort all job extras to the bottom
      items.sort((a, b) => {
        const jobExtraA = a.parentItemKey === 0 && a.extraItemId
        const jobExtraB = b.parentItemKey === 0 && b.extraItemId
        // If jobExtraA move down
        if (jobExtraA && !jobExtraB) return 1;
        // If jobExtraB move A up
        if (!jobExtraA && jobExtraB) return -1;
      });
      this.basket = items
    },
    setJob(job) {
      this.job = job;
    },
    setIsEnquiry(value) {
      this.isEnquiry = value;
    },
    setContract(contract) {
      this.contractIdentifier = {
        contractId: parseInt(contract.contractId, 10),
        jobKey: parseInt(contract.jobKey, 10),
        existsInBusinessStore: contract.existsInBusinessStore === true,
      };

      this.isEnquiry = !!contract.isEnquiry;
      this.isOrder = !!contract.isOrder;
    },
    async setIsPaymentGateway(isPaymentGateway) {
      this.isPaymentGateway = isPaymentGateway;
    },
    async getSandboxReportUrlForCurrentItem() {
      const sandboxStore = useSandboxStore();
      const sandboxId = sandboxStore.selectedSandbox?.id || 0;
      const { contractId, jobKey } = this.contractIdentifier;
      const { itemKey } = this.currentItem;

      let url = (sandboxId ? `v1/sandbox/${sandboxId}/`: '') + `processing/GenerateFromAdminProcessing/${this.customerId}/${contractId}/${jobKey}/${itemKey}`

      return window.touch.displayStream(url, { type: 'application/pdf' });
    },
    async updateStockItemQty({ itemId, quantity }) {
      const existingItem = this.basket.find(
        (basketItem) =>
          basketItem.inputType === window.enum.inputType.STOCK_ITEM &&
          basketItem.stockItemId === itemId,
      );
      const contract = await this.getContract();

      if (!existingItem) {
        const addedStockItem = await window.touch.processingAddStockItem(
          contract.contractId,
          contract.jobKey,
          itemId,
          quantity,
          0,
        );
        await this.appendItemByKey(addedStockItem.itemKey)
        return addedStockItem.itemKey;
      }

      const lineItem = await this.updateStockItemQuantity({
        itemKey: existingItem.itemKey,
        qty: quantity,
      });

      this.REPLACE_BASKET_ITEM({
        itemKey: existingItem.itemKey,
        item: lineItem,
      });

      this.refresh()

      return lineItem;
    },
    async setFit(isFit) {
      this.setIsFit(isFit);

      const contract = await this.getContract()

      await window.touch.processingUpdateFittingType(contract.contractId, contract.jobKey, isFit);

      return this.refresh()
    },
    async updateExistingExtraItem({ params, itemId }) {
      const contract = await this.getContract()

      await window.touch.processingUpdateExistingExtraItem(
        contract.contractId,
        contract.jobKey,
        itemId,
        params,
      );

      return this.refreshItemByKey(itemId);
    },
    async updateNewExtraItem({ params, itemId }) {
      const contract = await this.getContract()

      await window.touch.processingUpdateNewExtraItem(
        contract.contractId,
        contract.jobKey,
        itemId,
        params,
      );

      return this.refreshItemByKey(itemId);
    },
    async addExistingExtraItem({ itemId, extraItemId, params }) {
      const contract = await this.getContract()

      const result = await window.touch.processingAddExistingExtraItem(
        contract.contractId,
        contract.jobKey,
        extraItemId,
        itemId,
        params,
      );

      if (result.status === false) {
        return result;
      }

      return this.appendItemByKey(result.itemKey);
    },
    async getBreakdown(refresh) {
      if (refresh) {
        await this.refresh(refresh);
      }
      return this.currentItem?.priceBreakdown?.sort((a, b) => a.listIndex - b.listIndex);
    },
    async markAsMeasured() {
      return window.touch.processingUpdateContract(this.contractIdentifier.contractId, {
        NoSizes: false,
      });
    },
    async setMeasurements(measurementsOn) {
      await window.touch.processingUpdateContract(this.contractIdentifier.contractId, {
        NoSizes: !measurementsOn,
      });

      return this.refresh()
    },
    async setCustomerId(customerId) {
      if (customerId === undefined) {
        return;
      }

      if (Number.isNaN(Number(customerId))) {
        return;
      }
      this.SET_CUSTOMER_ID(customerId)
    },
    async setSalesSectorType(salesSectorType) {
      if (salesSectorType === undefined) {
        return;
      }
      this.SET_SALES_SECTOR_TYPE(salesSectorType)
    },
    async createTest(isEnquiry = false) {
      this.clearBasket()
      this.SET_TEST_MODE_CONTRACT(true)

      const quote = await window.touch.testCreateTestJob(this.customerId);

      if (quote.message) {
        window.alertBox.fire(quote.message);
        return false;
      }

      this.setContract({
        contractId: quote.contractId,
        jobKey: quote.jobKey,
        isEnquiry,
      });

      this.setButtonName('Test Job');

      return true;
    },
    async createQuote(isEnquiry = false) {
      const authStore = useAuthStore();
      const sandboxStore = useSandboxStore();
      sandboxStore.SET_SANDBOX(null);
      this.clearBasket()
      if (authStore.demoMode) {
        this.setReference(window.helpers.fake_reference())
      }

      const quote = await window.touch.createTradeQuote(this.customerId);

      if (quote.message) {
        window.alertBox.fire(quote.message);
        return false;
      }

      this.setContract({
        contractId: quote.contractId,
        jobKey: quote.jobKey,
        isEnquiry,
      });

      this.setButtonName('Quote');

      this.refreshDelivery();

      return true;
    },
    async createOrder() {
      const authStore = useAuthStore();
      const sandboxStore = useSandboxStore();
      sandboxStore.SET_SANDBOX(null);
      this.clearBasket()
      if (authStore.demoMode) {
        this.setReference(window.helpers.fake_reference())
      }

      const quote = await window.touch.createTradeOrder(this.customerId);

      if (quote.message) {
        window.alertBox.fire(quote.message);
        return false;
      }

      this.setContract({
        contractId: quote.contractId,
        jobKey: quote.jobKey,
        isOrder: true,
      });

      this.setButtonName('Order');

      this.refreshDelivery();

      return true;
    },
    async fromQuote(contract) {
      return new Promise((resolve, reject) => {
        this.setContract({
          contractId: contract.contractId,
          jobKey: contract.jobKey,
          isEnquiry: contract.isEnquiry,
        });

        window.touch
          .processingReloadContract(this.customerId, contract.contractId)
          .then(() => {
            this.refreshDelivery();
            this.setButtonName('Quote');
            resolve();
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },
    async fromOrder(contract) {
      return new Promise((resolve, reject) => {
        this.setContract({
          contractId: contract.contractId,
          jobKey: contract.jobKey,
          isOrder: true,
          existsInBusinessStore: true,
        });

        window.touch
          .processingReloadContract(this.customerId, contract.contractId)
          .then(() => {
            this.refreshDelivery();
            this.setButtonName('Order');
            resolve();
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },
    async fromEnquiry(contract) {
      const authStore = useAuthStore();
      let contractId;

      if (contract.fromDealer) {
        contractId = await window.touch.processingConvertDealerEnquiry(
          contract.contractId,
          [contract.jobKey],
          authStore.processingLevel,
        );
      } else {
        contractId = await window.touch.contractConvertToQuote(contract.contractId, [
          contract.jobKey,
        ]);
      }

      this.setContract({
        contractId,
        jobKey: 1,
        isEnquiry: false,
      });

      await window.touch.processingReloadContract(this.customerId, contractId);

      this.refreshDelivery();
    },
    async addDesign({ designId, productId }) {
      const contract = await this.getContract()
      const authStore = useAuthStore();

      if (this.contractData === undefined) {
        const contractData = await window.touch.processingContract(contract.contractId);
        this.setContractData(contractData);
      }

      const addedDesign = await window.touch.createJobLineItem(
        this.customerId,
        contract.contractId,
        contract.jobKey,
        designId,
        productId,
        authStore.processingLevel,
      );

      this.APPEND_BASKET_ITEM(addedDesign)

      return addedDesign.itemKey;
    },
    async deleteItemByKey(itemKey) {
      this.DELETE_BASKET_ITEM(itemKey)
    },
    async refreshItemByKey(itemKey) {
      const contract = await this.getContract()
      const authStore = useAuthStore();

      const basketItems = await window.touch.processingJob(contract.contractId, contract.jobKey);

      if (basketItems === null || basketItems.items === undefined) {
        throw new Error('Unexpected Empty Contract');
      }

      const newItem = basketItems.items.find((item) => item.key === itemKey);

      const fullItem = await window.touch.jobLineItem(
        this.customerId,
        contract.contractId,
        contract.jobKey,
        itemKey,
        authStore.processingLevel,
        false,
        false,
        basketItems.exchangeRate,
      );

      this.REPLACE_BASKET_ITEM({
        itemKey,
        item: {
          ...newItem,
          ...fullItem,
        },
      });
    },
    async appendItemByKey(itemKey) {
      const contract = await this.getContract()
      const authStore = useAuthStore();

      if (this.contractData === undefined) {
        const contractData = await window.touch.processingContract(contract.contractId);
        this.setContractData(contractData)
      }

      const basketItems = await window.touch.processingJob(contract.contractId, contract.jobKey);

      if (basketItems === null || basketItems.items === undefined) {
        throw new Error('Unexpected Empty Contract');
      }

      const newItem = basketItems.items.find((item) => item.key === itemKey);

      const fullItem = await window.touch.jobLineItem(
        this.customerId,
        contract.contractId,
        contract.jobKey,
        itemKey,
        authStore.processingLevel,
        false,
        false,
        basketItems.exchangeRate,
      );

      this.APPEND_BASKET_ITEM({
        ...newItem,
        ...fullItem,
      });
    },
    async getChildProducts({ componentId, width, height, customerId }) {
      const contract = await this.getContract()

      const products = await window.touch.processingGetChildProducts(
        contract.contractId,
        contract.jobKey,
        componentId,
        width,
        height,
        customerId,
      );

      return products;
    },
    async insertDesignIntoBay({ product, designId, itemId, componentId }) {
      const contract = await this.getContract()
      const authStore = useAuthStore();

      const response = await window.touch.processingUpdateJobLineItemNestedDesign(
        this.customerId,
        contract.contractId,
        contract.jobKey,
        itemId,
        authStore.processingLevel,
        product,
        designId,
        componentId,
      );

      await this.refreshItemByKey(itemId);

      return response;
    },
    getComponentDimensions(componentId) {
      const container = this.currentItem.frameContainers.filter(
        (frameContainer) => frameContainer.id === componentId,
      );

      if (container.length !== 1) {
        throw new Error('Unable to find component');
      }

      return container[0];
    },
    async updateItemDimension({ itemKey, dimensionId, value }) {
      const contract = await this.getContract()
      const authStore = useAuthStore();

      const lineItem = await window.touch.updateJobLineItemDimension(
        this.customerId,
        contract.contractId,
        contract.jobKey,
        itemKey,
        authStore.processingLevel,
        dimensionId,
        value,
      );

      this.REPLACE_BASKET_ITEM({
        itemKey: this.currentItem.itemKey,
        item: lineItem,
      });

      await this.refresh()
      return lineItem;
    },
    async updateItemQty({ itemKey, qty }) {
      const contract = await this.getContract()
      const authStore = useAuthStore();

      const lineItem = await window.touch.updateJobLineItemQuantity(
        this.customerId,
        contract.contractId,
        contract.jobKey,
        itemKey,
        authStore.processingLevel,
        qty,
      );

      this.REPLACE_BASKET_ITEM({
        itemKey,
        item: lineItem,
      });
      await this.refresh()
      return lineItem;
    },
    async updateStockItemQuantity({ itemKey, qty }) {
      const contract = await this.getContract()
      const authStore = useAuthStore();

      return window.touch.UpdateJobLineStockItemQuantity(
        contract.contractId,
        contract.jobKey,
        itemKey,
        authStore.processingLevel,
        qty,
      );
    },
    async updateImageTypeForHeading(headingId) {
      const imageType = await this.imageTypeForHeading({ heading: headingId });

      if (imageType) {
        this.SET_IMAGE_TYPE(imageType);
      }
    },
    async imageTypeForHeading({ heading }) {
      try {
        return this.currentItem.specification.filter(
          (spec) => spec.optionHeadingId === heading,
        )[0].drawingType;
      } catch (error) {
        return false;
      }
    },
    async updateItemOption({ itemKey, heading, value, components, members, text, rule, parameters }) {
      const contract = await this.getContract()
      const authStore = useAuthStore();

      const lineItem = await window.touch.UpdateJobLineItemOption(
        this.customerId,
        contract.contractId,
        contract.jobKey,
        itemKey,
        authStore.processingLevel,
        heading,
        value,
        components,
        members,
        parameters,
        text,
        rule,
      );

      this.REPLACE_BASKET_ITEM({
        itemKey: this.currentItem.itemKey,
        item: lineItem,
      });

      return lineItem;
    },
    async loadItem({ itemKey, customerID }) {
      if (this.basket.length === 0) {
        await this.refresh()
      }
      if (this.basket.length > 0) {
        const item = this.basket.filter((x) => Number(x.key) === Number(itemKey))[0];
        const authStore = useAuthStore();

        if (!item) {
          return undefined
        }
        const fullItem = await window.touch.jobLineItem(
          customerID,
          item.contractId,
          item.jobKey,
          item.itemKey,
          authStore.processingLevel,
          false,
          false,
          this.job.exchangeRate,
        );
        return fullItem;
      }
      return undefined;
    },
    async getEarliestDeliveryDate() {
      const contract = await this.getContract()
      return window.touch.processingGetEarliestDeliveryDate(contract.contractId, contract.jobKey);
    },
    async processingForceDefaults() {
      const contract = await this.getContract()
      const authStore = useAuthStore();

      await window.touch.processingForceDefaults(
        contract.contractId,
        contract.jobKey,
        this.currentItem,
        authStore.processingLevel,
      );
      return this.refreshItemByKey(this.currentItem);
    },
    async refresh() {
      const contract = await this.getContract()
      const authStore = useAuthStore();

      window.touch.processingContract(contract.contractId).then((contractData) => {
        this.setContractData(contractData);
      });

      const uuid = new Date().getTime();
      const basketSummary = await window.touch.processingBasketSummary(
        this.customerId,
        contract.contractId,
        contract.jobKey,
        authStore.processingLevel,
      );
      if (basketSummary) {
        for (let i = 0; i < basketSummary.items.length; i++) {
          // eslint-disable-line
          basketSummary.items[i].key = basketSummary.items[i].itemKey;
          basketSummary.items[i]._uuid = uuid + 1; // eslint-disable-line
        }
        this.setJob({
          exchangeRate: basketSummary.exchangeRate,
          fittingType: basketSummary.fittingType,
          tax: basketSummary.tax,
          taxRate: basketSummary.taxRate,
          calculatedPriceTaxable: basketSummary.calculatedPriceTaxable,
          calculatedPriceTaxExempt: basketSummary.calculatedPriceTaxExempt,
          calculatedFittingPriceTaxable: basketSummary.calculatedFittingPriceTaxable,
          calculatedFittingPriceTaxExempt: basketSummary.calculatedFittingPriceTaxExempt,
          priceDeliveryCharge: basketSummary.priceDeliveryCharge,
          priceDeliveryChargeIncTax: basketSummary.priceDeliveryChargeIncTax,
          priceIncludingTax: basketSummary.priceIncludingTax,
          fittingPrice: basketSummary.fittingPrice,
          fittingPriceIncTax: basketSummary.fittingPriceIncTax,
          surveyPrice: basketSummary.surveyPrice,
          surveyPriceIncTax: basketSummary.surveyPriceIncTax,
          subtotal: basketSummary.priceExcludingTax,
          total: basketSummary.priceIncludingTax,
          overrideCalculatedPrice: basketSummary.overrideCalculatedPrice,
          overridePriceTaxExempt: basketSummary.overridePriceTaxExempt,
          overridePriceTaxable: basketSummary.overridePriceTaxable,
          discountApplied: basketSummary.discountApplied,
          discountCode: basketSummary.discountCode,
        });
        this.setIsFit(basketSummary.fittingType === window.enum.fittingType.SUPPLY_AND_FIT);
        this.setBasket(basketSummary.items)
      }
    },
    async saveBrandedEnquiry(
      {
        salutation,
        firstName,
        lastName,
        email,
        phone,
        addressLine1,
        addressLine2,
        addressLine3,
        addressTown,
        addressCode,
        addressIso,
        notes,
        latitude,
        longitude,
      },
    ) {
      const contract = await this.getContract()
      // set quote reference
      const quoteId = contract.contractId;

      this.clearBasket()

      await window.touch.processingUpdateContract(contract.contractId, {
        Reference: this.reference,
        Consumer: {
          Salutation: salutation,
          FirstName: firstName,
          LastName: lastName,
          Email: email,
          Mobile: phone,
          AddressLine1: addressLine1,
          AddressLine2: addressLine2,
          AddressLine3: addressLine3,
          AddressTown: addressTown,
          AddressCode: addressCode,
          AddressIso: addressIso,
          AddressLatitude: latitude,
          AddressLongitude: longitude,
        },
        OverwriteConsumer: true,
      });

      await window.touch.processingUpdateNote({
        ContractId: contract.contractId,
        JobKey: 1,
        NoteType: window.enum.noteType.CUSTOMER,
        Text: notes,
      });

      await window.touch.processingSaveContract(this.customerId, contract.contractId);
      const visualiserStore = useVisualiserStore();
      visualiserStore.clear({});

      return quoteId;
    },
    async updateOrder() {
      const contract = await this.getContract()
      // set quote reference

      await window.touch.processingUpdateContract(contract.contractId, {
        Reference: this.reference,
      });

      if (contract.jobKey === 1) {
        await window.touch.processingUpdateJob(contract.contractId, contract.jobKey, {
          Reference: this.reference,
        });
      }

      if (!this.skipSop) {
        await window.touch.processingSaveContract(
          this.customerId,
          contract.contractId,
          this.skipSop,
          false,
        );
      }

      const orderId = contract.contractId;

      if (!this.skipSop) {
        this.clearBasket()
      }

      return {
        orderId,
      };
    },
    async saveQuote(isLazySaving = true) {
      const contract = await this.getContract()
      // set quote reference
      let quoteId;

      if (this.isEnquiry) {
        quoteId = await window.touch.contractConvertToQuote(contract.contractId, [contract.jobKey]);
        // quoteId = await window.touch.processingConvertDealerEnquiry(contract.contractId, [contract.jobKey]);
      } else {
        quoteId = contract.contractId;
      }

      if (this.isEnquiry) {
        // await window.touch.processingSaveContract(this.customerId, contract.contractId);
      }

      const jobId = this.isEnquiry ? 1 : contract.jobKey;

      await window.touch.processingUpdateContract(quoteId, {
        Reference: this.reference,
      });

      if (jobId === 1) {
        await window.touch.processingUpdateJob(quoteId, jobId, {
          Reference: this.reference,
        });
      }

      await window.touch.processingSaveContract(
        this.customerId,
        quoteId,
        false,
        false,
        isLazySaving,
      );
      this.clearBasket()

      return {
        jobId,
        quoteId,
      };
    },
    async saveOrder({ isLazySaving = true, placeTradeOrder = false }) {
      const contract = await this.getContract()
      // set quote reference
      // const quoteId = contract.contractId;
      const orderId = contract.contractId;
      if (!this.isOrder) {
        // await window.touch.processingSaveContract(this.customerId, quoteId);
        // orderId = await window.touch.contractConvertToOrder(quoteId, [contract.jobKey]);
      }

      await window.touch.processingUpdateContract(orderId, {
        Reference: this.reference,
      });

      await window.touch.processingUpdateJobDelivery(this.customerId, orderId, 1, {
        RequestedDate: moment(this.deliveryDate).add(12, 'hours').toISOString(),
        Notes: this.deliveryNotes,
      });

      let existingNotes;
      if (contract.existsInBusinessStore) {
        const touchStore = useTouchStore();
        existingNotes = touchStore.loadNote(
          {
            contractId: orderId,
            jobId: 1,
            noteType: window.enum.noteType.SUPPLIER,
          }
        );
      }
      const existingSupplierNoteId = existingNotes?.id;

      await window.touch.processingUpdateNote({
        NoteId: existingSupplierNoteId,
        ContractId: orderId,
        JobKey: 1,
        NoteType: window.enum.noteType.SUPPLIER,
        Text: this.supplierNotes,
      });

      await window.touch.processingUpdateCustomerPONumber(orderId, this.poNumber);

      // caching this here as we clear it now as well;
      const skippingSop = this.skipSop;

      this.clearBasket()
      // await window.touch.processingReloadContract(this.customerId, quoteId);

      if (!this.isOrder) {
        await window.touch.processingSaveContract(
          this.customerId,
          orderId,
          false,
          false,
          isLazySaving,
        );
        return orderId;
      }

      if (this.salesSectorType === window.enum.salesSectorType.RETAIL) {
        await window.touch.processingSaveContract(
          this.customerId,
          orderId,
          false,
          placeTradeOrder,
          isLazySaving,
        );
        return orderId;
      }

      return window.touch.processingSaveContract(
        this.customerId,
        orderId,
        skippingSop,
        !skippingSop,
        isLazySaving,
      );
    },
    async addUserEnteredAddress({ town, address1, address2, address3, iso, postcode, latitude, longitude }) {
      const contract = await this.getContract()

      await window.touch.processingUpdateJobDelivery(
        this.customerId,
        contract.contractId,
        contract.jobKey,
        {
          NewAddress: {
            Line1: address1,
            Line2: address2,
            Line3: address3,
            Town: town,
            Iso: iso,
            Code: postcode,
            Latitude: latitude,
            Longitude: longitude,
          },
        },
      );

      this.refreshDelivery();
    },
    async getContract() {
      if (!this.contractIdentifier) {
        throw new Error('No Contract Setup');
      }

      if (!this.job) {
        this.setJob(
          await window.touch.processingJob(
            this.contractIdentifier.contractId,
            this.contractIdentifier.jobKey,
            false,
          )
        )
      }

      return {
        contractId: this.contractIdentifier.contractId,
        jobKey: this.contractIdentifier.jobKey,
        existsInBusinessStore: this.contractIdentifier.existsInBusinessStore,
      };
    },
    async refreshDelivery(existingContract) {
      if (this.isEnquiry) {
        return; // We don't need delivery for enquiries
      }
      const contract = existingContract ?? (await this.getContract());
      const jobDelivery = await window.touch.processingJobDelivery(
        this.customerId,
        contract.contractId,
        contract.jobKey,
      )
      this.saveJobDelivery(jobDelivery)
      this.pickDefaultIfNoDelivery()
    },
    async pickDefaultIfNoDelivery() {
      if (this.delivery.addressId === 0) {
        const primaryAddress = this.delivery.addresses.find((address) => address.isPrimary);
        if (primaryAddress) {
          this.setAddress(primaryAddress.id)
        }
      }
    },
    async setAddress(addressId) {
      const contract = await this.getContract()

      await window.touch.processingUpdateJobDelivery(
        this.customerId,
        contract.contractId,
        contract.jobKey,
        {
          AddressId: parseInt(addressId, 10),
        },
      );
      this.setDeliveryDate('')
    },
    async deleteLineItem({ lineItemId }) {
      const contract = await this.getContract()
      const visualiserStore = useVisualiserStore();
      await window.touch.processingDeleteLineItem(
        contract.contractId,
        contract.jobKey,
        lineItemId,
        10,
      );
      await visualiserStore.deleteProduct({ id: lineItemId });
      this.deleteItemByKey(lineItemId)
      await this.refresh()
    },
    async duplicateLineItem({ lineItemId }) {
      const contract = await this.getContract()
      const newItem = await window.touch.processingDuplicateItem(
        this.customerId,
        contract.contractId,
        contract.jobKey,
        lineItemId,
        10,
      );
      await this.refresh()

      return newItem;
    },
    async resetJobLineItem({ itemKey }) {
      const contract = await this.getContract()
      const authStore = useAuthStore();

      return window.touch.resetJobLineItem(
        contract.contractId,
        contract.jobKey,
        itemKey,
        authStore.processingLevel,
      );
    },
    async getProcessingImage({ itemKey, imageType }) {
      const contract = await this.getContract()
      const image = await window.touch.processingImage(
        contract.contractId,
        contract.jobKey,
        itemKey,
        imageType,
      );
      return image;
    },
  },
  getters: {
    basketQty() {
      return (
        this.stockItems.length + this.jobLevelExtras.length + this.fenestrationItems.length
      );
    },
    stockItems(state) {
      return state.basket
        .filter((item) => item.inputType === window.enum.inputType.STOCK_ITEM)
        .filter((item) => item);
    },
    jobLevelExtras(state) {
      return state.basket
        .filter((item) => item.inputType === window.enum.inputType.CUSTOMER)
        .filter((item) => item)
        .filter((item) => item.parentItemKey === 0);
    },
    fenestrationItems(state) {
      return state.basket
        .filter((item) => item.inputType === window.enum.inputType.FENESTRATION)
        .filter((item) => item);
    },
    contractHasSizing(state) {
      return state.contractData && !state.contractData.noSizes;
    },
    hasContract(state) {
      return state.contractIdentifier !== null;
    },
    getCurrency(state) {
      if (state.contractData === undefined) {
        return '';
      }

      return state.contractData.customerCurrencyCode;
    },
    availableDeliveryDates(state) {
      try {
        return state.delivery.deliveryDates.map((date) => moment(date).format('DD/MM/YYYY'));
      } catch (error) {
        return [error];
      }
    },
    selected_address_id(state) {
      try {
        return state.delivery.addressId;
      } catch (error) {
        return -1;
      }
    },
    available_addresses(state) {
      try {
        return state.delivery.addresses; // .filter(address => address.isSelectable);
      } catch (error) {
        return [];
      }
    },
    total(state) {
      try {
        return state.job.priceIncludingTax;
      } catch (error) {
        return 0;
      }
    },
    fittingPrice(state) {
      try {
        if (state.salesSectorType === window.enum.salesSectorType.RETAIL) {
          return state.job.fittingPriceIncTax;
        }
        return state.job.fittingPrice;
      } catch (error) {
        return 0;
      }
    },
    surveyPrice(state) {
      try {
        if (state.salesSectorType === window.enum.salesSectorType.RETAIL) {
          return state.job.surveyPriceIncTax;
        }
        return state.job.surveyPrice;
      } catch (error) {
        return 0;
      }
    },
    vat(state) {
      try {
        return state.job.tax;
      } catch (error) {
        return 0;
      }
    },
    deliveryCharge(state) {
      try {
        if (state.salesSectorType === window.enum.salesSectorType.RETAIL) {
          return state.job.priceDeliveryChargeIncTax;
        }
        return state.job.priceDeliveryCharge;
      } catch (error) {
        return 0;
      }
    },
    subtotal(state) {
      try {
        return state.job.subtotal;
      } catch (error) {
        return 0;
      }
    },
  },
});
