<template>
  <aside
    class="hidden sm:flex border-r relative flex-col justify-around print:hidden bg-white"
    style="width: 250px"
  >
    <router-link
      to="/"
      class="bg-white flex flex-col justify-around border"
      style="height: 122px; width: 250px"
    >
      <img id="fabricator_logo" :src="style.fabricator_logo" class="m-auto max-h-full" />
    </router-link>

    <div class="overflow-y-auto overflow-x-hidden flex-shrink-0 flex-grow h-px scrolling-touch">
      <BusinessMenuItems></BusinessMenuItems>
    </div>

    <div class="mb-4">
      <div class="text-center">
        <a
          :href="$t(store_url)"
          target="_blank"
          class="inline-flex bg-white border border-solid border-gray-400 rounded p-1"
        >
          <div class="p-1">
            <img src="/images/touch-logo.svg" width="50px" />
          </div>
          <div class="p-1 text-2xs">By BM Group</div>
        </a>
      </div>
    </div>
  </aside>
</template>

<script>

import BusinessMenuItems from '@/components/shared/nav/BusinessMenuItems.vue';

export default {
  name: 'BusinessSidebarMenu',
  components: {
    BusinessMenuItems,
  },
};
</script>
