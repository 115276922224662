<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Dealer Network Enquiries', '/dealer-network'],
          ['Assigned', '/dealer-network-assigned'],
          ['Status', getStatusUrl()],
        ]"
      />
    </template>
    <template #header_page_title>
      Assigned <span class="hidden xs:inline">Dealer Network</span> Enquiry Status
    </template>
    <template #header_page_actions>
      <div class="px-3 md:px-10 flex items-center">
        <router-link to="/dealer-network-assigned" class="btn-action mr-1">
          View Assigned
        </router-link>
        <router-link to="/dealer-network" class="hidden xs:inline btn-action whitespace-nowrap">
          View All
        </router-link>
      </div>
    </template>
    <loading
      :loading="loading"
      class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch p-3 xs:p-10"
    >
      <div
        v-if="enquiry && enquiry.dealerNetworkAssignment.length > 0"
        class="bg-white p-3 xs:p-10 pb-6 rounded-lg"
      >
        <table class="table-lined xs:table-unstacked _with-hover w-full">
          <thead>
            <tr>
              <th>Assigned to</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="assignee in enquiry.dealerNetworkAssignment"
              :key="assignee.dealerNetworkAssignmentId"
            >
              <td>
                <div class="td-title w-24">Assigned to</div>
                <div class="td-content">{{ assignee.customerName }}</div>
              </td>
              <td>
                <div class="td-title w-24">Status</div>
                <div class="td-content">{{ getDealerStatusName(assignee.dealerStatus) }}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else key="none-found" class="bg-white p-3 md:p-10 border">No Enquiries to show</div>
    </loading>
  </touch-layout>
</template>

<script>

import { mapStores } from 'pinia'
import { useTouchStore } from '@/pinia/touch';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';

export default {
  components: {
    Breadcrumbs,
  },
  data() {
    return {
      enquiry: {
        dealerNetworkAssignment: [],
      },
      enquiryId: parseInt(this.$route.params.id, 10),
      loading: true,
      customerId: 0,
    };
  },
  computed: {
    ...mapStores(useTouchStore),
  },
  async mounted() {
    this.customerId = await window.touch.staffGetOrganisationCustomerId();
    await this.getContracts();
    this.loading = false;
  },
  methods: {
    getStatusUrl() {
      const url = `/dealer-network-assigned-statuses/${this.enquiryId}`;
      return url;
    },
    getDealerStatusName(enquiryStatus) {
      let status = Object.keys(this.enums.DealerStatus).find(
        (key) => this.enums.DealerStatus[key] === enquiryStatus,
      );
      status = status.toUpperCase() === 'AWAITING ACTION' ? 'AWAITING ACTION' : status;
      return status.toLowerCase();
    },
    async getContracts() {
      this.enquiry = await this.touchStore.loadQuote({
        customerId: this.customerId,
        quoteId: this.enquiryId,
      });
    },
  },
};
</script>
