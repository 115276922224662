<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        v-if="order"
        :routes="[['Dashboard', '/'], ['Orders', '/orders'], ['#' + order.customerCounter]]"
      />
    </template>
    <template #header_page_title>
      <div v-if="order">
        {{ order.customerCounter }}
      </div>
    </template>
    <template #header_page_actions>
      <div v-if="order" class="flex gap-5 md:px-10 items-center pr-3">
        <div
          v-if="$refs.orderComponent.orderPaymentStatus === enums.PaymentStatus.AWAITING && order.finalised"
          class="flex item-center gap-3"
        >
          <strong class="p-2 bg-gray-400 rounded-md text-white">Payment Pending</strong>
          <div class="hidden xs:inline btn-action" @click="redirectToPayment()">Pay Now</div>
        </div>
        <div
          v-else-if="$refs.orderComponent.orderPaymentStatus === enums.PaymentStatus.FAILED && order.finalised"
          class="flex items-center gap-3"
        >
          <strong class="p-2 bg-red-400 rounded-md text-white">Payment Failed</strong>
          <div class="hidden xs:inline btn-action" @click="redirectToPayment()">Pay Now</div>
        </div>
        <div v-else-if="$refs.orderComponent.orderPaymentStatus === enums.PaymentStatus.SUCCESS">
          <strong class="p-2 bg-green-400 rounded-md text-white">Payment Successful</strong>
        </div>
        <div class="hidden xs:block">
          <span class="font-medium mr-2">Reference</span>
          <span class="text-brand-primary whitespace-nowrap"> {{ order.reference }} </span>
        </div>
        <div>
          <span class="font-medium mr-2">Status</span>
          <span class="text-brand-primary" v-text="$refs.orderComponent.statusText"></span>
        </div>
        <div v-if="order.parentContractId">
          <router-link
            :to="`/quote/${order.parentContractId}`"
            class="hidden xs:inline btn-action xs:whitespace-nowrap"
          >
            View Quote
          </router-link>
        </div>
      </div>
    </template>
    <OrderComponent
      ref="orderComponent"
      :show-release-to-portal-or-admin-button="showReleaseToPortalOrAdminButton"
      breakdown="Cost"
      :cancellation-available="cancellationAvailable"
      :customer="customerStore.customer"
      @set-visibility="setVisibility"
      @redirect-to-checkout="redirectToCheckout"
      @redirect-to-basket="redirectToBasket"
      @redirect-cancel="redirectCancel"
      @set-order="setOrder"
      @is-edit-job="isEditJob"
    >
      <template #locked_by_user> This order is locked by you </template>
      <template #download_survey>
        <div v-if="!order.finalised" class="flex-1">
          <a
            class="btn-action whitespace-nowrap font-medium flex justify-between w-full p-3 px-6"
            @click="$refs.orderComponent.showSurveyPdf()"
          >
            Download Survey Sheet
            <i class="fa fa-file-download text-sm"></i>
          </a>
        </div>
      </template>
      <template #download_confirmation>
        <div v-if="order.finalised" class="flex-1">
          <a
            class="btn-action whitespace-nowrap font-medium flex justify-between w-full p-3 px-6"
            @click="$refs.orderComponent.showPdf()"
          >
            Download Confirmation
            <i class="fa fa-file-download text-sm"></i>
          </a>
        </div>
      </template>
      <template #download_glass_order>
        <div v-if="customerStore.customer.customer.useCustomerOnlyGlass" class="flex-1">
          <a
            class="btn-action whitespace-nowrap font-medium flex justify-between w-full p-3 px-6"
            @click="$refs.orderComponent.showGlassOrder()"
          >
            Download Glass Order
            <i class="fa fa-file-download text-sm"></i>
          </a>
        </div>
      </template>
      <template #release_to_admin>
        <a
          v-if="ORDER && !order.visibleToAdmin && order.visibleToAdmin !== undefined"
          class="btn-action whitespace-nowrap font-medium flex justify-between w-full p-3 px-6"
          @click.prevent="$refs.orderComponent.toggleVisibility()"
        >
          <span>Show To Admin</span>
          <i class="fa fa-eye text-sm"></i>
        </a>
      </template>
      <template #requote>
        <div v-if="order && order.jobs && order.jobs[0]" class="flex-1 flex flex-col">
          <div class="bg-white border p-3 h-full">
            <div class="border-b font-bold mb-3 pb-3">Price</div>
            <table>
              <tbody>
                <tr>
                  <td class="pb-1 w-32">Subtotal:</td>
                  <td
                    class="pb-1 text-brand-primary"
                    v-html="formatCurrency(
                      order.jobs[0].customerCurrency,
                      order.jobs[0].requotePriceBeforeTax,
                    )"
                  ></td>
                </tr>
                <tr>
                  <td class="pb-1 w-32">VAT:</td>
                  <td
                    class="pb-1 text-brand-primary"
                    v-html="formatCurrency(order.jobs[0].customerCurrency, order.jobs[0].requoteTax)"
                  ></td>
                </tr>
                <tr>
                  <td class="w-32">Total:</td>
                  <td
                    class="text-brand-primary"
                    v-html="formatCurrency(
                      order.jobs[0].customerCurrency,
                      order.jobs[0].requotePriceIncludingTax,
                    )"
                  ></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
      <template #customer_notes="slotProps">
        <div class="flex flex-col">
          <div class="bg-white border p-3 h-full">
            <show-note
              :read-only="slotProps.isReadOnlyNote"
              :contract-id="slotProps.contractId"
              :job-id="slotProps.jobId"
              :note-type="slotProps.noteType"
              :note="slotProps.note"
              @load-notes="$refs.orderComponent.loadNotes"
            >
              Customer Notes
            </show-note>
          </div>
        </div>
      </template>
      <template #internal_notes="slotProps">
        <div class=" flex flex-col">
          <div class="bg-white border p-3 h-full">
            <show-note
              :read-only="slotProps.isReadOnlyNote"
              :contract-id="slotProps.contractId"
              :job-id="slotProps.jobId"
              :note-type="slotProps.noteType"
              :note="slotProps.note"
              @load-notes="$refs.orderComponent.loadNotes"
            >
              Internal Notes
            </show-note>
          </div>
        </div>
      </template>
    </OrderComponent>
    <form ref="theForm" method="post" class="opacity-0" :action="paymentRequest.paymentUrl">
      <input
        v-for="(input, index) in paymentRequest.fields"
        :key="input.id"
        type="hidden"
        :name="index"
        :value="input"
      />
    </form>
  </touch-layout>
</template>

<script>

import { mapStores } from 'pinia'
import { useCustomerStore } from '@/pinia/customer';
import { useStyleStore } from '@/pinia/style';
import { formatCurrency } from '@/composables/currency';
import OrderComponent from '@/components/shared/Order.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import ShowNote from '@/components/shared/ShowNote.vue';

export default {
  components: {
    OrderComponent,
    Breadcrumbs,
    ShowNote,
  },
  setup() {
    return { formatCurrency }
  },
  data() {
    return {
      order: undefined,
      visibilityOptions: undefined,
      paymentRequest: {},
    };
  },
  computed: {
    ...mapStores(useCustomerStore, useStyleStore),
    showReleaseToPortalOrAdminButton() {
      return this.order && !this.order.visibleToAdmin && this.order.visibleToAdmin !== undefined;
    },
    cancellationAvailable() {
      return (
        this.order &&
        this.order.orderStatus !== window.enum.orderStatus.PLACED &&
        this.order.orderStatus !== window.enum.orderStatus.CANCELLED
      );
    },
  },
  methods: {
    setOrder(order) {
      this.order = order;
    },
    async setVisibility() {
      const res = await window.touch.contractUpdateVisibility(this.order.id, {
        visibleToAdmin: !this.order.visibleToAdmin,
      });
      if (res.status === 200) {
        this.order.visibleToAdmin = !this.order.visibleToAdmin;
        window.alertBox.fire('Order is now shown to admin');
      }
    },
    redirectToCheckout() {
      this.routerPush('/checkout');
    },
    redirectToBasket() {
      this.routerPush('/basket');
    },
    redirectCancel() {
      this.$router.push('/orders');
    },
    async redirectToPayment() {
      await window.touch
        .CreatePaymentRequest(this.$route.params.id, 1)
        .then((response) => {
          this.paymentRequest = response;
          if (this.paymentRequest.url) {
            window.location.href = this.paymentRequest.url;
          } else {
            this.$nextTick(() => {
              this.$refs.theForm.submit();
            });
          }
        })
        .catch((error) => {
          this.alertBox().fire({
            title: error,
            icon: 'error',
          });
          this.$router.push(`/order/${this.$route.params.id}`);
        });
    },
    isEditJob(isEditJob) {
      if (this.styleStore.fabricator.portalOnStop) {
        window.alertBox.fire({
          title: 'System under temporary maintenance',
          text: 'Please check back soon'
        });
      } else {
        isEditJob()
      }
    }
  },
};
</script>
