<template>
  <transition name="fade-in">
    <div
      v-if="modal_open"
      class="fixed top-0 left-0 right-0 bottom-0 bg-black_80 z-50 flex flex-col justify-around p-5"
    >
      <div
        class="bg-white rounded-lg p-3 xs:p-10 w-full mx-auto max-h-full overflow-y-auto"
        :style="{ maxWidth: maxWidth + 'px' }"
        @click.stop
      >
        <loading :loading="loading" label="Saving">
          <modal-window
            v-if="isForm && isEdited && isSaving"
            :is-form="false"
            :modal_open="true"
            :is-closable="false"
            title="There are unsaved changes in this form"
            @close="$emit('close')"
          >
            <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
              <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click="isSaving = false">
                Go Back
              </button>
              <button class="btn-action btn-lg" @click="$emit('close')">Discard</button>
            </div>
          </modal-window>
          <div ref="modal">
            <div
              class="flex border-b justify-between -m-3 xs:-m-10 mb-6 xs:mb-10 px-3 xs:px-10 py-3 flex-wrap-reverse"
            >
              <div v-if="title" class="font-medium text-xl">
                {{ title }}
              </div>
              <div v-if="isClosable" class="flex ml-auto">
                <div
                  v-if="!isEdited || !isForm"
                  class="pl-6 flex flex-col text-xl justify-around text-gray-600 cursor-pointer"
                  @click="$emit('close')"
                >
                  <i class="fal fa-times"></i>
                </div>
                <div
                  v-else
                  class="pl-6 flex flex-col text-xl justify-around text-gray-600 cursor-pointer"
                  @click="isSaving = true"
                >
                  <i class="fal fa-times"></i>
                </div>
              </div>
            </div>
            <slot></slot>
          </div>
        </loading>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    maxWidth: {
      default: 800,
    },
    modal_open: {
      default: false,
    },
    isClosable: {
      default: true,
    },
    isForm: {
      default: true,
    },
    title: String,
    loading: Boolean
  },
  data() {
    return {
      isEdited: false,
      isSaving: false,
      isUpdated: false,
    };
  },
  watch: {
    'modal_open': {
      async handler(to) {
        if (to) {
          this.$nextTick(() => {
            const inputElements = this.$refs.modal.querySelectorAll('input, textarea, select')
            for (let i = 0; i < inputElements.length; i++) {
              inputElements[i].addEventListener('input', this.handleInputChange);
            }

            const buttonElements = this.$refs.modal.querySelectorAll('button');
            for (let i = 0; i < buttonElements.length; i++) {
              buttonElements[i].addEventListener('click', this.handleInputChange);
            }
          })
        }
      },
      immediate: true,
    },
  },
  beforeUnmount() {
    const inputElements = this.$refs.modal?.querySelectorAll('input, textarea, select') || [];
    for (let i = 0; i < inputElements.length; i++) {
      inputElements[i].removeEventListener('input', this.handleInputChange);
    }
    const buttonElements = this.$refs.modal?.querySelectorAll('button') || [];
    for (let i = 0; i < buttonElements.length; i++) {
      buttonElements[i].removeEventListener('click', this.handleInputChange);
    }
  },
  methods: {
    handleInputChange() {
      this.isEdited = true
    }
  }
};
</script>
