<template>
  <Teleport defer to="#portal_popup">
    <modal-window :modal_open="true" title="Edit Video" @close="$emit('close')">
      <div class="flex flex-wrap -mx-3 mb-3">
        <div class="p-3 w-full 2xs:w-1/2">
          <div class="font-medium">Name</div>
          <div>
            <input v-model="video.name" class="form-control" />
          </div>
        </div>
        <div class="p-3 w-full 2xs:w-1/2">
          <div class="font-medium">Category</div>
          <div>
            <select v-model="video.categoryId" class="form-control">
              <option v-for="category in categories" :key="category.id" :value="category.id">
                {{ category.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap -mx-3 mb-3">
        <div class="p-3 w-full 2xs:w-1/2">
          <div class="font-medium">URL</div>
          <div>
            <input v-model="video.url" class="form-control" />
          </div>
        </div>
      </div>
      <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
        <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
          Cancel
        </button>
        <button class="btn-action btn-lg" @click.prevent.stop="save()">Save</button>
      </div>
    </modal-window>
  </Teleport>
</template>

<script>

import { getVideoType } from '@/composables/video';

export default {
  props: ['categories', 'video'],
  data() {
    return {
      saving: false,
      errors: [],
    };
  },
  methods: {
    async save() {
      this.errors = [];

      if (getVideoType(this.video.url) === this.enums.documentVideoType.NONE) {
        this.errors.push('We only support youtube or vimeo URLs.');
        return;
      }

      if (this.video.categoryId === null) {
        this.errors.push('Please choose a Video Category');
        return;
      }

      if (this.video.url === null) {
        this.errors.push('Please add a video url');
        return;
      }

      this.saving = true;
      await window.touch.customerUploadDocumentWithUrl(this.video).then(() => {
        this.saving = false;
        this.$emit('updated');
        this.$emit('close');
      });
    },
  },
};
</script>
