<template>
  <Teleport defer to="#portal_popup">
    <modal-window
      :modal_open="true"
      title="Are you sure? The cancellation cannot be undone."
      :is-closable="false"
      @close="$emit('close')"
    >
      <div class="flex flex-col container">
        <div class="flex flex-row flex-wrap mb-6">
          <input
            type="checkbox"
            class="mr-2 ml-2"
            :value="riskAccepted"
            @change="riskAccepted = !riskAccepted"
          />
          <div>
            I understand that once cancelled this order cannot be uncancelled and wish to continue
            cancelling the order.
          </div>
        </div>
        <div class="text-right mt-auto flex flex-col 2xs:block">
          <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
            Back (do not cancel)
          </button>
          <button
            class="btn-lg 2xs:mr-1 mb-1 2xs:mb-0"
            :class="[{ 'btn-disabled': !riskAccepted }, { 'btn-action': riskAccepted }]"
            @click.prevent.stop="cancelOrder"
          >
            Cancel Order
          </button>
        </div>
      </div>
    </modal-window>
  </Teleport>
</template>

<script>
export default {
  data() {
    return {
      riskAccepted: false,
    };
  },
  methods: {
    cancelOrder() {
      if (this.riskAccepted) {
        this.$emit('cancel');
      }
    },
  },
};
</script>
