<template>
  <div id="app" class="text-sm">
    <debug-banner v-if="showDebugBanner" :vue-env="vueEnv" />
    <router-view />
    <FreshdeskWidget />
    <TalkativeWidget v-if="hasTalkative" />
  </div>
</template>

<script>
import DebugBanner from '@/components/shared/DebugBanner.vue';
import FreshdeskWidget from '@/components/shared/FreshdeskWidget.vue';
import TalkativeWidget from '@/components/shared/TalkativeWidget.vue';
import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';
import { useStyleStore } from '@/pinia/style';
import { useFeatureStore } from '@/pinia/feature';
import { isIframe } from '@/composables/web';
import { migrationPinia } from '@/composables/migrationPinia';

export default {
  components: {
    DebugBanner,
    FreshdeskWidget,
    TalkativeWidget,
  },
  setup() {
    return { isIframe }
  },
  data() {
    return {
      vueEnv: import.meta.env.VITE_APP_ENV,
    };
  },
  computed: {
    ...mapStores(useAuthStore, useStyleStore, useFeatureStore),
    hasTalkative() {
      return this.touch_portal_user && window.touch.urlIs('endurance') && this.$route.meta.portalRoute;
    },
    showDebugBanner() {
      return (this.vueEnv?.toLowerCase() !== 'prod') & (this.vueEnv?.toLowerCase() !== 'fitshow');
    },
  },
  watch: {
    'authStore.loggedIn': {
      async handler(to) {
        if (to) {
          try {
            const response = await window.touch.authGetCompanyWebSettings()
            if (response.data.companyWebSettings.some(x => x.key === 'tagId')) {
              const index = response.data.companyWebSettings.findIndex(x => x.key === 'tagId')
              window.gtag('config', 'G-' + response.data.companyWebSettings[index].value);
            }
          } catch (error) {
            this.authStore.loggedIn = false
          }
        }
      },
      immediate: true,
    },
    'styleStore.page_title': {
      async handler(to) {
        window.document.title = to;
      },
      immediate: true,
    },
    style: {
      async handler() {
        var existingFavicons = document.querySelectorAll("link[rel*='icon']");
        existingFavicons.forEach(function (link) {
          link.remove();
        });
        var link = document.createElement('link');
        link.type = 'image/png';
        link.rel = 'icon';
        if (this.touch_portal_installation && this.authStore.usingBrandedModeToken) {
          link.href = this.style.customer_logo || '/favicon.ico';
        } else {
          link.href = this.style.fabricator_logo || '/favicon.ico';
        }
        document.head.appendChild(link);
      },
      immediate: true,
    },
  },
  created() {
    migrationPinia()
    this.handleDuplicateTabs()
  },
  mounted() {
    this.initialiseStoreData();
    this.authStore.login_url = ['staging', 'prod'].includes(this.vueEnv?.toLowerCase()) ? '/login' : '/login-admin'

    if (isIframe()) {
      const sendHeight = () => {
        window.parent.postMessage({ type: 'resize', height: document.documentElement.scrollHeight }, '*')
      }
      window.addEventListener('resize', sendHeight)
    }
  },
  methods: {
    initialiseStoreData() {
      this.styleStore.refresh()
      this.featureStore.refreshPermissions();
    },
    handleDuplicateTabs() {
      const tabKey = 'tabOpen';
      /* 
        - Sesson storage tabKey is removed on refresh, tab closure or leaving page. 
        - Also doesn't exist in new sessions. 
        - If exists on page load must be duplicate tab.
      */
      if (sessionStorage.getItem(tabKey) && this.branded_mode_user) {
        sessionStorage.removeItem(tabKey);
        window.location.replace('/launch/marshdemo');
      } else {
        sessionStorage.setItem(tabKey, 'true');
      }
      // On refresh, tab closure or leaving page
      window.addEventListener('beforeunload', () => {
        sessionStorage.removeItem(tabKey);
      });
    }
  },
};
</script>

<style src="./assets/css/styles.css"></style>
