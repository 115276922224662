import moment from 'moment';
moment.locale(navigator.language);

/*
 * tests a few things
 * 1. does the date evaluate to a truthy value (e.g. not null)
 * 2. Object.prototype.toString.call(date) makes sure it's specifically a js date object (and not, for example, a user created Date object)
 * 3. isNaN makes sure the date is valid.
 * https://stackoverflow.com/questions/643782/how-to-check-whether-an-object-is-a-date has a more in depth explanation of this solution.
 */

export const isValidDate = (date) =>
  date && Object.prototype.toString.call(date) === '[object Date]' && !Number.isNaN(date);

export const addMinutesToDate = (date, minutes) => new Date(date.getTime() + minutes * 60000);

export const addDays = (date, days) => {
  const dateCopy = new Date(date.getTime());
  dateCopy.setDate(dateCopy.getDate() + days);
  return dateCopy;
};

export const getDateFromJsDate = (date) => moment(date).format('YYYY-MM-DD');

export const getAllDatesBetweenDatesInclusive = (startDate, endDate) => {
  const dates = [];
  let currentDate = new Date(startDate.getTime());
  while (currentDate <= endDate) {
    dates.push(currentDate);
    currentDate = addDays(currentDate, 1);
  }
  return dates;
};

export const todaysDate = moment().format('ddd, LL');

export function formatDate(date, format = 'L') {
  return moment(date).format(format);
}

export function formatHumanDate(date) {
  return moment(date).fromNow();
}

export function expiringInXDays(date, days){
  if (date) {
    const inXDaysTime = new Date()
    inXDaysTime.setDate(inXDaysTime.getDate() + days);
    if (inXDaysTime > new Date(date) && new Date(date) > new Date()) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}
