<template>
  <div class="flex flex-wrap justify-center">
    <div
      v-if="canChangeBayDesigns"
      class="view-button flex flex-row items-center justify-center text-gray-600 text-center text-2xs cursor-pointer border p-2 rounded relative uppercase tracking-widest w-auto bg-white m-1 whitespace-nowrap"
      :class="{ selected: changingDesign }"
      @click.prevent="changeDesign()"
    >
      Change Frame Designs
      <div class="pl-2 hidden sm:block">
        <i class="fal fa-window-frame"></i>
      </div>
    </div>

    <div
      v-if="currentViewPartOfToggle && !changingDesign"
      class="view-toggle-btn flex w-40 justify-center view-button text-gray-600 text-center text-2xs cursor-pointer border p-2 rounded relative uppercase tracking-widest bg-white m-1 whitespace-nowrap"
      @click.prevent="toggleInnerView()"
    >
      <div>External</div>
      <div class="relative mx-2">
        <div
          class="pill w-6 h-3 bg-gray-400 rounded-full shadow-inner"
          :class="{ _active: toggleValue === enums.imageType.INTERNAL }"
        ></div>
        <div
          class="toggle-indicator switch-toggle"
          :class="{ _active: toggleValue === enums.imageType.INTERNAL }"
        ></div>
      </div>
      <div>Internal</div>
    </div>
    <div
      v-else
      class="view-button w-40 text-gray-600 text-center text-2xs cursor-pointer border p-2 rounded relative uppercase tracking-widest bg-white m-1 whitespace-nowrap"
      @click.prevent="toggleAlternateView(cachedToggleValue)"
    >
      Internal/External View
    </div>

    <div
      v-if="hasGlassSchematic"
      class="view-button text-gray-600 text-center text-2xs cursor-pointer border p-2 rounded relative uppercase tracking-widest w-auto bg-white m-1 whitespace-nowrap"
      :class="{ selected: currentImageType === enums.imageType.GLASS_SCHEMATIC && !changingDesign }"
      @click.prevent="toggleAlternateView(enums.imageType.GLASS_SCHEMATIC)"
    >
      {{ image_types[enums.imageType.GLASS_SCHEMATIC] }} View
    </div>

    <div
      v-if="hasBowBayPlanView"
      class="view-button text-gray-600 text-center text-2xs cursor-pointer border p-2 rounded relative uppercase tracking-widest w-auto bg-white m-1 whitespace-nowrap"
      :class="{ selected: currentImageType === enums.imageType.PLAN && !changingDesign }"
      @click.prevent="toggleAlternateView(enums.imageType.PLAN)"
    >
      {{ image_types[enums.imageType.PLAN] }} View
    </div>

    <div
      v-if="hasFoldingSashPlanView"
      class="view-button text-gray-600 text-center text-2xs cursor-pointer border p-2 rounded relative uppercase tracking-widest w-auto bg-white m-1 whitespace-nowrap"
      :class="{
        selected: currentImageType === enums.imageType.FOLDING_SASH_VIEW && !changingDesign,
      }"
      @click.prevent="toggleAlternateView(enums.imageType.FOLDING_SASH_VIEW)"
    >
      {{ image_types[enums.imageType.FOLDING_SASH_VIEW] }} View
    </div>

    <div
      v-if="hasSashPlainView"
      class="view-button text-gray-600 text-center text-2xs cursor-pointer border p-2 rounded relative uppercase tracking-widest w-auto bg-white m-1 whitespace-nowrap"
      :class="{
        selected: currentImageType === enums.imageType.SASH_PLAN_VIEW && !changingDesign,
      }"
      @click.prevent="toggleAlternateView(enums.imageType.SASH_PLAN_VIEW)"
    >
      {{ image_types[enums.imageType.SASH_PLAN_VIEW] }} View
    </div>
  </div>
</template>

<script>
export default {
  props: ['images', 'currentImageType', 'canChangeBayDesigns', 'changingDesign'],
  data() {
    return {
      image_types: window.imageTypes,
      cachedToggleValue: 0,
    };
  },
  computed: {
    nextImageType() {
      let currentImageIndex = -1;

      for (let i = 0; i < this.imagesForImageRotationButton.length; i += 1) {
        if (this.currentImageType === this.imagesForImageRotationButton[i].type) {
          currentImageIndex = i;
        }
      }

      if (currentImageIndex + 1 < this.imagesForImageRotationButton.length) {
        return this.imagesForImageRotationButton[currentImageIndex + 1].type;
      }

      return this.imagesForImageRotationButton[0].type;
    },
    imagesForImageRotationButton() {
      return this.images.filter(
        (image) =>
          image.type !== this.enums.imageType.GLASS_SCHEMATIC &&
          image.type !== this.enums.imageType.PLAN,
      );
    },
    hasGlassSchematic() {
      return this.images.find((image) => image.type === this.enums.imageType.GLASS_SCHEMATIC);
    },
    hasBowBayPlanView() {
      return this.images.find((image) => image.type === this.enums.imageType.PLAN);
    },
    hasFoldingSashPlanView() {
      return this.images.find((image) => image.type === this.enums.imageType.FOLDING_SASH_VIEW);
    },
    hasSashPlainView() {
      return this.images.find((image) => image.type === this.enums.imageType.SASH_PLAN_VIEW);
    },
    currentViewPartOfToggle() {
      return (
        this.currentImageType === this.enums.imageType.EXTERNAL ||
        this.currentImageType === this.enums.imageType.INTERNAL
      );
    },

    // we need to switch to using a cached value if the user decides to look at a view that's not on the toggle.
    toggleValue() {
      return this.currentViewPartOfToggle ? this.currentImageType : this.cachedToggleValue;
    },
  },
  watch: {
    currentImageType(newVal, oldVal) {
      if (!this.isTypePartOfViewToggle(newVal) && this.isTypePartOfViewToggle(oldVal)) {
        // we cache the current value of the toggle so we can revert to it when the user has finished with the alternate view.
        this.cachedToggleValue = oldVal;
      }
    },
  },
  methods: {
    toggleInnerView() {
      if (this.enums.imageType.INTERNAL === this.currentImageType) {
        this.showImageType(this.enums.imageType.EXTERNAL);
      } else {
        this.showImageType(this.enums.imageType.INTERNAL);
      }
    },
    toggleAlternateView(type) {
      if (this.changingDesign) {
        this.$emit('change-design');
      }
      this.showImageType(type);
    },
    isTypePartOfViewToggle(value) {
      return value === this.enums.imageType.EXTERNAL || value === this.enums.imageType.INTERNAL;
    },
    showImageType(type) {
      this.$emit('show-image-type', type);
    },
    changeDesign() {
      if (this.changingDesign) {
        return;
      }
      if (this.currentViewPartOfToggle) {
        this.cachedToggleValue = this.toggleValue;
      }
      this.$emit('change-design');
    },
  },
};
</script>

<style scoped>
.view-button.selected,
.view-toggle-btn {
  background-color: #d6f0df;
}

.pill._active {
  background-color: theme("colors.green.400");
}

.toggle-indicator._active {
  background-color: theme("colors.gray.200");
}
</style>
