<template>
  <div v-if="isVoucherCode" class="flex flex-col gap-1 mt-3">
    <div class="text-left">Voucher Code</div>
    <div
      class="h-10 p-1 pl-3 border w-full rounded placeholder-gray-500 flex"
      :class="[isApplied ? 'border-black' : 'border-black']"
      tabindex="0"
      @focusout="handleFocusOut"
    >
      <input
        ref="input"
        v-model="code"
        type="text"
        class="flex-grow"
        :placeholder="'Enter a voucher code for this ' + type + '....'"
      />
      <button
        v-if="code && code !== basketStore.job.discountCode"
        class="text-white py-1 px-3 rounded cursor-pointer bg-brand-primary"
        @click="validate"
      >
        APPLY
      </button>
    </div>
    <div v-if="isApplied && code === basketStore.job.discountCode" class="flex gap-1 items-center text-green-500">
      <i class="fa fa-circle-check"></i>
      <strong>Voucher Code Applied</strong>
    </div>
  </div>
  <div v-else class="mt-3">
    <span class="text-blue-500 cursor-pointer" @click="isVoucherCode = true">Apply voucher code to this {{ type }}?</span>
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useBasketStore } from '@/pinia/basket';

export default {
  props: {
    type: String,
    itemKey: Number,
    isApplied: Boolean,
    customerId: Number
  },
  data() {
    return {
      code: '',
      isVoucherCode: false
    }
  },
  computed: {
    ...mapStores(useBasketStore),
  },
  watch: {
    'isVoucherCode'(to) {
      if (to) {
        this.$nextTick(() => this.$refs.input.focus())
      }
    },
    'isApplied'(to) {
      if (!to) {
        this.code = ''
      }
    }
  },
  mounted() {
    if (this.isApplied) {
      this.code = this.basketStore.job.discountCode
      this.isVoucherCode = true
    }
  },
  methods: {
    handleFocusOut() {
      if (!this.code) {
        setTimeout(() => this.isVoucherCode = false, 250)
      }
    },
    async validate() {
      if (this.basketStore.job.discountApplied) {
        this.alertBox()
          .fire({
            title: 'A Voucher code is already applied',
            text: 'Are you sure you want to override this code?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              await this.applyDiscountVoucher()
            }
          });
      } else {
        await this.applyDiscountVoucher()
      }
    },
    async applyDiscountVoucher() {
      await window.touch.ApplyDiscountVoucher({
        Code: this.code,
        CustomerId: this.customerId,
        ContractId: this.basketStore.contractIdentifier.contractId,
        JobKey: this.basketStore.contractIdentifier.jobKey,
        ItemKey: this.itemKey,
      })
      await this.basketStore.refresh(true);
      this.code = this.basketStore.job.discountCode
    }
  },
};
</script>
