<template>
  <div>
    <div class="flex flex-col gap-10 xs:flex-row pb-10 border-b">
      <div class="new-image-zone flex-1">
        <div class="flex flex-col justify-around text-base">
          <strong>Enquiry Email Header Image</strong>
        </div>
        <div class="text-gray-600 text-base my-3">
          <div>
            <strong><strong>Location:</strong></strong> The Header of the email receipt the user
            receives after making an enquiry.
          </div>
          <div>
            <strong><strong>Purpose:</strong></strong> Personalise your paperwork with a photo
            displaying your product
          </div>
        </div>
        <Dropzone
          :current_file="emailEquiryImageDetails.src"
          @add-file="$emit('addFile', { file: $event, imageType: emailEquiryImageDetails.imageType })"
          @remove-file="$emit('removeFile', emailEquiryImageDetails.imageType)"
        ></Dropzone>
        <div class="text-gray-500 py-3">
          <div>Required file type: JPG, PNG.</div>
          <div>Recommended size: 437 x 423 pixels</div>
          <div>Max File Size: 1MB</div>
        </div>
      </div>
      <div class="flex flex-col gap-5 w-full flex-1">
        <div
          v-if="emailEquiryImageDetails.src"
          class="flex flex-col xs:mx-auto md:mr-0 justify-center border border-gray-300 w-full lg:w-8/12"
          style="min-height: 300px; max-width: 400px;"
        >
          <img :src="emailEquiryImageDetails.src" />
        </div>
        <div
          v-else-if="!emailEquiryImageDetails.src"
          class="flex flex-col xs:mx-auto md:mr-0 justify-center border border-gray-300 w-full lg:w-8/12"
          style="min-height: 300px; max-width: 400px;"
        >
          <img :src="`/images/guides/quote-first-page-image.jpg`" />
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-10 xs:flex-row mt-10 border-b pb-10">
      <div class="new-image-zone flex-1">
        <div class="flex flex-col justify-around text-base">
          <strong>Enquiry PDF image 1</strong>
        </div>
        <div class="text-gray-600 text-base my-3">
          <div><strong>Location:</strong> Intro page of the pdf emailed to the homeowner</div>
          <div>
            <strong>Purpose:</strong> Personalise your paperwork with a photo displaying your
            product
          </div>
        </div>
        <Dropzone
          :current_file="enquiryReportFirstPageImageDetails.src"
          @add-file="$emit('addFile', { file: $event, imageType: enquiryReportFirstPageImageDetails.imageType })"
          @remove-file="$emit('removeFile', enquiryReportFirstPageImageDetails.imageType)"
        ></Dropzone>
        <div class="text-gray-500 py-3">
          <div>Required file type: JPG, PNG.</div>
          <div>Recommended size: 1240 x 1752 pixels</div>
          <div>Max File Size: 1MB</div>
        </div>
      </div>
      <div class="flex flex-col gap-5 w-full flex-1">
        <div
          v-if="enquiryReportFirstPageImageDetails.src"
          class="flex flex-col xs:mx-auto md:mr-0 justify-center border border-gray-300 w-full lg:w-8/12"
          style="min-height: 300px; max-width: 400px;"
        >
          <img :src="enquiryReportFirstPageImageDetails.src" />
        </div>
        <div
          v-else-if="!enquiryReportFirstPageImageDetails.src"
          class="flex flex-col xs:mx-auto md:mr-0 justify-center border border-gray-300 w-full lg:w-8/12"
          style="min-height: 300px; max-width: 400px;"
        >
          <img :src="`/images/enquiryPdfFirstImageLocation${touch_portal_user ? 'Portal' : ''}.png`" />
        </div>
      </div>
    </div>
    <div class="flex flex-col xs:flex-row gap-10 mt-10 border-b pb-10">
      <div class="new-image-zone flex-1">
        <div class="flex flex-col justify-around text-base">
          <strong>Enquiry PDF image 2</strong>
        </div>
        <div class="text-gray-600 text-base my-3">
          <div>
            <strong><strong>Location:</strong></strong> Back page of the pdf emailed to the
            homeowner
          </div>
          <div>
            <strong><strong>Purpose:</strong></strong> Personalise your paperwork with a photo
            displaying your product
          </div>
        </div>
        <Dropzone
          :current_file="enquiryReportLastPageImageDetails.src"
          @add-file="$emit('addFile', { file: $event, imageType: enquiryReportLastPageImageDetails.imageType })"
          @remove-file="$emit('removeFile', enquiryReportLastPageImageDetails.imageType)"
        ></Dropzone>
        <div class="text-gray-500 py-3">
          <div>Required file type: JPG, PNG.</div>
          <div>Recommended size: 1240 x 1752 pixels</div>
          <div>Max File Size: 1MB</div>
        </div>
      </div>
      <div class="flex flex-col gap-5 w-full flex-1">
        <div
          v-if="enquiryReportLastPageImageDetails.src"
          class="flex flex-col xs:mx-auto md:mr-0 justify-center border border-gray-300 w-full lg:w-8/12"
          style="min-height: 300px; max-width: 400px;"
        >
          <img :src="enquiryReportLastPageImageDetails.src" />
        </div>
        <div
          v-else-if="!enquiryReportLastPageImageDetails.src"
          class="flex flex-col xs:mx-auto md:mr-0 justify-center border border-gray-300 w-full lg:w-8/12"
          style="min-height: 300px; max-width: 400px;"
        >
          <img src="/images/guides/quote-last-page-image.jpg" />
        </div>
      </div>
    </div>
    <div class="flex flex-col xs:flex-row  gap-10 mt-10">
      <div class="flex-1 max-w-3xl mb-5 sm:mb-0">
        <div class="flex flex-col justify-around text-base">
          <strong>Enquiry PDF Summary Footer</strong>
        </div>
        <div class="text-gray-600 text-base my-3">
          <div>
            <strong>Location:</strong> The last page of the enquiry PDF, at the bottom of the
            summary section.
          </div>
        </div>
        <div class="flex flex-col mt-5">
          <div class="flex flex-col justify-around text-base mb-3">
            <div>Footer Title</div>
          </div>
          <div class="max-w-sm">
            <input v-model="settings.enquiryPdfFooterHeader" type="text" class="form-control" />
          </div>
        </div>
        <div class="flex flex-col mt-5">
          <div class="flex flex-col justify-around text-base mb-3">
            <div>Footer Text</div>
          </div>
          <div class="max-w-sm">
            <input v-model="settings.enquiryPdfFooterText" type="text" class="form-control" />
          </div>
        </div>
      </div>
      <div
        class="flex flex-1 flex-col xs:mx-auto md:mr-0 justify-center border border-gray-300 w-full lg:w-8/12"
        style="min-height: 300px; max-width: 400px;"
      >
        <img src="/images/guides/what-happens-next.jpg" />
      </div>
    </div>
  </div>
</template>

<script>
import Dropzone from '@/components/shared/Dropzone.vue';

export default {
  components: {
    Dropzone
  },
  props: {
    setup: String,
    settings: Object,
    emailEquiryImageDetails: Object,
    enquiryReportFirstPageImageDetails: Object,
    enquiryReportLastPageImageDetails: Object,
  },
};
</script>

<style scoped>
.preview-section {
  width: 500px;
}

.new-image-zone {
  width: 100%;
}

@media (min-width: theme('screens.sm')) {
  .new-image-zone {
    width: 700px;
  }
}
</style>
