import { useAuthStore } from '@/pinia/auth';
import { useCustomerStore } from '@/pinia/customer';

export default async (to, from, next) => {
  if (to.path === '/') {
    next({ path: '/portal' });
  } else if (to.matched.some((record) => record.meta.portalRoute === true)) {
    const authStore = useAuthStore()
    const customerStore = useCustomerStore()
    const isLoginValid = await authStore.validateLogin('customer')
    if (isLoginValid) {
      if (from.name === null) {
        await authStore.setLogin('customer')
        const customer = await customerStore.customerGet()
        customerStore.setCustomer(customer)
      }
      customerStore.freshdeskWidgetEnabled = true;
      next();
    } else {
      customerStore.freshdeskWidgetEnabled = false;
      next({
        path: authStore.login_url,
        query: to.fullPath ? { redirect: to.fullPath } : undefined
      });
    }
  } else {
    next('/');
  }
};
