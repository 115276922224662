<template>
  <div class="loader"></div>
</template>

<style>
.loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  position: relative;
  background: #fff;
  box-shadow:
    -24px 0 #fff,
    24px 0 #fff;
  box-sizing: border-box;
  animation: animate 2s linear infinite;
}

@keyframes animate {
  33% {
    background: #fff;
    box-shadow:
      -24px 0 #ff3d00,
      24px 0 #fff;
  }
  66% {
    background: #ff3d00;
    box-shadow:
      -24px 0 #fff,
      24px 0 #fff;
  }
  100% {
    background: #fff;
    box-shadow:
      -24px 0 #fff,
      24px 0 #ff3d00;
  }
}
</style>
