import { errorCallback, successCallback } from '@/composables/callback';

export async function stockListStockItems(
  subCategoryId,
  searchText,
) {
  // this can take a partId and/or a subcategory item and get all the items associated with them.
  return (
    await this.authenticatedPost('stock/ListStockItems', {
      Limit: 99999,
      Offset: 0,
      subCategoryId,
      searchText: searchText ?? '',
    })
  ).data;
}
export async function stockUpdateStockItems(stockItems) {
  return this.parseResponse(
    await this.authenticatedPost('stock/UpdateStockItems', {
      stockItems,
    }),
    errorCallback,
    () => successCallback('Save')
  );
}
export async function stockUpdateStockCustomerPrice(
  stockCustomerPriceDetails,
) {
  return this.parseResponse(
    await this.authenticatedPost('stock/UpdateStockCustomerPrice', {
      stockCustomerPriceDetails,
    }),
    errorCallback,
    () => successCallback('Save')
  );
}
export async function stockListStockCustomerPrice(
  customerId,
  itemId,
) {
  return (
    await this.authenticatedPost('stock/ListStockCustomerPrice', {
      CustomerId: customerId,
      stockId: itemId,
    })
  ).data;
}
export async function stockListStockParts(query) {
  return (
    await this.authenticatedPost('stock/ListStockParts', {
      Limit: 99999,
      Offset: 0,
      SearchText: query,
    })
  ).data;
}

export async function stockListStockPartSubCategories() {
  return (
    await this.authenticatedPost('stock/ListStockPartSubCategories', {
      Limit: 99999,
      Offset: 0,
    })
  ).data;
}

export async function stockListStockPartCategories() {
  return (
    await this.authenticatedPost('stock/ListStockPartCategories', {
      Limit: 99999,
      Offset: 0,
    })
  ).data;
}
