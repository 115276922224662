<template>
  <div class="xs:flex">
    <div
      v-if="groups.length > 0"
      class="relative m-3"
      tabindex="1"
      @focusout="select_group_dropdown_open = false"
    >
      <div
        class="border border-t-4 border-l-0 border-r-0 border-b-0 border-white w-full"
        :class="{ 'border-green-600': modelValue.groupId !== undefined }"
      ></div>
      <div
        class="border flex justify-between cursor-pointer"
        @click="
          (select_group_dropdown_open = !select_group_dropdown_open),
          (select_type_dropdown_open = false),
          $emit('close-number-of-squares-dropdown')"
      >
        <div class="p-3 whitespace-nowrap text-gray-600">Select Group</div>
        <div class="p-3">
          <i v-if="select_group_dropdown_open" class="fa fa-caret-up"></i>
          <i v-else class="fa fa-caret-down"></i>
        </div>
      </div>
      <div
        v-show="select_group_dropdown_open"
        class="absolute bg-gray-200 w-full md:w-64 border border-t-0 z-10 text-xs sm:text-sm"
      >
        <div v-for="group in groups" :key="group.productSelectionGroupId" class="">
          <label
            :title="group.description"
            class="flex justify-between cursor-pointer bg-white m-2 rounded-lg cursor-pointer hover:bg-brand-primary hover:text-white"
            :class="{
              'bg-brand-primary text-white': modelValue.groupId == group.productSelectionGroupId,
            }"
          >
            <div class="p-3">
              <input
                v-model="modelValue.groupId"
                type="radio"
                name="group"
                class="hidden"
                :modelValue="group.productSelectionGroupId"
                @change="selectGroup"
              />
              {{ group.title }}
            </div>
            <div v-if="modelValue.groupId == group.productSelectionGroupId" class="p-3">
              <i class="fal fa-check"></i>
            </div>
          </label>
        </div>
      </div>
    </div>
    <div
      v-if="types.length > 0"
      class="relative m-3"
      tabindex="2"
      @focusout="select_type_dropdown_open = false"
    >
      <div
        class="border border-t-4 border-l-0 border-r-0 border-b-0 border-white w-full"
        :class="{ 'border-green-600': modelValue.typeId !== undefined }"
      ></div>
      <div
        class="border flex justify-between cursor-pointer"
        @click="
          (select_type_dropdown_open = !select_type_dropdown_open),
          (select_group_dropdown_open = false),
          $emit('close-number-of-squares-dropdown')
        "
      >
        <div class="p-3 whitespace-nowrap text-gray-600">Select Type</div>
        <div class="p-3">
          <i v-if="select_type_dropdown_open" class="fa fa-caret-up"></i>
          <i v-else class="fa fa-caret-down"></i>
        </div>
      </div>
      <div
        v-show="select_type_dropdown_open"
        class="absolute bg-gray-200 w-full md:w-64 border border-t-0 z-10 text-xs sm:text-sm"
      >
        <div v-for="type in types" :key="type.productSelectionTypeId" class="">
          <label
            :title="type.description"
            class="flex justify-between cursor-pointer bg-white m-2 rounded-lg cursor-pointer hover:bg-brand-primary hover:text-white"
            :class="{ 'bg-brand-primary text-white': modelValue.typeId === type.productSelectionTypeId }"
          >
            <div class="p-3">
              <input
                v-model="modelValue.typeId"
                type="radio"
                name="type"
                class="hidden"
                :modelValue="type.productSelectionTypeId"
                @change="selectType"
              />
              {{ type.title }}
            </div>
            <div v-if="modelValue.typeId === type.productSelectionTypeId" class="p-3">
              <i class="fal fa-check"></i>
            </div>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useTouchStore } from '@/pinia/touch';
import { useBasketStore } from '@/pinia/basket';

export default {
  props: ['modelValue', 'number_of_squares_dropdown_open'],
  data() {
    return {
      groups: [],
      types: [],
      select_group_dropdown_open: false,
      select_type_dropdown_open: false,
    };
  },
  computed: {
    ...mapStores(useBasketStore, useTouchStore),
  },
  watch: {
    number_of_squares_dropdown_open() {
      if (this.number_of_squares_dropdown_open) {
        this.select_group_dropdown_open = false;
        this.select_type_dropdown_open = false;
      }
    },
  },
  async mounted() {
    try {
      this.groups = (await this.touchStore.processingGetAvailableProductSelectionGroups(this.basketStore.customerId))
        .map((group) => group.groups)
        .filter(Boolean)
        .sort((a, b) => a.listOrder - b.listOrder);
    } catch (error) {
      return;
    }

    this.groups.unshift({
      productSelectionGroupId: undefined,
      title: 'All',
    });

    this.types = (
      await this.touchStore.processingGetAvailableProductSelectionTypes({
        customerId: this.basketStore.customerId,
      })
    )
      .map((type) => type.types)
      .filter(Boolean)
      .sort((a, b) => a.listOrder - b.listOrder);

    this.types.unshift({
      productSelectionTypeId: undefined,
      title: 'All',
    });

    this.$emit('input', {
      groupId: undefined,
      typeId: undefined,
      selectedGroupName: null,
      selectedTypeName: null,
    });
  },
  methods: {
    selectGroup() {
      this.select_group_dropdown_open = false;
      this.refreshTypes();
    },
    selectType() {
      this.select_type_dropdown_open = false;

      this.$emit('input', {
        groupId: this.modelValue.groupId,
        typeId: this.modelValue.typeId,
        selectedGroupName: this.getGroupName(this.modelValue.groupId),
        selectedTypeName: this.getTypeName(this.modelValue.typeId),
      });
    },
    async refreshTypes() {
      this.types = (
        await this.touchStore.processingGetAvailableProductSelectionTypes({
          customerId: this.basketStore.customerId,
          groupId: this.modelValue.groupId,
        })
      )
        .filter((type) => type.types !== undefined)
        .map((type) => type.types)
        .sort((a, b) => a.listOrder - b.listOrder);

      this.types.unshift({
        productSelectionTypeId: undefined,
        title: 'All',
      });

      this.$emit('input', {
        groupId: this.modelValue.groupId,
        typeId: undefined,
        selectedGroupName: this.getGroupName(this.modelValue.groupId),
        selectedTypeName: 'All',
      });
    },
    getGroupName(groupId) {
      const currentGroup = this.groups.filter((group) => groupId === group.productSelectionGroupId);
      if (currentGroup.length > 0) {
        return currentGroup[0].title;
      }
      return 'All';
    },
    getTypeName(typeId) {
      const currentType = this.types.filter((type) => typeId === type.productSelectionTypeId);
      if (currentType.length > 0) {
        return currentType[0].title;
      }
      return 'All';
    },
  },
};
</script>

<style scoped></style>
