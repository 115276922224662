<template>
  <div @click.stop="visible = !visible">
    <a
      class="flex flex-col justify-center p-4 text-center border-l border-b cursor-pointer h-full w-16 sm:w-32"
    >
      <div class="text-2xl">
        <i class="fal fa-bars"></i>
      </div>
      <div class="hidden sm:block text-sm capitalize mt-3">
        <div>View</div>
        <div>Menu</div>
      </div>
    </a>

    <transition appear name="fade-in">
      <div v-show="visible">
        <div
          class="absolute top-0 left-0 right-0 bottom-0 background-black_80 z-40"
          @click.stop="visible = false"
        ></div>
        <nav class="relative">
          <div
            class="inline-block absolute right-0 top-0 overflow-x-auto z-50"
            :class="touch_portal_installation
              ? 'bg-blue-900 text-white'
              : 'bg-white text-black border border-t-0'"
            style="max-height: 70vh"
          >
            <div class="flex capitalize justify-between p-3">
              <div class="whitespace-nowrap mr-5 flex flex-col justify-around">
                {{ todaysDate }}
              </div>
              <div class="ml-auto">
                <a
                  class="cursor-pointer hover:bg-blue-900 px-4 py-2 bg-blue-700 border border-blue-700 text-white rounded-lg flex flex-col justify-around capitalize whitespace-nowrap transition duration-500 ease-in-out"
                  @click="routerPush(authStore.superuser ? '/login-admin' : '/login')"
                >
                  Log Out
                </a>
              </div>
            </div>

            <PortalMenuItems v-if="touch_portal_user"></PortalMenuItems>
            <BusinessMenuItems v-if="touch_business_user"></BusinessMenuItems>
          </div>
        </nav>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';
import { todaysDate } from '@/composables/date';
import PortalMenuItems from '@/components/shared/nav/PortalMenuItems.vue';
import BusinessMenuItems from '@/components/shared/nav/BusinessMenuItems.vue';

export default {
  components: {
    PortalMenuItems,
    BusinessMenuItems,
  },
  setup() {
    return { todaysDate }
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    ...mapStores(useAuthStore),
  },
};
</script>
