<template>
  <div class="flex flex-col justify-around w-full p-2">
    <label class="bg-white rounded-full text-xs flex border border-white w-full">
      <div class="bg-brand-primary rounded-full p-2 border-white" style="width: 32px; height: 32px">
        <i class="fal fa-search text-white fa-fw"></i>
      </div>
      <div class="flex flex-col justify-around uppercase flex-grow">
        <input
          ref="searchInput"
          type="text"
          class="bg-transparent outline-none pl-2 h-full flex-grow"
          :value="modelValue"
          placeholder="Search by option name..."
          @click.stop
          @input="$emit('update:modelValue', $event.target.value)"
        />
      </div>
      <div v-if="isFilter" class="fixed w-full h-full top-0 left-0 z-40" @click="isFilter = false"></div>
      <div v-if="filters.length > 0" class="relative">
        <div class="bg-gray-500 rounded-full p-2 border-white cursor-pointer flex items-center gap-1" style="height: 32px" @click="isFilter = !isFilter">
          <i class="fal fa-filter text-white fa-fw"></i>
          <div v-if="filters.some(x => x.id === filterId)" class="text-white text-xs">
            {{ filters.find(x => x.id === filterId).description }}
          </div>
        </div>
        <div v-if="isFilter" class="absolute bg-white z-10 border border-gray-300 text-xs rounded right-0 mt-1 z-50" style="min-width: max-content">
          <div>
            <div v-for="filter in noFilter.concat(filters)" :key="filter.id" class="flex gap-2 py-2 px-4 hover:bg-gray-200" @click="$emit('setFilterId', filter.id), isFilter = false">
              <span>
                {{ filter.description }}
              </span>
              <span class="text-green-600 ml-auto" :class="{ 'invisible': filterId !== filter.id }">
                <i class="fal fa-check"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: String,
    filters: Array,
    filterId: String,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      isFilter: false,
      noFilter: [{
        id: undefined,
        description: 'No Filter'
      }]
    }
  },
  mounted() {
    if (window.innerWidth > 1024) {
      this.$refs.searchInput.focus();
    }
  },
};
</script>
