import { errorCallback, successCallback } from '@/composables/callback';

export async function CreatePaymentRequest(contractId, JobKey) {
  const response = this.parseResponse(
    await this.authenticatedPost('payment/CreatePaymentRequest', {
      contractId,
      JobKey,
    }),
    (errorResponse) => {
      throw new Error(errorResponse.data.message);
    },
  );

  if (!response) {
    throw new Error('No response');
  }

  return response;
}

export async function GetContractTransactions(contractId) {
  return this.parseResponseWith400(
    await this.authenticatedPost('payment/GetContractTransactions', {
      contractId,
    }),
  );
}

export async function UpdatePaymentStatus(
  ContractId,
  PaymentStatus,
) {
  return this.parseResponse(
    await this.authenticatedPost('contract/UpdatePaymentStatus', {
      ContractId,
      PaymentStatus,
    }),
    errorCallback,
    () => successCallback('Save'),
  );
}

export async function getAccount() {
  return this.parseResponseWith400(await this.authenticatedPost('stripe/getaccount'));
}

export async function createAccount() {
  return this.parseResponse(await this.authenticatedPost('stripe/createaccount'), errorCallback)
}

export async function createAccountLink() {
  return this.parseResponse(await this.authenticatedPost('stripe/createaccountlink'), errorCallback)
}
