<template>
  <Teleport v-if="modal_open" defer to="#portal_popup">
    <modal-window :modal_open="true" title="Add Staff Member" @close="$emit('close')">
      <div class="text-green-600 pl-3 flex flex-col justify-around font-medium text-base">
        * Required Fields
      </div>
      <div class="flex flex-wrap mt-5">
        <div class="w-full xs:w-1/2 xs:pr-5">
          <div class="font-medium">First Name <span class="text-green-600">*</span></div>
          <input v-model="user.firstName" type="text" class="form-control" />
        </div>
        <div class="w-full mt-4 xs:w-1/2 xs:pl-5 xs:mt-0">
          <div class="font-medium">Last Name <span class="text-green-600">*</span></div>
          <input v-model="user.lastName" type="text" class="form-control" />
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full mt-5 xs:w-1/2 xs:pr-5 xs:mt-8">
          <div class="font-medium">Email <span class="text-green-600">*</span></div>
          <input v-model="user.email" type="text" class="form-control" />
        </div>
        <div class="w-full mt-5 xs:w-1/2 xs:pl-5 xs:mt-8">
          <div class="font-medium">Mobile</div>
          <input v-model="user.mobile" type="text" class="form-control" />
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full mt-5 xs:w-1/2 xs:pr-5 xs:mt-8">
          <div class="font-medium">Departments <span class="text-green-600">*</span></div>
          <select v-model="user.departments" multiple class="form-control multiple">
            <option
              v-for="department in touchStore.contactDepartments"
              :key="department.id"
              :value="department.id"
            >
              {{ department.name }}
            </option>
          </select>
        </div>
        <div class="w-full mt-5 xs:w-1/2 xs:pl-5 xs:mt-8">
          <ContactRoles
            :roles="user.roles"
            @set-contact-roles="$event === 0 ? (user.roles = $event) : (user.roles += $event)"
          />
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full mt-5 xs:w-1/2 xs:pr-5 xs:mt-8">
          <div class="font-medium">Processing Level <span class="text-green-600">*</span></div>
          <select v-model="user.processingLevelId" class="form-control">
            <option disabled="disabled">Please Select</option>
            <option
              v-for="processingLevel in touchStore.processingLevels"
              :key="processingLevel.id"
              :value="processingLevel.id"
            >
              {{ processingLevel.name }}
            </option>
          </select>
        </div>
        <div v-if="authStore.isBranchManagerAvailable" class="w-full mt-5 xs:w-1/2 xs:pl-5 xs:mt-8">
          <Branch :available-branches="branches" :branches="user.branches" @set-branches="user.branches = $event" />
        </div>
      </div>
      <div class="w-full mt-4 xs:w-1/2 xs:pr-5 xs:mt-8">
        <div class="w-full">
          <Dropzone
            @add-file="new_user_image = $event"
            @remove-file="new_user_image = null"
          ></Dropzone>
        </div>
        <div class="mt-1">Photos help your teammates recognise you in TOUCH.</div>
      </div>
      <div class="xs:flex">
        <div class="flex flex-col justify-around h-10 md:h-20 lg:h-10 mt-3">
          <div
            class="flex items-center cursor-pointer"
            @click.prevent="user.isAdmin = !user.isAdmin"
          >
            <div class="relative">
              <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner"></div>
              <div class="switch-toggle" :class="{ _active: user.isAdmin }"></div>
            </div>
            <div class="ml-2 text-gray-700 font-medium whitespace-nowrap w-32">
              <span v-text="user.isAdmin ? 'Admin User' : 'Non Admin User'"></span>
            </div>
          </div>
        </div>
        <div class="flex flex-col">
          <div class="flex flex-col justify-around h-10 md:h-20 lg:h-10 mt-3">
            <div
              class="flex items-center cursor-pointer"
              @click.prevent="user.noLogin = !user.noLogin"
            >
              <div class="relative">
                <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner"></div>
                <div class="switch-toggle" :class="{ _active: user.noLogin }"></div>
              </div>
              <div class="ml-2 text-gray-700 font-medium whitespace-nowrap">No Login</div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
        <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
          Cancel
        </button>
        <button
          class="btn-action btn-lg"
          :class="{ 'pointer-events-none opacity-50': loading }"
          @click.prevent.stop="saveUser()"
        >
          Save
        </button>
      </div>
    </modal-window>
  </Teleport>
</template>

<script>
import validate from 'validate.js';
import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';
import { useCustomerStore } from '@/pinia/customer';
import { useTouchStore } from '@/pinia/touch';
import Dropzone from '@/components/shared/Dropzone.vue';
import ContactRoles from '@/components/shared/ContactRoles.vue';
import Branch from '@/components/business/manageTouchAccount/staff/branch.vue';

export default {
  components: {
    Dropzone,
    ContactRoles,
    Branch
  },
  data() {
    return {
      user: {
        isAdmin: false,
        licenseStatusType: 1,
        departments: [],
        noLogin: false,
        roles: 0,
        branches: []
      },
      loading: true,
      new_user_image: null,
      options: {
        limit: 20,
        offset: 0,
      },
      branches: [],
      modal_open: false,
    };
  },
  computed: {
    ...mapStores(useAuthStore, useCustomerStore, useTouchStore),
  },
  async mounted() {
    this.touchStore.loadProcessingLevels();
    const response = await this.customerStore.customerListBranches(this.options);
    this.branches = response.branches;
    this.modal_open = true;
    this.loading = false;
  },
  methods: {
    async saveUser() {
      this.loading = true;
      const validationErrors = validate(
        {
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          email: this.user.email,
          processingLevel: this.user.processingLevelId,
          departments: this.user.departments,
        },
        {
          departments: {
            presence: { allowEmpty: false },
          },
          processingLevel: {
            presence: { allowEmpty: false },
          },
          firstName: {
            presence: { allowEmpty: false },
            length: {
              maximum: 32,
            },
          },
          lastName: {
            presence: { allowEmpty: false },
            length: {
              maximum: 32,
            },
          },
          email: {
            presence: { allowEmpty: false },
            length: {
              maximum: 255,
            },
            email: true,
          },
        },
      );

      if (validationErrors) {
        this.alertBox().fire({
          title: 'Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        this.loading = false;
        return;
      }

      if (this.user.branches.length > 0 && this.user.isAdmin) {
        this.alertBox().fire({
          title: 'Branch users are non-admins',
        });
        this.loading = false;
        return;
      }

      if (this.new_user_image !== null) {
        this.user.image = this.new_user_image;
      }

      await this.customerStore.customerCreateContact(this.user).then(() => {
        this.$emit('saved');
        this.$emit('close');
      });
      this.loading = false;
    },
  },
};
</script>
