<template>
  <div class="">
    <div class="2xs:flex relative">
      <Dropzone
        :current_file="image"
        :orientation="'portrait'"
        @add-file="image = $event"
        @remove-file="image = null"
      ></Dropzone>
      <div class="flex-grow 2xs:ml-3 2xs:pl-3 2xs:border-l 2xs:border-gray-200">
        <div class="flex flex-col xs:flex-row xs:items-center mb-2">
          <label class="xs:w-32" for="name"> Title </label>
          <input id="name" v-model="title" type="text" class="form-control" />
        </div>
        <div class="flex flex-col xs:flex-row xs:items-center mb-2">
          <label class="w-32" for="description"> Description </label>
          <textarea id="description" v-model="description" rows="5" class="form-control"></textarea>
        </div>
      </div>
    </div>
    <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
      <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
        Cancel
      </button>
      <button class="btn-action btn-lg" @click.prevent.stop="saveOption()">Add Group</button>
    </div>
  </div>
</template>

<script>
import validate from 'validate.js';
import Dropzone from '@/components/shared/Dropzone.vue';

export default {
  components: {
    Dropzone
  },
  props: ['categories'],
  data() {
    return {
      title: '',
      description: null,
      image: null,
    };
  },
  methods: {
    inputIsValid() {
      return validate(
        {
          title: this.title,
          description: this.description,
        },
        {
          title: {
            presence: { allowEmpty: false },
          },
          description: {
            presence: { allowEmpty: false },
          },
        },
      );
    },
    saveOption() {
      const validationErrors = this.inputIsValid();

      if (validationErrors) {
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        return;
      }

      this.$emit('new-product-group', {
        title: this.title,
        description: this.description,
        ImageBlob: this.image,
      });
      this.image = null;
      this.title = '';
      this.description = null;
    }
  },
};
</script>

<style scoped></style>
