<template>
  <Teleport defer to="#portal_popup"> 
    <modal-window :modal_open="true" title="Add Holiday" @close="$emit('close')">
      <div class="flex flex-col container">
        <div class="flex flex-col flex-wrap">
          <div class="w-full xs:w-1/2 xs:pr-5 mt-4">
            <div class="font-medium">Title</div>
            <input v-model="newHolidayTitle" class="form-control" />
          </div>
          <div class="w-full xs:w-1/2 xs:pr-5 mt-4">
            <div class="font-medium">Start Date</div>
            <input v-model="newHolidayStart" input-class="form-control" type="date" />
          </div>
          <div class="w-full xs:w-1/2 xs:pr-5 mt-4">
            <div class="font-medium">End Date</div>
            <input v-model="newHolidayEnd" input-class="form-control" type="date" />
          </div>
        </div>
        <div class="text-right mt-auto flex flex-col 2xs:block">
          <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
            Cancel
          </button>
          <button class="btn-action btn-lg" @click.prevent.stop="saveHoliday">Save Holiday</button>
        </div>
      </div>
    </modal-window>
  </Teleport>
</template>

<script>
import validate from 'validate.js';
import { getDateFromJsDate } from '@/composables/date';

export default {
  props: {
    holidayTitle: {
      type: String,
      default: '',
    },
    holidayStart: {
      type: String,
      default: undefined,
    },
    holidayEnd: {
      type: String,
      default: undefined,
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      newHolidayEnd: this.holidayEnd,
      newHolidayStart: this.holidayStart,
      newHolidayTitle: this.holidayTitle,
    };
  },
  methods: {
    async saveHoliday() {
      const validationErrors = validate(
        {
          name: this.newHolidayTitle,
          holidayStart: this.newHolidayStart,
          holidayEnd: this.newHolidayEnd,
        },
        {
          name: {
            presence: { allowEmpty: false },
          },
          holidayStart: {
            presence: { allowEmpty: false },
          },
          holidayEnd: {
            presence: { allowEmpty: false },
          },
        },
      );

      if (validationErrors) {
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        return;
      }

      if (this.id) {
        await window.touch.updateCompanyHoliday(
          this.id,
          this.newHolidayTitle,
          getDateFromJsDate(new Date(this.newHolidayStart)),
          getDateFromJsDate(new Date(this.newHolidayEnd)),
        );
      } else {
        await window.touch.addCompanyHoliday(
          this.newHolidayTitle,
          getDateFromJsDate(new Date(this.newHolidayStart)),
          getDateFromJsDate(new Date(this.newHolidayEnd)),
        );
      }

      this.$emit('created');
      this.$emit('close');
    },
  },
};
</script>
<style scoped>
.container {
  min-height: 650px;
}
</style>
