<template>
  <div class="w-full">
    <div class="bg-white overflow-x-auto pl-3 md:pl-10">
      <div class="flex bg-white gap-10 border-t-4 border-white">
        <router-link
          to="/manage-touch-account/company-details"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black text-sm"
          :class="{
            'border-black': $route.name === 'company-details',
            'border-white': $route.name !== 'company-details',
          }"
        >
          Company Details
        </router-link>
        <router-link
          to="/manage-touch-account/documents-and-agreements"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black"
          :class="{
            'border-black': $route.name === 'documents-and-agreements',
            'border-white': $route.name !== 'documents-and-agreements',
          }"
        >
          Documents & Agreements
        </router-link>
        <router-link
          v-if="styleStore.isCompanyStylingEnabled"
          to="/manage-touch-account/custom-styles"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black text-sm"
          :class="{
            'border-black': $route.name === 'custom-styles',
            'border-white': $route.name !== 'custom-styles',
          }"
        >
          Custom Styles
        </router-link>
        <router-link
          v-if="!isLeadGen"
          to="/manage-touch-account/delivery-options"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black text-sm"
          :class="{
            'border-black': $route.name === 'delivery-options',
            'border-white': $route.name !== 'delivery-options',
          }"
        >
          Delivery Options
        </router-link>
        <router-link
          v-if="!isLeadGen"
          to="/manage-touch-account/quote-stages"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black text-sm"
          :class="{
            'border-black': $route.name === 'quote-stages',
            'border-white': $route.name !== 'quote-stages',
          }"
        >
          Quote Stages
        </router-link>
        <router-link
          v-if="basketStore.isPaymentGateway"
          to="/manage-touch-account/payment-gateway"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black text-sm"
          :class="{
            'border-black': $route.name.includes('Payment Gateway'),
            'border-white': !$route.name.includes('Payment Gateway'),
          }"
        >
          Payment Gateway
        </router-link>
        <div class="pr-5"></div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useStyleStore } from '@/pinia/style';
import { useBasketStore } from '@/pinia/basket';

export default {
  computed: {
    ...mapStores(useStyleStore, useBasketStore),
    isLeadGen() {
      return (
        this.styleStore.fabricator?.accountProductStatus ===
        this.enums.ProductStatus.LEAD_GEN_ONLY
      );
    },
  },
};
</script>
