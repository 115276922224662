<template>
  <Checkout
    ref="checkout"
    breakdown="Price"
    :customer="customer"
    :warnings_checked="true"
    @cancel-order="cancelOrder"
    @redirect-process-place-order="redirectProcessPlaceOrder"
  ></Checkout>
</template>

<script>

import { mapStores } from 'pinia'
import { useBasketStore } from '@/pinia/basket';
import Checkout from '@/components/shared/checkout/Checkout.vue';

export default {
  components: {
    Checkout,
  },
  props: {
    customer: Object,
  },
  computed: {
    ...mapStores(useBasketStore),
  },
  mounted() {
    if (!this.basketStore.hasContract) {
      this.routerPush(`/customer/${this.basketStore.customerId}/sales-dashboard/new-quote`);
    }
    this.$refs.checkout.terms_checked = true;
  },
  methods: {
    cancelOrder() {
      this.basketStore.clearBasket();
      this.$router.go(-1);
    },
    redirectProcessPlaceOrder({ isSuccess }) {
      if (isSuccess) {
        this.routerPush(`/customer/${this.basketStore.customerId}/sales-dashboard?type=` + this.basketStore.buttonName.toLowerCase());
      } else {
        this.routerPush('/customer');
      }
    },
  },
};
</script>
