<template>
  <div>
    <div v-if="!basketStore.available_addresses || basketStore.available_addresses.length === 0">
      No delivery addresses available.
    </div>
    <template v-else>
      <div>
        <div v-for="(row, i) in address_array" :key="i" v-text="row"></div>
      </div>
      <select class="form-control bg-white" @change="setAddress($event.target.value)">
        <option v-if="basketStore.selected_address_id === 0" :value="0">Please Select</option>
        <option
          v-for="address in basketStore.available_addresses"
          :key="address.id"
          :selected="address.id == basketStore.selected_address_id"
          :value="address.id"
          v-text="describe(address)"
        ></option>
      </select>
    </template>
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useBasketStore } from '@/pinia/basket';

export default {
  computed: {
    ...mapStores(useBasketStore),
    address() {
      const addressArray = this.basketStore.available_addresses.filter(
        (address) => address.id === parseInt(this.basketStore.selected_address_id, 10),
      );

      if (addressArray.length !== 1) {
        return null;
      }

      return addressArray[0];
    },

    address_array() {
      if (this.address === null) {
        return [];
      }

      if (!(typeof this.address === 'object')) {
        return [];
      }

      return [
        [this.address.line1, this.address.line2, this.address.line3].filter(Boolean).join(', '),
        this.address.town,
        this.address.code,
        this.address.iso,
      ].filter(Boolean);
    },
  },
  methods: {
    async setAddress(addressId) {
      if (parseInt(addressId)) {
        await this.basketStore.setAddress(addressId);
        await this.basketStore.refreshDelivery()
        await this.basketStore.refresh();
      }
    },
    describe(address) {
      return [address.line1, address.line2, address.line3, address.town, address.code, address.iso]
        .filter(Boolean)
        .join(', ');
    },
  },
};
</script>
