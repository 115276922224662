<template>
  <div>
    <div id="map" class="mb-6" :style="{ height: '400px' }"></div>
    <div class="text-right flex">
      <div class="ml-auto mr-3">
        <button
          class="btn-action btn-lg rounded-sm"
          :style="{ 'background-color': 'lightgrey', 'border-color': 'lightgrey' }"
          @click="$emit('hideGoogleMaps')"
        >
          Go Back
        </button>
      </div>
      <div>
        <button class="btn-action btn-lg rounded-sm" @click="saveCoordinates">
          Save Coordinates
        </button>
      </div>
    </div>
  </div>
</template>

<script>
function initMap() {
  const myLatlng = { lat: window.latitude, lng: window.longitude };
  let content;
  if (myLatlng.lat === 0 && myLatlng.lng === 0) {
    myLatlng.lat = 51.5072;
    myLatlng.lng = 0.1276;
    content = 'London';
    window.latitude = 51.5072;
    window.longitude = 0.1276;
  } else {
    content = JSON.stringify(myLatlng);
  }

  const map = new window.google.maps.Map(document.getElementById('map'), {
    zoom: 8,
    center: myLatlng,
  });

  let infoWindow = new window.google.maps.InfoWindow({
    content,
    position: myLatlng,
  });

  infoWindow.open(map);
  map.addListener('click', (mapsMouseEvent) => {
    infoWindow.close();
    infoWindow = new window.google.maps.InfoWindow({
      position: mapsMouseEvent.latLng,
    });
    infoWindow.setContent(JSON.stringify(mapsMouseEvent.latLng.toJSON(), null, 2));
    window.latitude = mapsMouseEvent.latLng.lat();
    window.longitude = mapsMouseEvent.latLng.lng();
    infoWindow.open(map);
  });
}

window.initMap = initMap;
window.latitude = 0;
window.longitude = 0;

export default {
  props: {
    latitude: {
      default: 0,
      type: Number,
    },
    longitude: {
      default: 0,
      type: Number,
    },
  },
  created() {
    if (!window.google) {
      this.setupScript();
    }

    window.latitude = Number(this.latitude);
    window.longitude = Number(this.longitude);
  },
  mounted() {
    if (window.google) {
      initMap();
    }
  },
  beforeUnmount() {
    window.initMap = undefined;
    window.latitude = undefined;
    window.longitude = undefined;
  },
  methods: {
    setupScript() {
      const recaptchaScript = document.createElement('script');
      recaptchaScript.setAttribute(
        'src',
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyA9ccJ2OEtBJ47b2eaqVAEYVf2kZqBZQFo&callback=initMap&v=weekly',
      );
      document.head.appendChild(recaptchaScript);
    },
    saveCoordinates() {
      this.$emit('saveCoordinates', window.latitude, window.longitude);
      this.$emit('hideGoogleMaps');
    },
  },
};
</script>
