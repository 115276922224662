<template>
  <div
    class="bg-white p-5 rounded-lg"
    :class="{ 'gap-x-5 xs:grid xs:grid-cols-2 lg:grid-cols-3 lg:gap-5 lg:auto-rows-fr': is_admin_user }"
  >
    <div class="p-5 col-span-2 lg:col-span-1">
      <div class="mb-2">
        <span>Hi {{ firstName }}...</span>
      </div>
      <div>
        <span class="text-xl">Welcome to your {{ companyName }} Admin</span>
      </div>
    </div>
    <button
      v-if="is_admin_user"
      class="text-left bg-blue-200 rounded-lg flex items-end p-4"
      @click.stop.prevent="$emit('addCustomerPopUp')"
    >
      <div class="flex-1">
        <div>
          <span class="text-xl">Add Customer</span>
        </div>
        <div>
          <span>Allow more customers to quote & order.</span>
        </div>
      </div>
      <div class="flex-1">
        <img class="w-full" src="/images/newaccount.png" />
      </div>
    </button>
    <router-link
      v-if="is_admin_user"
      class="bg-yellow-200 rounded-lg flex items-end p-4 mt-4 xs:mt-0"
      to="/manage-touch-account/staff?isAddStaff=true"
    >
      <div class="flex-1">
        <div>
          <span class="text-xl">Add Staff</span>
        </div>
        <div>
          <span>Add more staff members to your team.</span>
        </div>
      </div>
      <div class="flex-1">
        <img class="w-full" src="/images/staffmember.png" />
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    firstName: String,
    companyName: String
  },
};
</script>
