import { defineStore } from 'pinia';
import { useAuthStore } from '@/pinia/auth';
import { useStyleStore } from '@/pinia/style';

export const useFeatureStore = defineStore('feature', {
  persist: true,
  state: () => ({
    isCreateQuoteEnabled: true,
    isCreateOrderEnabled: true,
    isViewQuotesEnabled: true,
    isViewOrdersEnabled: true,
    isQuoteEmailDetailEditingEnabled: true,
    isRequotePriceChangeEnabled: true,
  }),
  actions: {
    setIsCreateQuoteEnabled(isEnabled) {
      this.isCreateQuoteEnabled = isEnabled;
    },
    setIsCreateOrderEnabled(isEnabled) {
      this.isCreateOrderEnabled = isEnabled;
    },
    setIsViewQuotesEnabled(isEnabled) {
      this.isViewQuotesEnabled = isEnabled;
    },
    setIsViewOrdersEnabled(isEnabled) {
      this.isViewOrdersEnabled = isEnabled;
    },
    setIsQuoteEmailDetailEditingEnabled(isEnabled) {
      this.isQuoteEmailDetailEditingEnabled = isEnabled;
    },
    setIsRequotePriceChangeEnabled(isEnabled) {
      this.isRequotePriceChangeEnabled = isEnabled;
    },
    async isRequotePriceChangeEnabled() {
      const authStore = useAuthStore();
      const styleStore = useStyleStore();
      const productStatus = styleStore.fabricator?.accountProductStatus ?? (await window.touch.commonOrganisationDetails())?.accountProductStatus;
      if (productStatus !== window.enum.ProductStatus.LEAD_GEN_ONLY) {
        if (window.VITE_APP_INSTALLATION_TYPE === 'business' && authStore.loggedIn) {
          return true;
        }
        if (authStore.admin) {
          return true;
        }
      }
      return false;
    },
    async refreshPermissions() {
      const styleStore = useStyleStore();
      const productStatus = styleStore.fabricator?.accountProductStatus ?? (await window.touch.commonOrganisationDetails())?.accountProductStatus;
      this.setIsQuoteEmailDetailEditingEnabled( 
        window.VITE_APP_INSTALLATION_TYPE !== 'business' &&
        productStatus !== window.enum.ProductStatus.LEAD_GEN_ONLY
      )

      if (productStatus === window.enum.ProductStatus.LEAD_GEN_ONLY && window.VITE_APP_INSTALLATION_TYPE === 'portal') {
        this.setIsCreateQuoteEnabled(false)
        this.setIsCreateOrderEnabled(false)
        this.setIsViewQuotesEnabled(false)
        this.setIsViewOrdersEnabled(false)
      } else {
        this.setIsCreateQuoteEnabled(true)
        this.setIsCreateOrderEnabled(true)
        this.setIsViewQuotesEnabled(true)
        this.setIsViewOrdersEnabled(true)
      }
    },
  },
  getters: {
    getIsCreateQuoteEnabled(state) {
      return state.isCreateQuoteEnabled;
    },
    getIsCreateOrderEnabled(state) {
      return state.isCreateQuoteEnabled;
    },
    getIsViewQuotesEnabled(state) {
      return state.isCreateQuoteEnabled;
    },
    getIsViewOrdersEnabled(state) {
      return state.isCreateQuoteEnabled;
    },
    getIsQuoteEmailDetailEditingEnabled(state) {
      return state.isQuoteEmailDetailEditingEnabled;
    },
    getIsRequotePriceChangeEnabled(state) {
      return state.isRequotePriceChangeEnabled;
    },
    isRackManagerEnabled() {
      const styleStore = useStyleStore();
      return styleStore.fabricator?.name === 'Epwin Window Systems';
    },
  },
});
