<template>
  <div class="m-auto">
    <div class="flex flex-col-reverse md:flex-row md:items-center mb-6">
      <h1 class="mt-3 md:mt-0">Selected Extras</h1>
      <div class="flex flex-grow justify-end items-center">
        <div>
          <button class="btn-action ml-2" @click.prevent="showAddItem = true">Choose Extra</button>
        </div>
      </div>
      <Teleport v-if="showAddItem" defer to="#portal_popup">
        <add-extra-item
          :supply-only="!basketStore.isFit"
          title="Add extra to Item"
          @close="showAddItem = false"
          @attach-item="attachItem($event)"
          @attach-item-with-option="attachItemWithOption($event)"
        ></add-extra-item>
      </Teleport>
    </div>

    <div v-if="extras.length > 0" class="mb-6">
      <base-tile
        v-for="extra in extras"
        :key="extra.key"
        :item="item"
        :extra="extra"
        class="p-2 border rounded-lg mb-4"
        @set-breakdown="$emit('setBreakdown')"
        @delete="$emit('delete-line-item', extra.key)"
      ></base-tile>
    </div>
    <div v-else class="mb-3">No extras selected</div>
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useBasketStore } from '@/pinia/basket';
import AddExtraItem from '@/components/shared/basket/AddExtraItem.vue';
import BaseTile from '@/components/shared/basket/BaseTile.vue';

export default {
  components: {
    'add-extra-item': AddExtraItem,
    'base-tile': BaseTile,
  },
  props: ['itemId', 'extras', 'item'],
  data() {
    return {
      showAddItem: false,
    };
  },
  computed: {
    ...mapStores(useBasketStore),
  },
  methods: {
    focusOut(event) {
      if (window.$(this.$refs.aside).has(event.relatedTarget).length > 0) {
        return; // don't close if focus passed to a child element
      }

      if (event.relatedTarget === this.$refs.aside) {
        return; // don't close if we're the element gaining focus
      }

      if (this.showAddItem) {
        return; // don't close if a popup is open
      }

      if (window.$(event.relatedTarget).hasClass('swal2-confirm')) {
        return; // don't close on swal popup
      }

      this.close();
    },
    defaultParams(type, withSizes = true, quantity) {
      const common = {
        Quantity: quantity || 1,
      };

      if (!withSizes) {
        return common;
      }

      switch (type) {
        case window.enum.unitOfMeasure.NONE:
          return {
            ...common,
          };
        case window.enum.unitOfMeasure.UNIT:
          return {
            ...common,
          };
        case window.enum.unitOfMeasure.WIDTH:
          return {
            ...common,
            Width: 0,
          };
        case window.enum.unitOfMeasure.LENGTH:
          return {
            ...common,
            Length: 0,
          };
        case window.enum.unitOfMeasure.AREA:
          return {
            ...common,
            Width: 0,
            Height: 0,
          };
        case window.enum.unitOfMeasure.FITTING_DAYS:
          return {
            ...common,
          };
        case window.enum.unitOfMeasure.HEIGHT:
          return {
            ...common,
            Height: 0,
          };
        case window.enum.unitOfMeasure.PERIMETER:
          return {
            ...common,
            Width: 0,
            Height: 0,
          };
        case window.enum.unitOfMeasure.VOLUME:
          return {
            ...common,
            Length: 0,
            Width: 0,
            Height: 0,
          };
        default:
          throw Error(`Unknown Unit Of Measure ${type}`);
      }
    },
    async attachItemWithOption({ item, option }) {
      if (
        this.extras.some(
          (x) => x.extraItemId === item.id && x.optionDescription === option.description,
        ) &&
        (item.useSizesFromProduct || item.unitOfMeasure === this.enums.unitOfMeasure.UNIT)
      ) {
        const existingExtra = this.extras.find(
          (x) => x.extraItemId === item.id && x.optionDescription === option.description,
        );
        await this.basketStore.updateExistingExtraItem({
          itemId: parseInt(existingExtra.key, 10),
          extraItemId: item.id,
          params: {
            ...this.defaultParams(
              item.unitOfMeasure,
              !item.useSizesFromProduct,
              existingExtra.quantity + 1,
            ),
          },
        });
      } else {
        await this.basketStore.addExistingExtraItem({
          itemId: parseInt(this.itemId, 10),
          extraItemId: item.id,
          params: {
            ...this.defaultParams(item.unitOfMeasure, !item.useSizesFromProduct),
            OptionId: option.id,
          },
        });
      }
      await this.basketStore.refresh(true);
      this.$emit('setBreakdown');
    },
    async attachItem(extraItem) {
      let extra;
      if (
        this.extras.some((x) => x.extraItemId === extraItem.id) &&
        (extraItem.useSizesFromProduct || extraItem.unitOfMeasure === this.enums.unitOfMeasure.UNIT)
      ) {
        const existingExtra = this.extras.find((x) => x.extraItemId === extraItem.id);
        extra = await this.basketStore.updateExistingExtraItem({
          params: {
            Quantity: existingExtra.quantity + 1,
            Length:
              !existingExtra.useSizesFromProduct &&
                [window.enum.unitOfMeasure.LENGTH, window.enum.unitOfMeasure.VOLUME].includes(
                  existingExtra.unitOfMeasure,
                )
                ? existingExtra.length
                : undefined,
            Width:
              !existingExtra.useSizesFromProduct &&
                [
                  window.enum.unitOfMeasure.WIDTH,
                  window.enum.unitOfMeasure.AREA,
                  window.enum.unitOfMeasure.PERIMETER,
                  window.enum.unitOfMeasure.VOLUME,
                ].includes(existingExtra.unitOfMeasure)
                ? existingExtra.width
                : undefined,
            Time: [window.enum.unitOfMeasure.FITTING_DAYS].includes(existingExtra.unitOfMeasure)
              ? existingExtra.time
              : undefined,
            Height:
              !existingExtra.useSizesFromProduct &&
                [
                  window.enum.unitOfMeasure.HEIGHT,
                  window.enum.unitOfMeasure.AREA,
                  window.enum.unitOfMeasure.PERIMETER,
                  window.enum.unitOfMeasure.VOLUME,
                ].includes(existingExtra.unitOfMeasure)
                ? existingExtra.height
                : undefined,
          },
          itemId: existingExtra.key,
        });
      } else {
        extra = await this.basketStore.addExistingExtraItem({
          itemId: parseInt(this.itemId, 10),
          extraItemId: extraItem.id,
          params: this.defaultParams(extraItem.unitOfMeasure, !extraItem.useSizesFromProduct),
        });
      }
      if (extra && extra.status === false) {
        this.alertBox().fire(extra.message);
      }
      this.$emit('setBreakdown');
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
