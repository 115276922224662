<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Product Setup', '/product-setup'],
          ['Stock Parts'],
        ]"
      />
    </template>
    <template #header_page_title> Stock Parts </template>
    <Teleport defer to="#portal_search">
      <SearchBox class="flex" placeholder="Search for an code/description..." />
    </Teleport>
    <Menu></Menu>
    <StockParts :query="search" :minimum-query-length="stockPartsStore.minimumQueryLength"></StockParts>
  </touch-layout>
</template>

<script>

import { mapStores } from 'pinia'
import { useStockPartsStore } from '@/pinia/stock-parts';
import StockParts from '@/components/shared/StockParts.vue';
import Menu from '@/components/business/productSetup/Menu.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import SearchBox from '@/components/shared/SearchBox.vue';

export default {
  components: {
    Menu,
    StockParts,
    Breadcrumbs,
    SearchBox,
  },
  computed: {
    ...mapStores(useStockPartsStore),
    search() {
      return this.$route.query.search || '';
    },
  },
  created() {
    this.$router.replace({ query: undefined }).catch(() => { });
    setTimeout(() => {
      this.$watch('$route.query', this.setQuery);
    }, 0);
  },
  methods: {
    setQuery() {
      this.stockPartsStore.runItemQuery(this.search)
      if (this.search.length < this.stockPartsStore.minimumQueryLength) {
        this.stockPartsStore.setSearchQuery('')
        this.stockPartsStore.setQueriedStockParts({
          query: this.search,
          results: [],
        });
      }
    },
  },
};
</script>
