<template>
  <transition name="fade-in" appear mode="out-in">
    <div v-show="!loading" class="h-screen bg-gray-200 sm:bg-transparent overflow-hidden">
      <div class="text-brand-primary hidden sm:block">
        <div class="absolute top-0 right-0 -z-1 left-0">
          <img
            v-if="isFabricatorForMondayDemo()"
            src="/images/stddemofab.png"
            class="ml-auto w-1/2"
          />
          <img
            v-else-if="hasCustomSecondaryImage"
            :src="customSecondaryImage"
            class="ml-auto w-1/2"
          />
          <img v-else :src="$t('/images/login-background.png')" class="ml-auto w-1/2" />
        </div>
      </div>
      <header class="bg-white w-full sm:bg-transparent print:hidden">
        <div class="flex flex-grow">
          <logo
            class="border-0 bg-transparent"
            :src="style.fabricator_logo"
            :href="$t(store_url)"
          ></logo>
          <div class="flex flex-grow flex-col"></div>
          <div class="flex">
            <div v-if="style.back_to_site_link" class="flex flex-col justify-center">
              <a
                :href="isFabricatorForMondayDemo()
                  ? 'https://wallside.bm-touch.com/'
                  : style.back_to_site_link
                "
                class="bg-white rounded-full hover:bg-gray-200 my-auto mx-3 sm:py-2 sm:pr-6 sm:pl-5 sm:m-5 sm:font-medium"
              >
                <i class="fal fa-browser mr-3"></i> <span>Back to site</span>
              </a>
            </div>
          </div>
        </div>
      </header>
      <div class="flex flex-col h-full bg-gray-200 sm:bg-transparent">
        <div class="mx-auto w-full flex justify-around" style="max-width: 1600px">
          <div class="hidden sm:flex flex-col justify-around text-center m-10 mr-0">
            <div class="flex flex-col justify-between font-medium">
              <div v-if="isFabricatorForMondayDemo()" class="text-3xl xs:text-5xl leading-tight">
                Welcome To
              </div>
              <div v-else class="text-3xl xs:text-5xl leading-tight" v-html="headerText"></div>
              <div
                v-if="isFabricatorForMondayDemo()"
                class="text-3xl xs:text-5xl leading-tight mb-6"
              >
                Wallside Windows
              </div>
              <div v-else class="text-3xl xs:text-5xl leading-tight" v-html="subHeaderText"></div>
              <div
                v-if="!isFabricatorForMondayDemo()"
                class="text-lg font-normal mx-auto my-6"
                style="max-width: 500px"
              >
                {{ welcomeText }}
              </div>
              <div class="mx-auto">
                <img
                  v-if="isFabricatorForMondayDemo()"
                  src="/images/stddemofab-2.png"
                  class="m-auto w-full"
                  style="margin-left: -30px"
                />
                <img
                  v-else-if="hasCustomImage"
                  :src="customImage"
                  class="m-auto w-full"
                  style="margin-left: -30px"
                />
                <img
                  v-else
                  :src="$t('/images/login-image.png')"
                  class="m-auto w-full"
                  style="margin-left: -30px"
                />
              </div>
            </div>
          </div>
          <div class="login-form-wrapper">
            <transition name="fade-in" appear mode="out-in">
              <div v-if="forgot_password_open" key="forgot">
                <forgot-password-form @close="forgot_password_open = false"></forgot-password-form>
              </div>
              <div v-else key="login" class="my-auto">
                <div class="text-center mb-4 bg-white py-4 sm:hidden font-medium">
                  <div v-html="$t('Login-Main Title')"></div>
                  <div
                    class="text-base xs:text-lg font-normal mx-auto my-6"
                    style="max-width: 500px"
                  >
                    {{
                      $t(
                        'Login here to manage Touch Portal quotes, orders, products, news, resources and dealer network enquiries.',
                      )
                    }}
                  </div>
                  <div class="mx-auto">
                    <img
                      v-if="hasCustomImage"
                      :src="customImage"
                      class="m-auto"
                      style="max-height: 130px"
                    />
                    <img
                      v-else
                      :src="$t('/images/login-image.png')"
                      class="m-auto"
                      style="max-height: 130px"
                    />
                  </div>
                </div>

                <form class="login-form" @submit.prevent="login">
                  <div class="text-center mb-4 bg-white py-0 hidden sm:block">
                    <div class="text-4xl mb-2 font-medium">Welcome back!</div>
                    <div class="text-xl">Continue to your dashboard.</div>
                  </div>

                  <ErrorBox v-if="login_error" :message="login_error"></ErrorBox>

                  <div class="mb-4">
                    <label for="email" class="block w-full">Email address</label>
                    <input
                      id="email"
                      v-model="email"
                      type="email"
                      class="border border-solid border-gray-400 rounded block w-full p-2"
                      autocomplete="email"
                      autofocus
                    />
                  </div>

                  <div class="mb-4">
                    <label for="password" class="block w-full">Password</label>
                    <input
                      id="password"
                      v-model="password"
                      type="password"
                      autocomplete="current-password"
                      class="border border-solid border-gray-400 rounded block w-full p-2"
                    />
                  </div>

                  <div class="flex mb-4 text-right">
                    <div
                      class="ml-auto text-gray-600 text-xs hover:text-gray-800 cursor-pointer"
                      @click.prevent="forgot_password_open = true"
                    >
                      Forgot your password? Click here!
                    </div>
                  </div>

                  <button type="submit" class="btn-action btn-lg w-full">Login</button>

                  <div class="text-center p-3">
                    <router-link to="/register" class="text-blue-600 underline hover:text-blue-700 cursor-pointer">
                      Register for an Account
                    </router-link>
                  </div>

                  <div class="text-center mt-10">
                    <a
                      :href="store_url"
                      target="_blank"
                      class="inline-flex bg-white border border-solid border-gray-400 rounded p-1"
                    >
                      <div class="p-1">
                        <img src="../../assets/images/touch-logo.svg" width="75px" />
                      </div>
                      <div class="">
                        <template v-if="touch_portal_installation">
                          <img src="/images/touch-portal-icon.png" style="max-height: 27px" />
                        </template>
                      </div>
                      <div class="p-1 text-xs">By BM Group</div>
                    </a>
                  </div>
                </form>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';
import { useStyleStore } from '@/pinia/style';
import Logo from '@/components/shared/nav/Logo.vue';
import ForgotPasswordForm from './ForgotPassword.vue';
import ErrorBox from '@/components/shared/Error.vue';

export default {
  components: {
    'forgot-password-form': ForgotPasswordForm,
    Logo,
    ErrorBox
  },
  data() {
    return {
      email: '',
      password: '',
      endpoint: import.meta.env.VITE_APP_ENDPOINT_URL,
      imageBaseURL: import.meta.env.VITE_APP_IMAGE_BASE_URL,
      processingLevel: 2,
      login_error: false,
      forgot_password_open: false,
      loading: true,
    };
  },
  computed: {
    ...mapStores(useAuthStore, useStyleStore),
    headerText() {
      return (
        this.fabricator?.styling?.portalPageStyling?.loginHeader ?? this.$t('Login-Main Title')
      );
    },
    subHeaderText() {
      return this.styleStore.fabricator?.styling?.portalPageStyling?.loginHeader
        ? this.styleStore.fabricator?.styling?.portalPageStyling?.loginSubHeader
        : null;
    },
    welcomeText() {
      return (
        this.styleStore.fabricator?.styling?.portalPageStyling?.loginWelcomeText ??
        this.$t(
          'Login here to manage Touch Portal quotes, orders, products, news, resources and dealer network enquiries.',
        )
      );
    },
    hasCustomImage() {
      return this.customImage && this.customImage.length;
    },
    hasCustomSecondaryImage() {
      return this.customSecondaryImage && this.customSecondaryImage.length;
    },
    customSecondaryImage() {
      return this.styleStore.fabricator?.styling?.portalPageStyling?.loginSecondaryImageUri;
    },
    customImage() {
      return this.styleStore.fabricator?.styling?.portalPageStyling?.loginImageUri;
    },
  },
  async mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 500);
  },
  methods: {
    login() {
      if (this.email !== '' && this.password !== '') {
        this.authStore.attemptLogin({
          username: this.email,
          password: this.password,
        }).then(async (loggedIn) => {
          if (loggedIn) {
            this.login_error = false;
            this.setSentryUser();
            this.routerPush(this.$route.query.redirect ?? '/');
            return;
          }
          this.login_error = 'Unable to login, Please check your username / password and try again';
        });
      } else {
        this.login_error = 'Email address and password required';
      }
    },
    isFabricatorForMondayDemo() {
      return window.location.hostname === 'stddemofab.portal.bm-touch.co.uk';
    },
  },
};
</script>
