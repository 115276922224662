<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Manage News', '/manage-news'],
        ]"
      />
    </template>
    <template #header_page_title>
      <div class="flex items-center justify-center gap-2">
        Manage News
        <help-point freshdesk-article-id="101000455645" />
      </div>
    </template>
    <Teleport defer to="#portal_search">
      <SearchBox class="flex" placeholder="Search news..." />
    </Teleport>
    <template #header_page_actions>
    </template>
    <transition appear name="fade-in" mode="out-in">
      <loading v-if="loading || saving_post" :loading="loading || saving_post" :label="saving_post ? 'Saving' : 'Loading'" style="flex: 1 1 0"></loading>
      <main v-else class="bg-gray-200 w-full h-full overflow-y-auto overflow-x-hidden scrolling-touch p-5 xs:p-10 relative">
        <div class="flex flex-col-reverse flex-col 2xs:flex-row items-center gap-5 mb-5">
          <div class="relative w-full 2xs:w-auto bg-white" tabindex="3" @focusout="category_dropdown_open = false">
            <div class="border border-l-0 border-r-0 border-b-0 border-white w-full" />
            <div
              class="p-3 border flex gap-5 cursor-pointer"
              @click="category_dropdown_open = !category_dropdown_open"
            >
              <div v-if="current_category_name" class="text-gray-600">
                Category: <strong>{{ current_category_name }}</strong>
              </div>
              <div v-else class="whitespace-nowrap text-gray-600">Choose Category</div>
              <div class="ml-auto">
                <i v-if="category_dropdown_open" class="fa fa-caret-up" />
                <i v-else class="fa fa-caret-down" />
              </div>
            </div>
            <div
              v-if="category_dropdown_open"
              class="absolute bg-gray-200 w-full md:w-64 border border-t-0 z-10 text-xs sm:text-sm"
            >
              <div
                v-for="category in categories.filter((x) => !x.isEmpty)"
                :key="category.id"
                class="flex cursor-pointer bg-white m-2 rounded-lg cursor-pointer hover:bg-brand-primary hover:text-white"
                :class="{ 'bg-brand-primary text-white': currentCategory === category.id }"
                @click="setCategory(category.id), (category_dropdown_open = false)"
              >
                <div v-if="currentCategory === category.id" class="p-3">
                  <i class="fal fa-check" />
                </div>
                <div class="p-3">
                  <label :title="category.name"> {{ category.name }} </label>
                </div>
              </div>
            </div>
          </div>
          <div class="mx-auto 2xs:mr-0 flex gap-1 items-center justify-center">
            <router-link to="/manage-news/category" class="btn-action">
              Categories
            </router-link>
            <a class="btn-action" @click="add_news_open = !add_news_open">New Post</a>
            <div class="relative">
              <a
                class="btn-action"
                @click="social_media_feeds_settings_open = !social_media_feeds_settings_open"
              >
                Social Feeds
                <i v-if="!social_media_feeds_settings_open" class="fal fa-chevron-down"></i>
                <i v-if="social_media_feeds_settings_open" class="fal fa-chevron-up"></i>
              </a>
              <div v-if="social_media_feeds_settings_open" class="bg-white absolute border w-full">
                <div
                  class="border-b px-3 py-1 cursor-pointer hover:bg-gray-300"
                  @click="add_twitter_handle_open = true"
                >
                  Twitter
                </div>
                <div
                  v-if="isDemo()"
                  class="border-b px-3 py-1 cursor-pointer hover:bg-gray-300"
                  @click="add_instagram_handle_open = true"
                >
                  Instagram
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-show="add_news_open"
          key="1"
          class="bg-white p-6 xs:p-10 border mb-10"
          data-index="1"
        >
          <div class="2xs:flex mb-5">
            <div class="flex-1 flex 2xs:mr-5 mb-5 2xs:mb-0">
              <div class="flex flex-col justify-around whitespace-nowrap mr-3 w-16 2xs:w-auto">
                Title
              </div>
              <div class="flex-grow">
                <input v-model="new_post.title" type="text" class="form-control" />
              </div>
            </div>
            <div class="flex-1 flex 2xs:ml-5">
              <div class="flex flex-col justify-around whitespace-nowrap mr-3 w-16 2xs:w-auto">
                Category
              </div>
              <div class="flex-grow">
                <select v-model="new_post.newsCategoryId" class="form-control">
                  <option
                    v-for="category in categories"
                    :key="category.id"
                    :value="category.id"
                    :selected="new_post.newsCategoryId === category.id"
                  >
                    {{ category.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div>
            <div class="mb-2">Summary</div>
            <TextEditor id="summary" v-model="new_post.summary" />
          </div>
          <div class="mt-6">
            <div class="mb-2">Content</div>
            <TextEditor id="paragraph" v-model="new_post.paragraph" />
          </div>
          <div class="mt-5">
            <Dropzone @add-file="new_post.image = $event" @remove-file="new_post.image = undefined"></Dropzone>
          </div>
          <div class="text-gray-500 py-3">
            <div>4:3 Ratio Required: E.g. 400 by 300 or 1200 by 900 etc.</div>
            <div>Max File Size: 1MB</div>
          </div>
          <div class="mt-5 text-right">
            <a class="btn btn-lg w-32 mr-1" @click.prevent="add_news_open = false"> Cancel </a>
            <a class="btn-action btn-lg w-32" @click.prevent="doAddNewsItem()"> Save </a>
          </div>
          <transition name="fade-in">
            <div v-if="errors.length > 0" class="flex flex-col justify-around mt-3">
              <div
                v-for="(error, index) in errors"
                :key="index"
                class="text-red-600"
                :class="{ 'mt-1': index > 0 }"
              >
                {{ error }}
              </div>
            </div>
          </transition>
        </div>
        <div>
          <animation-staggered-fade :start-delay="0" appear class="grid xs:grid-cols-2 lg:grid-cols-3 gap-5">
            <article
              v-for="(news_post, index) in filteredNews"
              :key="index"
              class="w-full flex flex-col hover-zoom"
              :data-index="index"
            >
              <div class="flex flex-col flex-1 bg-white border p-3">
                <div v-if="news_post.imageUrl" class="flex flex-col justify-around flex-grow">
                  <api-img :src="news_post.imageUrl" class="w-full" />
                </div>
                <div class="flex flex-col mt-auto">
                  <div class="font-medium border-b pb-3 my-3">
                    {{ news_post.title }}
                  </div>
                  <div class="flex justify-between">
                    <div class="text-sm">
                      {{ formatDate(news_post.publishAt) }}
                    </div>
                    <div>
                      <router-link :to="news_post.edit_url" class="cursor-pointer pr-6">
                        <i class="fal fa-pencil"></i>
                      </router-link>
                      <a
                        class="cursor-pointer text-red-600"
                        @click.prevent="deleteNewsItem(news_post.id)"
                      >
                        <i class="fal fa-times"></i>
                      </a>
                    </div>
                  </div>
                  <div v-if="!news_post.imageUrl" class="mt-6" v-html="news_post.summary"></div>
                </div>
              </div>
            </article>
          </animation-staggered-fade>
          <div v-if="show_load_more" class="text-center">
            <a class="btn bg-white" @click="current_page += 1"> Load More </a>
          </div>
        </div>
      </main>
    </transition>
    <!--      <add-twitter-handle @close="add_twitter_handle_open = false" v-if="add_twitter_handle_open"></add-twitter-handle>-->
    <add-instagram-handle
      v-if="add_instagram_handle_open"
      @close="add_instagram_handle_open = false"
    ></add-instagram-handle>
  </touch-layout>
</template>

<script>

import { formatDate } from '@/composables/date';
import HelpPoint from '@/components/shared/HelpPoint.vue';
import AddInstagramHandle from '@/components/business/manageNews/AddInstagramHandle.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import SearchBox from '@/components/shared/SearchBox.vue';
import Dropzone from '@/components/shared/Dropzone.vue';
import TextEditor from '@/components/shared/TextEditor.vue';

export default {
  components: {
    'add-instagram-handle': AddInstagramHandle,
    HelpPoint,
    Breadcrumbs,
    SearchBox,
    Dropzone,
    TextEditor
  },
  setup() {
    return { formatDate }
  },
  data() {
    return {
      limit: 6,
      new_post: {},
      news: [],
      categories: [],
      current_page: 1,
      show_load_more: true,
      total_items: 0,
      errors: [],
      add_news_open: false,
      saving_post: false,
      loading: true,
      social_media_feeds_settings_open: false,
      add_twitter_handle_open: false,
      add_instagram_handle_open: false,
      category_dropdown_open: false
    };
  },
  computed: {
    filteredNews() {
      const search = this.$route.query.search || '';
      return this.news.filter((newsArticle) =>
        `${newsArticle.title} ${newsArticle.paragraph} ${newsArticle.summary}`
          .toLowerCase()
          .includes(search.toLowerCase()),
      );
    },
    current_category_name() {
      // eslint-disable-next-line eqeqeq
      const currentCategory = this.categories.find(
        (category) => category.id == this.currentCategory,
      );
      if (currentCategory !== undefined) {
        return currentCategory.name;
      }
      return '';
    },
    currentCategory() {
      return this.$route.query.category;
    },
  },
  watch: {
    current_page(page) {
      const offset = this.limit * (page - 1);
      this.loadFeed(offset);
    },
  },
  async mounted() {
    this.categories = await window.touch.getNewsCategories();
    await this.loadFeed();
    if (this.$route.query.instagram_updated === 'yes') {
      this.$router
        .replace({ query: { ...this.$route.query, instagram_updated: undefined } })
        .catch(() => { });
      this.alertBox().fire({
        title: 'Instagram updated',
        icon: 'success',
      });
    }
  },
  methods: {
    isDemo() {
      return window.location.hostname.split('.')[0] === 'demo';
    },
    async loadFeed(offset = 0) {
      const loadedNews = await window.touch.latestNews({
        limit: this.limit,
        offset,
      });
      if (offset > 0) {
        for (let i = 0; i < loadedNews.news_items.length; i += 1) {
          this.news.push(loadedNews.news_items[i]);
        }
      } else {
        this.news = loadedNews.news_items;
      }
      this.total_items = loadedNews.total_items;
      this.show_load_more = this.total_items > this.news.length;
      this.loading = false;
    },
    fileRemovedFromDropZone() {
      this.new_post.image = null;
    },
    async doAddNewsItem() {
      this.saving_post = true;
      this.errors = [];

      if (!this.new_post.title) {
        this.errors.push('Please choose a Title for this post');
      }

      if (!this.new_post.newsCategoryId) {
        this.errors.push('Please choose a Category');
      }

      if (!this.new_post.summary) {
        this.errors.push('Please write a summary for this post');
      }

      if (!this.new_post.paragraph) {
        this.errors.push('Post content cannot be blank');
      }

      if (!this.new_post.image) {
        this.errors.push('Please choose an image');
      }

      if (this.errors.length > 0) {
        this.saving_post = false;
        return;
      }

      await window.touch.addNewsItem(this.new_post).then(async () => {
        await this.loadFeed();
        this.new_post = {};
        this.saving_post = false;
        this.add_news_open = false
      });
    },
    async deleteNewsItem(id) {
      this.alertBox()
        .fire({
          title: 'Are you sure?',
          text: 'Are you sure you want to delete this news post?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        })
        .then(async (result) => {
          if (result.value) {
            await window.touch.deleteNewsItems([id]).then(() => {
              this.loadFeed();
            });
          }
        });
    },
    setCategory(category) {
      this.$router
        .push({ query: { ...this.$route.query, category: category || undefined } })
        .catch(() => { });
    },
  },
};
</script>
