import i18n from '@/i18n';

import { defineStore } from 'pinia';
import { useAuthStore } from '@/pinia/auth';
import { useBrandedStore } from '@/pinia/branded';
import { useBasketStore } from '@/pinia/basket';
import { useCustomerStore } from '@/pinia/customer';
import { isValidDate, addMinutesToDate } from '@/composables/date';

export const useTouchStore = defineStore('touch', {
  persist: {
    omit: ['pageProductStoredDesignsCache']
  },
  state: () => ({
    processingLevels: [],
    pageProductStoredDesignsCache: {
      cachedPages: {},
      cacheExpiry: undefined,
      cacheLifetimeMins: 30,
    },
    isDealerNetworkPartnerOnly: false,
  }),
  actions: {
    setDealerNetworkPartnerOnly(isDealerNetworkPartnerOnly) {
      this.isDealerNetworkPartnerOnly = isDealerNetworkPartnerOnly;
    },
    SET_PROCESSING_LEVELS(processingLevels) {
      this.processingLevels = processingLevels;
    },
    ADD_TO_PAGE_STORED_DESIGNS_CACHE({ itemKey, item }) {
      if (!Object.keys(this.pageProductStoredDesignsCache.cachedPages).length) {
        // if there are no pages cached lets set a new cache expiry time
        this.pageProductStoredDesignsCache.cacheExpiry = addMinutesToDate(
          new Date(),
          this.pageProductStoredDesignsCache.cacheLifetimeMins,
        );
      }
      this.pageProductStoredDesignsCache.cachedPages[itemKey] = item;
    },
    CLEAR_STORED_DESIGNS_CACHE() {
      this.pageProductStoredDesignsCache.cachedPages = {};
    },
    testAcceptChanges(sandboxId) {
      return window.touch.testAcceptChanges(sandboxId);
    },
    customerGetCompanySettings() {
      return window.touch.customerGetCompanySettings();
    },
    customerUpdateCompanySettings({ showSizes, showDesignNames }) {
      return window.touch.customerUpdateCompanySettings(showSizes, showDesignNames);
    },
    sendTradeCustomerQuoteReadyEmail({ contractId, jobKey }) {
      return window.touch.contractSendTradeCustomerQuoteReadyEmail(contractId, jobKey);
    },
    staffUpdateDiscountCategories(discount) {
      return window.touch.staffUpdateDiscountCategories([discount]);
    },
    staffListDiscountCategories() {
      return window.touch.staffListDiscountCategories();
    },
    async loadProcessingLevels() {
      if (this.processingLevels.length === 0) {
        this.SET_PROCESSING_LEVELS(await window.touch.customerGetProcessingLevels())
      }
    },
    connectGetConnectSubscriptions() {
      return window.touch.connectGetConnectSubscriptions();
    },
    connectGetConnectSources() {
      return window.touch.connectGetConnectSources();
    },
    getDatasetIds() {
      return window.touch.commonGetDatasets();
    },
    async addConnectSource(type) {
      const datasetIds = await this.getDatasetIds()
      return window.touch.connectUpdateConnectSource(undefined, type, datasetIds[0].id);
    },
    async UpdateConnectCustomerProductLinks(link) {
      const customerStore = useCustomerStore()
      return window.touch.connectUpdateConnectCustomerProductLinks(
        customerStore.customer.datasetIds[0],
        link,
      );
    },
    authAuthenticateConnectCustomer({ username, password, accountId }) {
      return window.touch.authAuthenticateConnectCustomer(username, password, accountId);
    },
    customerUpdateProductDefault({ id, productId, optionItemId, datasetId, customerId }) {
      return window.touch.customerUpdateProductDefault(
        id,
        productId,
        optionItemId,
        datasetId,
        customerId,
      );
    },
    customerGetProductDefaultDetails({ id, productId, datasetId, customerId }) {
      return window.touch.customerGetProductDefaultDetails(id, productId, datasetId, customerId);
    },
    customerGetProductDefaultsWithDetails({ productId, datasetId, customerId }) {
      return window.touch.customerGetProductDefaultsWithDetails(productId, datasetId, customerId);
    },
    customerGetProductDefaults({ productId, datasetId, customerId }) {
      return window.touch.customerGetProductDefaults(productId, datasetId, customerId);
    },
    customerGetProductsWithDefaults(datasetId) {
      return window.touch.customerGetProductsWithDefaults(datasetId);
    },
    staffUpdateAlternativePriceData({ CustomerId, DatasetId, Anomalies, customerName }) {
      return window.touch.staffUpdateAlternativePriceData(
        CustomerId,
        DatasetId,
        Anomalies,
        customerName,
      );
    },
    staffGetFinancialGroups({ CustomerId, DatasetId }) {
      return window.touch.staffGetFinancialGroups(CustomerId, DatasetId);
    },
    staffGetAlternativePriceData({ CustomerId, DatasetId, GroupId, Description, Offset, Limit }) {
      return window.touch.staffGetAlternativePriceData(
        CustomerId,
        DatasetId,
        GroupId,
        Description,
        Offset,
        Limit,
      );
    },
    async staffGetLatestExchangeRates() {
      return window.touch.staffGetLatestExchangeRates();
    },
    async staffUpdateCurrency(param) {
      return window.touch.staffUpdateCurrency(param);
    },
    async commonGetCurrencies() {
      return window.touch.commonGetCurrencies();
    },
    async processingGetAvailableProductsToSelect({ typeId, groupId, sandbox }) {
      const basketStore = useBasketStore()
      return window.touch.processingGetAvailableProductsToSelect(
        typeId,
        groupId,
        basketStore.customerId,
        sandbox
      );
    },
    async processingGetAvailableProductSelectionGroups(customerId) {
      return window.touch.processingGetAvailableProductSelectionGroups(customerId);
    },
    async processingGetAvailableProductSelectionTypes({ customerId, groupId }) {
      return window.touch.processingGetAvailableProductSelectionTypes(customerId, groupId);
    },
    async saveProductGroup({ title, description, ImageBlob }) {
      return window.touch.staffUpdateProductSelectionGroups([{ title, description, ImageBlob }]);
    },
    async updateProductGroup({ id, title, description, ImageBlob, listOrder }) {
      return window.touch.staffUpdateProductSelectionGroups([
        {
          id,
          title,
          description,
          ImageBlob,
          listOrder,
        },
      ]);
    },
    async updateProductType({ id, title, description, ImageBlob, listOrder }) {
      return window.touch.staffUpdateProductSelectionTypes([
        {
          id,
          title,
          description,
          ImageBlob,
          listOrder,
        },
      ]);
    },
    async saveProductType({ title, description, ImageBlob }) {
      return window.touch.staffUpdateProductSelectionTypes([{ title, description, ImageBlob }]);
    },
    async deleteProductType(groupId) {
      return window.touch.staffUpdateProductSelectionTypes([{ Id: groupId, Delete: true }]);
    },
    async setupUpdateProducts(params) {
      return window.touch.setupUpdateProducts([params]);
    },
    async deleteProductGroup(groupId) {
      return window.touch.staffUpdateProductSelectionGroups([{ Id: groupId, Delete: true }]);
    },
    async AddExtraItem(params) {
      return window.touch.customerUpdateExtraItems([
        {
          GroupId: params.category,
          Description: params.name,
          UnitOfMeasure: params.unit_of_measure,
          Price: params.price,
          FittingItem: params.isFittingCharge,
          UseSizesFromProduct: params.useProductMeasurements,
          ImageData: params.ImageData,
        },
      ]);
    },
    async AddExtraItemOption(params) {
      return window.touch.customerUpdateExtraItemOptions([
        {
          ItemId: params.itemId,
          Description: params.description,
          Value: params.value,
        },
      ]);
    },
    async UpdateExtraItemOptions(params) {
      return window.touch.customerUpdateExtraItemOptions(params);
    },
    async UpdateExtraItemOption(params) {
      return window.touch.customerUpdateExtraItemOptions([
        {
          id: params.id,
          ItemId: params.itemId,
          Description: params.description,
          Price: params.value,
          Delete: params.deleted,
        },
      ]);
    },
    async updateExtraItem(params) {
      return window.touch.customerUpdateExtraItems([
        {
          Id: params.id,
          GroupId: params.category,
          Description: params.name,
          UnitOfMeasure: params.unit_of_measure,
          Value: params.price,
          FittingItem: params.isFittingCharge,
          UseSizesFromProduct: params.useProductMeasurements,
          ImageData: params.ImageData,
        },
      ]);
    },
    async DeleteExtraItem(id) {
      return window.touch.customerUpdateExtraItems([
        {
          Id: id,
          Delete: true,
        },
      ]);
    },
    async AddExtraItemGroup(name) {
      return window.touch.customerUpdateExtraItemGroups([
        {
          Description: name,
        },
      ]);
    },
    async UpdateExtraItemGroups(params) {
      return window.touch.customerUpdateExtraItemGroups(params);
    },
    async deleteExtraItemGroup(groupId) {
      return window.touch.customerUpdateExtraItemGroups([
        {
          Id: groupId,
          Delete: true,
        },
      ]);
    },
    async customerGetAllExtraItems() {
      return window.touch.customerGetAllExtraItems();
    },
    async vendorUpdateThisGridAnomaly(params) {
      const customerStore = useCustomerStore()
      return window.touch.vendorUpdateThisGridAnomaly(
        customerStore.customer.datasetIds[0],
        params.Id,
        params.FinancialGridConfigurationId,
        params.OverrideCells,
      );
    },
    async vendorGetThisGridAnomaly(params) {
      const customerStore = useCustomerStore()
      return window.touch.vendorGetThisGridAnomaly(
        params.id,
        params.customerId,
        params.datasetId ?? customerStore.customer.datasetIds[0],
      );
    },
    async staffUpdateThisListAnomaly(params) {
      const customerStore = useCustomerStore()
      return window.touch.staffUpdateThisListAnomaly(
        params.DatasetId ?? customerStore.customer?.datasetIds[0],
        params.Id,
        params.FinancialGridConfigurationId,
        params.OverrideCells,
        params.customerId,
      );
    },
    async staffGetThisListAnomaly({ id, customerId, datasetId }) {
      return window.touch.staffGetThisListAnomaly(id, customerId, datasetId);
    },
    async customerGetFinancialGroups() {
      const customerStore = useCustomerStore()
      return window.touch.customerGetFinancialGroups(
        customerStore.customer.datasetIds[0],
      );
    },
    async staffUpdateOrganisationDetails({ name, email, phone, web, file }) {
      return window.touch.staffUpdateOrganisationDetails(name, email, phone, web, file);
    },
    async assignLead({ contractId, customerIds }) {
      return window.touch.staffAssignEnquiry(contractId, customerIds);
    },
    async loadProductsForCustomer(customerId) {
      return window.touch.customerGetAllProductsWithOverrides(customerId);
    },
    async updateProductsForCustomer({ customerId, products }) {
      return window.touch.customerUpdateProductOverrides(products, customerId);
    },
    async getMyStoreLinkToken() {
      return window.touch.authGetMyStoreLinkToken();
    },
    async productSelectionStage(selectedStages = []) {
      return window.touch.processingProductSelectionStage(selectedStages);
    },
    async getProducts() {
      return window.touch.getProducts();
    },
    async saveJobTotalOverride({ contractId, jobId, overridePrice, fitPrice }) {
      return window.touch.contractUpdateJobRequote({
        contractId,
        jobKey: jobId,
        overridePrice,
        fitPrice,
        requotePriceBeforeTax: Number(overridePrice),
      });
    },
    async saveItemOverride({ contractId, jobId, overridePrices }) {
      const basketStore = useBasketStore()
      const response = await window.touch.processingReloadContract(
        basketStore.customerId,
        contractId,
      );

      if (!response.status) {
        throw new Error(response.message);
      }

      return window.touch.processingUpdateJobLineItemRequote(
        contractId,
        jobId,
        this.processingLevel,
        overridePrices.map((item) => ({
          itemKey: item.itemKey,
          price: item.price,
          fitPrice: item.fitPrice,
        })),
      );
    },
    async saveContract({ contractId }) {
      const basketStore = useBasketStore()
      return window.touch.processingSaveContract(basketStore.customerId, contractId);
    },

    async dashboardEnquiries(vendorMode = false) {
      let typeLabel = 'Branded Mode Enquiry';
      if (vendorMode) {
        typeLabel = 'Website Designer Enquiry';
      }

      const brandedModeEnquiries = (
        await window.touch.contractSummary(
          window.touch.CONTRACT_TYPE_ENQUIRY,
          5,
          0,
          undefined,
          this.enums.EnquiryStatus.UNASSIGNED,
          undefined,
          window.enum.enquirySource.BRANDED_MODE,
        )
      ).contracts.map((quote) => ({
        ...quote,
        url: `/enquiry/${quote.id}/1`,
        enquirySource: window.enum.enquirySource.BRANDED_MODE,
        type: typeLabel,
      }));

      return brandedModeEnquiries.sort((a, b) => b.id - a.id);
    },

    async dashboardNetworkEnquiries() {
      const dealerNetworkEnquiries = (
        await window.touch.contractList(
          window.touch.CONTRACT_TYPE_ENQUIRY,
          5,
          0,
          undefined,
          this.enums.DealerStatus.AWAITINGACTION,
          undefined,
          window.enum.enquirySource.DEALER_NETWORK,
        )
      ).contracts.map((quote) => ({
        ...quote,
        url: `/enquiry/${quote.id}/1`,
        enquirySource: window.enum.enquirySource.DEALER_NETWORK,
        type: 'Dealer Network Enquiry',
      }));

      return dealerNetworkEnquiries.sort((a, b) => b.id - a.id);
    },
    async dashboardQuotes() {
      return Promise.all(
        (await window.touch.contractList(window.touch.CONTRACT_TYPE_QUOTE, 5, 0)).contracts.map(
          async (quote) => ({
            ...quote,
            jobs: (
              await window.touch.contractListFirstJobMinimal(
                quote.id,
                this.processingLevel,
              )
            ).map((job) => ({
              ...job,
              currency: quote.currencyCode,
              customerCurrency: quote.customerCurrencyCode,
            })),
            url: quote.jobs.length === 1 ? `/quote/${quote.id}/1` : `/quote/${quote.id}`,
          }),
        ),
      );
    },
    async dashboardOrders() {
      return (
        await window.touch.contractList(window.touch.CONTRACT_TYPE_ORDER, 5, 0)
      ).contracts.map((order) => ({
        ...order,
        url: `/order/${order.id}`,
      }));
    },
    async loadQuotes(options) {
      const response = await window.touch.contractSummary({
        type: window.touch.CONTRACT_TYPE_QUOTE,
        limit: options.limit,
        offset: options.offset,
        searchForReference: options.search,
        statusId: options.statusId,
        onlyShowLocked: options.onlyShowLocked,
        IncludeItems: true,
      });
      const quotes = response.contracts.map((quote) => ({
        ...quote,
        url: quote.jobs.length === 1 ? `/quote/${quote.id}/1` : `/quote/${quote.id}`,
      }));
      return {
        quotes,
        total_items: response.total,
      };
    },
    async loadActiveQuotes(options) {
      return this.loadQuotes(options)
    },
    async staffListCustomers({ limit, offset, query, contractId, dealerNetworkPartnerOnly, customerStatus }) {
      return window.touch.staffListCustomers(
        limit,
        offset,
        query,
        contractId ? Number(contractId) : undefined,
        dealerNetworkPartnerOnly || false,
        customerStatus,
      );
    },
    async loadDealerNetworkList(options) {
      const response = await window.touch.contractSummary({
        type: window.touch.CONTRACT_TYPE_ENQUIRY,
        limit: options.limit,
        offset: options.offset,
        searchForReference: options.search,
        enquirySource: window.enum.enquirySource.DEALER_NETWORK,
        statusId: options.statusId,
        fromDate: options.fromDate,
        toDate: options.toDate,
      });
      const orders = response.contracts.map((order) => ({
        ...order,
        isEnquiryOverdue:
          order.expired.charAt(2) !== '0'
            ? [...new Set(order.expired.split('/'))].length === 1
            : false,
        // the format of the order refused is x/y
        // if y is zero the contract hasn't been assigned yet so can't have been refused
        // if it is not 0 then we split on the /, change the resulting array into a set thus eliminating duplicates, and then back into an array.
        // if the length of the array is 1 then x and y were the same, thus the contract has been completely refused.
        // if the length is greater than one then x and y were different, thus we know at least one person has not yet refused the order.
        isEnquiryCompletelyRefused:
          order.refused.charAt(2) !== '0'
            ? [...new Set(order.refused.split('/'))].length === 1
            : false,
      }));
      return {
        orders,
        total_items: response.total,
      };
    },
    async loadDealerNetworkAssignedList(options) {
      const response = await window.touch.contractSummary({
        type: window.touch.CONTRACT_TYPE_ENQUIRY,
        limit: options.limit,
        offset: options.offset,
        searchForReference: options.search,
        enquirySource: window.enum.enquirySource.DEALER_NETWORK,
        statusId: [2, 3],
        fromDate: options.fromDate,
        toDate: options.toDate,
      });
      return {
        orders: response.contracts,
        assignees: response.assignees,
        total_items: response.total,
      };
    },
    async loadOrders(options) {
      const response = await window.touch.contractSummary({
        type: window.touch.CONTRACT_TYPE_ORDER,
        limit: options.limit,
        offset: options.offset,
        searchForReference: options.search,
        statusId: options.orderStatus,
        onlyShowLocked: options.onlyShowLocked,
        customerId: options.customerId,
        branchCompanyId: options.branchCompanyId,
      });
      const orders = response.contracts.map((order) => ({
        ...order,
        url: `/order/${order.id}`,
      }));
      return {
        orders,
        total_items: response.total,
        assignees: response.assignees,
      };
    },
    async loadQuotesForAdmin(options) {
      const response = await window.touch.contractSummary({
        type: window.touch.CONTRACT_TYPE_QUOTE,
        limit: options.limit,
        offset: options.offset,
        searchForReference: options.search,
        statusId: options.orderStatus,
        customerId: options.customerId,
        customQuoteStageId: options.customQuoteStageId,
        branchCompanyId: options.branchCompanyId,
      });
      const orders = response.contracts.map((order) => ({
        ...order,
        url: `/quote/${order.id}`,
      }));
      return {
        orders,
        total_items: response.total,
        assignees: response.assignees,
      };
    },
    async updateConsumer(params) {
      const { contractId, customerId, consumer } = params;
      const basketStore = useBasketStore()
      await window.touch.contractUpdateConsumer({
        customerId: customerId || basketStore.customerId,
        contractId,
        firstName: consumer.firstName,
        lastName: consumer.lastName,
        email: consumer.email,
        mobile: consumer.mobile,
        addressLine1: consumer.addressLine1,
        addressLine2: consumer.addressLine2,
        addressLine3: consumer.addressLine3,
        addressTown: consumer.addressTown,
        addressCounty: consumer.addressCounty,
        addressCode: consumer.addressCode,
        addressIso: consumer.addressIso,
      });
    },
    async loadEnquiries(options) {
      const response = await window.touch.contractSummary({
        type: window.touch.CONTRACT_TYPE_ENQUIRY,
        enquirySource: options.enquirySource,
        limit: options.limit,
        offset: options.offset,
        searchForReference: options.search,
        statusId: options.statusId,
        onlyShowLocked: options.onlyShowLocked,
      });

      const enquiries = response.contracts.map((enquiry) => ({
        ...enquiry,
        url: `/enquiry/${enquiry.id}/1`,
      }));

      return {
        enquiries,
        total_items: response.total,
      };
    },
    async unlockQuote({ contractId }) {
      return window.touch.processingRemoveContract(contractId);
    },
    async get3DModel({ contractId, itemKey, jobKey, customerId }) {
      return window.touch.processingGet3DModel(contractId, itemKey, jobKey, customerId);
    },
    async loadEnquiryNotes({ contractId, jobId }) {
      return window.touch.contractGetNotes({
        ContractId: contractId,
        JobKey: jobId,
        NoteType: window.enum.noteType.CUSTOMER,
      });
    },
    async loadNotes({ contractId, jobId }) {
      try {
        const notes = await window.touch.contractGetNotes({
          ContractId: contractId,
          JobKey: jobId,
        });

        if (notes.length === 0) {
          return null;
        }

        return notes;
      } catch (e) {
        return null;
      }
    },
    async loadNote({ contractId, jobId, noteType }) {
      try {
        const notes = (
          await window.touch.contractGetNotes({
            ContractId: contractId,
            JobKey: jobId,
          })
        ).filter((note) => note.noteType === noteType);

        if (notes.length === 0) {
          return null;
        }

        return notes[0];
      } catch (e) {
        return null;
      }
    },
    async updateNote({ contractId, jobId, noteType, noteId, text, deleteNote = false }) {
      return window.touch.contractUpdateNote({
        ContractId: contractId,
        JobKey: jobId,
        NoteType: noteType,
        NoteId: noteId,
        Text: text,
        Delete: deleteNote,
      });
    },
    async createNote({ contractId, jobId, noteType, text }) {
      return window.touch.contractUpdateNote({
        ContractId: contractId,
        JobKey: jobId,
        NoteType: noteType,
        Text: text,
      });
    },
    async loadQuote({ customerId, quoteId, jobId }) {
      return window.touch.contractDetails(
        customerId,
        quoteId,
        jobId
      );
    },
    async deleteJob({ contractId, jobId }) {
      const basketStore = useBasketStore()
      await window.touch.processingReloadContract(basketStore.customerId, contractId);
      await window.touch.processingDeleteJob(contractId, jobId);
      await window.touch.processingSaveContract(basketStore.customerId, contractId);
    },
    async copyJob({ contractId, jobId, reference }) {
      const basketStore = useBasketStore()
      await window.touch.processingReloadContract(basketStore.customerId, contractId);
      const newJobKey = await window.touch.processingCopyJob(contractId, jobId);

      await window.touch.processingUpdateJob(contractId, newJobKey, {
        Reference: reference,
      });

      await window.touch.processingSaveContract(basketStore.customerId, contractId);

      return newJobKey;
    },
    async loadOrder({ customerId, id }) {
      return window.touch.contractDetails(
        customerId,
        id
      );
    },
    async checkSeats(id = null) {
      return window.touch.customerCheckSeats(id);
    },
    async loadJobDelivery({ contractId, jobId }) {
      const basketStore = useBasketStore()
      return window.touch.processingJobDelivery(
        basketStore.customerId,
        contractId,
        jobId,
      );
    },
    async loadMyAddresses() {
      const customer = await window.touch.customerGet();

      return customer.addresses;
    },
    async setBrandedContract(contractId) {
      const basketStore = useBasketStore()
      basketStore.setContract(
        {
          contractId,
          jobKey: 1, // This is always 1 for branded mode enquiries
          isEnquiry: true,
        }
      );
    },
    async startPublicSession({ slug, returnUrl }) {
      const auth = await window.touch.authVendorGuest(slug, returnUrl);
      const authStore = useAuthStore()
      const brandedStore = useBrandedStore()
      const basketStore = useBasketStore()

      if (auth.status === 400) {
        throw new Error('No Designer Found');
      }

      await authStore.setVendorLogin({
          isDemo: auth.data.isDemo,
          isDealerNetwork: auth.data.isDealerMode,
          property: 'public',
          token: auth.data.token,
          tokenExpiry: auth.data.expiresAt,
        }
      );

      basketStore.setContract(
        {
          contractId: auth.data.contractId,
          jobKey: 1, // This is always 1 for branded mode enquiries
          isEnquiry: true,
        }
      );

      basketStore.setButtonName('Enquiry');


      brandedStore.setSlugAndKey({ slug })

      return auth;
    },
    async getEnquiryStatuses() {
      return window.touch.getEnquiryStatuses();
    },
    async updateQuoteStatus({ contractId, status }) {
      const basketStore = useBasketStore()
      await window.touch.processingReloadContract(basketStore.customerId, contractId);
      await window.touch.updateQuoteStatus(contractId, status);
      await window.touch.processingSaveContract(basketStore.customerId, contractId);
    },
    async updateEnquiryStatus({ contractId, status }) {
      const basketStore = useBasketStore()
      await window.touch.processingReloadContract(basketStore.customerId, contractId);
      await window.touch.updateEnquiryStatus(contractId, status);
      await window.touch.processingSaveContract(basketStore.customerId, contractId);
    },
    async updateDealerEnquiryStatus({ dealerNetworkAssignmentId, status, refusalReason }) {
      await window.touch.updateDealerEnquiryStatus(
        dealerNetworkAssignmentId,
        status,
        refusalReason,
      );
    },
    async getDocsToAccept() {
      return window.touch.getDocsToAccept();
    },
    async updateJobRequote({ contractId, jobKey, emailSubject, emailBodyText }) {
      return window.touch.contractUpdateJobRequote({contractId, jobKey, emailSubject, emailBodyText});
    },
    async sendRequoteEmailToConsumer({ contractId, jobKey }) {
      await window.touch.contractSendRequoteEmailToConsumer(contractId, jobKey);
    },

    async getProductStoredDesignsForPage({ customerId, processingLevel, productId, page = 1, perPage = 50, squaresQty = undefined }) {
      /*
       * this has some primitive caching functionality within it
       * if this approach is copied, I'd suggest we refactor and setup a store specific for caches
       * this would handle cache functionality (clearing, cache expiry, etc)
       */
      const cacheKey = `${customerId}${processingLevel}${productId}${undefined}${undefined}${false}${perPage}${(page - 1) * perPage}${squaresQty}`;

      if (
        isValidDate(this.pageProductStoredDesignsCache.cacheExpiry) &&
        this.pageProductStoredDesignsCache.cacheExpiry < Date.now()
      ) {
        // if the cache expiry is in the past then we need to clear out the cache
        this.CLEAR_STORED_DESIGNS_CACHE()
      } else if (
        Object.keys(this.pageProductStoredDesignsCache.cachedPages).includes(cacheKey)
      ) {
        // we check to see if there is a product page in the cache that matches the page we're after
        return this.pageProductStoredDesignsCache.cachedPages[cacheKey];
      }

      return window.touch
        .productStoredDesigns(
          customerId,
          processingLevel,
          productId,
          undefined,
          undefined,
          false,
          perPage,
          (page - 1) * perPage,
          squaresQty,
        )
        .then((res) => {
          this.ADD_TO_PAGE_STORED_DESIGNS_CACHE({ itemKey: cacheKey, item: res });
          return res;
        });
    },
  },
  getters: {
    contactDepartments() {
      return [
        window.enum.contactDepartments.MARKETING,
        window.enum.contactDepartments.SALES,
        window.enum.contactDepartments.SALES_ADMIN,
        window.enum.contactDepartments.PRODUCTION,
        window.enum.contactDepartments.DELIVERIES,
        window.enum.contactDepartments.INSTALLATIONS,
        window.enum.contactDepartments.ACCOUNTS,
        window.enum.contactDepartments.CUSTOMER_SERVICE,
        window.enum.contactDepartments.PURCHASING,
      ].map((department) => ({
        id: department,
        name: i18n.global.t(`contactDepartment.${department}`),
      }));
    },
    positionTypes() {
      return [
        window.enum.contactPositions.DIRECTOR,
        window.enum.contactPositions.MANAGER,
        window.enum.contactPositions.STAFF,
      ].map((positionType) => ({
        id: positionType,
        name: i18n.global.t(`positionType.${positionType}`),
      }));
    },
  },
});
