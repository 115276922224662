<template>
  <aside
    class="bg-white shadow-xl sm:w-1/3 overflow-y-auto scrolling-touch sm:p-5 xl:p-10 absolute left-0 right-0 bottom-0 sm:relative z-10 sm:z-auto"
  >
    <div v-if="!isCostsHidden" class="hidden sm:block">
      <div class="font-medium">{{ breakdown }} Summary</div>

      <div class="mb-4">Basket showing {{ basketStore.basketQty }} item<span v-if="basketStore.basketQty > 1">s</span></div>

      <slot name="extras"></slot>

      <div v-if="basketStore.deliveryCharge && !basketStore?.job?.overrideCalculatedPrice" class="flex border-t border-gray-300 p-3">
        <div>Delivery Charge</div>
        <div class="ml-auto" v-html="formatCurrency(basketStore.getCurrency, basketStore.deliveryCharge)"></div>
      </div>

      <div v-if="basketStore.fittingPrice" class="flex border-t border-gray-300 p-3">
        <div>Fitting Charge</div>
        <div class="ml-auto" v-html="formatCurrency(basketStore.getCurrency, basketStore.fittingPrice)"></div>
      </div>

      <div v-if="basketStore.surveyPrice" class="flex border-t border-gray-300 p-3">
        <div>Survey Charge</div>
        <div class="ml-auto" v-html="formatCurrency(basketStore.getCurrency, basketStore.surveyPrice)"></div>
      </div>

      <div v-if="basketStore?.job?.overrideCalculatedPrice">
        <div v-if="basketStore?.job?.discountApplied">
          <div class="border-t border-gray-300 p-3">
            <div class="flex">
              <div>Subtotal</div>
              <div class="ml-auto" v-html="formatCurrency(basketStore.getCurrency, basketStore?.job?.calculatedPriceTaxable)"></div>
            </div>
          </div>
          <div class="border-t border-gray-300 p-3">
            <div class="flex">
              <div>Discount ({{ basketStore.job.discountCode }})</div>
              <span class="ml-auto">-{{ formatCurrency(basketStore.getCurrency, discount) }}</span>
            </div>
          </div> 
        </div>
        <div class="border-t border-gray-300 p-3">
          <div class="flex">
            <div>Subtotal <span v-if="basketStore?.job?.discountApplied">(Discounted)</span></div>
            <slot name="pricing" :basket-currency="basketStore.getCurrency" :price="basketStore?.job?.overridePriceTaxable"></slot>
          </div>
        </div>
        <div class="flex border-t border-grat-600 p-3">
          <div>Delivery Charge</div>
          <div class="ml-auto" v-html="formatCurrency(basketStore.getCurrency, basketStore.deliveryCharge)"></div>
        </div>
      </div>
      <div v-else-if="basketStore.salesSectorType !== enums.salesSectorType.RETAIL" class="border-t border-gray-300 p-3">
        <div class="flex">
          <div>Subtotal</div>
          <slot name="pricing" :basket-currency="basketStore.getCurrency" :price="basketStore.subtotal"></slot>
        </div>
      </div>

      <div v-if="basketStore.salesSectorType !== enums.salesSectorType.RETAIL" class="flex border-t border-gray-300 p-3">
        <div>VAT</div>
        <div class="ml-auto" v-html="formatCurrency(basketStore.getCurrency, basketStore.vat)"></div>
      </div>

      <div class="flex border-t border-b border-gray-300 p-3 font-medium">
        <div>Total</div>
        <div class="ml-auto" v-html="formatCurrency(basketStore.getCurrency, basketStore.total)"></div>
      </div>
    </div>
    <div class="flex flex-col gap-4 w-full sm:my-6">
      <div v-if="!sandboxStore.sandboxIsActive" class="flex flex-col gap-1">
        <div>Reference</div>
        <input
          v-model="reference"
          type="text"
          class="form-control"
          :class="{ 'border-red-600': referenceMissing }"
        />
        <div v-if="referenceMissing" class="text-red-600 mt-1">Please enter a reference</div>
      </div>
    </div>
    <div
      v-if="basketStore.isOrder"
      class="m-3 xs:m-1 sm:m-0 sm:mb-6 flex flex-col xs:flex xs:flex-row-reverse sm:flex-col"
    >
      <div class="xs:m-2 xs:mr-1 sm:m-0 xs:mb-2 xs:w-1/2 sm:w-full xs:w-full">
        <button
          v-if="showSaveProductButton"
          class="btn-action btn-lg w-full"
          @click="saveQuote(false)"
        >
          Save Products
        </button>
        <button
          v-if="showPlaceOrderButton"
          class="btn-action btn-lg w-full mt-4"
          @click="saveQuote(true)"
        >
          Place Order
        </button>
        <button
          v-if="isFabricatorForMondayDemo()"
          class="btn-action btn-lg w-full mt-4"
          @click="$emit('showBasketInvisionAppLinks')"
        >
          Contract Mode
        </button>
      </div>
    </div>
    <div
      v-else-if="!sandboxStore.sandboxIsActive"
      class="m-3 xs:m-1 sm:m-0 sm:mb-6 flex flex-col xs:flex xs:flex-row-reverse sm:flex-col"
    >
      <div
        class="xs:m-2 xs:mr-1 sm:m-0 xs:mb-2 xs:w-1/2 sm:w-full"
        :class="{ 'xs:w-full': !canBeOrdered }"
      >
        <button class="btn-action btn-lg w-full" @click="saveQuote(true)">Complete</button>
      </div>
    </div>
    <div
      v-else
      class="m-3 xs:m-1 sm:m-0 sm:mb-6 flex flex-col xs:flex xs:flex-row-reverse sm:flex-col"
    >
      <div class="m-3 sm:m-0 sm:mb-6">
        <div class="sm:mt-6 mb-2">feedback:</div>
        <textarea v-model="sandboxNote" class="form-control"></textarea>
      </div>
      <div
        class="xs:m-2 xs:mr-1 sm:m-0 xs:mb-2 xs:w-1/2 sm:w-full"
        :class="{ 'xs:w-full': !canBeOrdered }"
      >
        <button class="btn-action btn-lg w-full" @click="leaveSandboxNote">
          Create feedback on sandbox
        </button>
      </div>
    </div>
    <!--<img src="/images/card-images.png" class="w-64 mt-3 mx-auto">-->
  </aside>
</template>

<script>

import { mapStores } from 'pinia'
import { useBasketStore } from '@/pinia/basket';
import { useSandboxStore } from '@/pinia/sandbox';
import { formatCurrency } from '@/composables/currency';

export default {
  props: ['extras', 'showSaveProductButton', 'showPlaceOrderButton', 'customer', 'breakdown', 'isCostsHidden', 'basketMainItems', 'customerId'],
  setup() {
    return { formatCurrency }
  },
  data() {
    return {
      referenceMissing: false,
      sandboxNote: '',
    };
  },
  computed: {
    ...mapStores(useBasketStore, useSandboxStore),
    hasFittedExtras() {
      return false;
    },
    canBeOrdered() {
      return !this.basketStore.isEnquiry && this.basketStore.contractHasSizing;
    },
    reference: {
      get() {
        return this.basketStore.reference;
      },
      set(value) {
        this.basketStore.setReference(value);
      },
    },
    discount() {
      const total = this.basketStore.job?.calculatedPriceTaxable + this.basketStore.deliveryCharge + this.basketStore.surveyPrice + this.basketStore.fittingPrice
      const discount = total - this.basketStore.subtotal
      return discount
    }
  },
  mounted() {
    this.priceOverride = this.basketStore?.job?.overridePriceTaxable
  },
  methods: {
    leaveSandboxNote() {
      this.sandboxStore.addNoteOnSandbox({
        sandboxId: this.sandboxStore.selectedSandbox.id,
        contractId: this.basketStore.contractData.id,
        note: this.sandboxNote,
      })
        .then(() => {
          window.alertBox.fire('Your note has been submitted');
          this.sandboxNote = '';
        })
        .catch(() => {
          window.alertBox.fire('Failed saving note');
        });
    },
    setFit(fit) {
      if (fit !== this.basketStore.isFit) {
        if (fit === true || !this.hasFittedExtras) {
          this.basketStore.setFit(fit);
          return;
        }

        this.alertBox()
          .fire({
            title: 'Fitting extras will be removed, are you sure?',
            text: 'This action cannot be undone.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.basketStore.setFit(fit);
            }
          });
      }
    },
    saveQuote(bypassSOP) {
      if (this.reference.trim().length < 1) {
        this.referenceMissing = true;
        return;
      }

      if (this.basketStore.isOrder) {
        this.referenceMissing = false;
        this.$emit('save-order', bypassSOP);
        return;
      }
      this.referenceMissing = false;
      this.$emit('save-quote');
    },
    isFabricatorForMondayDemo() {
      return window.location.hostname === 'stddemofab.portal.bm-touch.co.uk';
    },
  },
};
</script>
