<template>
  <Teleport defer to="#portal_popup">
    <modal-window :modal_open="true" title="Edit Financial Details" @close="$emit('close')">
      <div class="flex flex-wrap">
        <div class="w-full xs:w-1/2 xs:pr-5">
          <div class="font-medium">Currency</div>
          <manage-currency
            v-model="customer.financial.currencyId"
            :edit_mode="true"
          ></manage-currency>
        </div>
        <div class="w-full mt-4 xs:w-1/2 xs:mt-0 xs:pl-5" :class="{ 'pointer-events-none opacity-50': customer.financial.allowProductRequoteMarkups }">
          <div class="font-medium">Requote Markup</div>
          <div class="flex relative">
            <input
              v-if="loggedInUser.isAdmin"
              v-model="customer.financial.requoteMarkup"
              class="form-control"
            />
            <input
              v-if="!loggedInUser.isAdmin"
              v-model="customer.financial.requoteMarkup"
              class="form-control bg-gray-200 text-gray-500"
              readonly
            />
            <div class="h-10 absolute pr-4 right-0">
              <div class="flex flex-col justify-around h-full">%</div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full mt-4 xs:w-1/2 xs:mt-8 xs:pr-5">
          <div class="font-medium">Apply tax to requotes</div>
          <div class="flex h-10">
            <label class="flex flex-nowrap mr-3">
              <div class="flex flex-col justify-around">
                <input
                  v-model="customer.financial.vatEnabled"
                  type="radio"
                  name="vat_enabled"
                  :value="true"
                  :disabled="!loggedInUser.isAdmin"
                />
              </div>
              <div
                class="flex flex-col justify-around p-1"
                :class="{ 'text-gray-500': !loggedInUser.isAdmin }"
              >
                Yes
              </div>
            </label>
            <label class="flex flex-nowrap">
              <div class="flex flex-col justify-around">
                <input
                  v-model="customer.financial.vatEnabled"
                  type="radio"
                  name="vat_enabled"
                  :value="false"
                  :disabled="!loggedInUser.isAdmin"
                />
              </div>
              <div
                class="flex flex-col justify-around p-1"
                :class="{ 'text-gray-500': !loggedInUser.isAdmin }"
              >
                No
              </div>
            </label>
          </div>
        </div>
        <div v-show="customer.financial.vatEnabled" class="w-full mt-4 xs:w-1/2 xs:mt-8 xs:pl-5">
          <div class="font-medium">Requote Tax Value</div>
          <div class="flex relative">
            <input
              v-if="loggedInUser.isAdmin"
              v-model="customer.financial.taxValue"
              class="form-control"
            />
            <input
              v-if="!loggedInUser.isAdmin"
              v-model="customer.financial.taxValue"
              class="form-control bg-gray-200 text-gray-500"
              readonly
            />
            <div class="h-10 absolute pr-4 right-0">
              <div class="flex flex-col justify-around h-full">%</div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="customer.financial.vatEnabled && authStore.superuser" class="flex flex-wrap">
        <div class="w-full mt-4 xs:w-1/2 xs:mt-8 xs:pr-5">
          <div class="font-medium">Allow Product Requote Markups</div>
          <div class="flex h-10">
            <label class="flex flex-no-wrap mr-3">
              <div class="flex flex-col justify-around">
                <input
                  v-model="customer.financial.allowProductRequoteMarkups"
                  type="radio"
                  name="allowProductRequoteMarkups"
                  :value="true"
                  :disabled="!loggedInUser.isAdmin"
                />
              </div>
              <div
                class="flex flex-col justify-around p-1"
                :class="{ 'text-gray-500': !loggedInUser.isAdmin }"
              >
                Yes
              </div>
            </label>
            <label class="flex flex-no-wrap">
              <div class="flex flex-col justify-around">
                <input
                  v-model="customer.financial.allowProductRequoteMarkups"
                  type="radio"
                  name="allowProductRequoteMarkups"
                  :value="false"
                  :disabled="!loggedInUser.isAdmin"
                />
              </div>
              <div
                class="flex flex-col justify-around p-1"
                :class="{ 'text-gray-500': !loggedInUser.isAdmin }"
              >
                No
              </div>
            </label>
          </div>
        </div>
      </div>
      <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
        <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
          Cancel
        </button>
        <button class="btn-action btn-lg" @click.prevent.stop="saveFinancialDetails()">
          Save Changes
        </button>
      </div>
    </modal-window>
  </Teleport>
</template>

<script>
import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';
import { useCustomerStore } from '@/pinia/customer';
import manageCurrency from '@/components/shared/ManageCurrency.vue';

export default {
  components: {
    'manage-currency': manageCurrency
  },
  props: ['customer', 'loggedInUser', 'tax_rates'],
  data() {
    return {};
  },
  computed: {
    ...mapStores(useCustomerStore, useAuthStore),
  },
  mounted() {
    this.customer.financial.allowProductRequoteMarkups = this.customer.financial.allowProductRequoteMarkups || false
  },
  methods: {
    async saveFinancialDetails() {
      this.$emit('isLoading', true);
      await this.customerStore.customerUpdateRaw({
        Financial: {
          currencyId: Number(this.customer.financial.currencyId),
          defaultTaxRateId: undefined,
          omitPriceBreakdown: undefined,
          paymentWithOrder: undefined,
          vatEnabled: Boolean(this.customer.financial.vatEnabled),
          requoteMarkup: Number(this.customer.financial.requoteMarkup),
          taxValue: this.customer.financial.vatEnabled ? Number(this.customer.financial.taxValue) : undefined,
          allowProductRequoteMarkups: Boolean(this.customer.financial.allowProductRequoteMarkups)
        },
        CustomerId: undefined,
      });
      this.$emit('isLoading', false);
      this.$emit('close');
    },
  },
};
</script>
