<template>
  <div class="sm:flex flex-col flex-grow overflow-y-scroll scrolling-touch h-screen pb-10">
    <div class="p-6">
      <search-box class="flex" placeholder="Search Part Number..."></search-box>
    </div>
    <div class="sm:flex flex-grow overflow-hidden gap-6 px-6">
      <div class="sm:w-1/4 flex flex-col flex-grow overflow-hidden sm:pt-0">
        <stock-parts-product-selection
          class="overflow-auto scrolling-touch"
        ></stock-parts-product-selection>
      </div>
      <div class="sm:w-1/2 flex flex-col flex-grow overflow-hidden pt-5 sm:pt-0">
        <stock-part-details
          v-if="stockPartsStore.openStockPartSubcategory"
          class="overflow-auto scrolling-touch"
          :open-subcategory-id="stockPartsStore.openStockPartSubcategory.id"
        ></stock-part-details>
      </div>
      <div class="sm:w-1/4 flex flex-col flex-grow overflow-hidden pt-5 sm:pt-0 gap-5">
        <stock-part-cart class="overflow-auto scrolling-touch"></stock-part-cart>
        <router-link
          v-if="stockPartsStore.cartItems.length > 0"
          class="btn-action btn-large"
          :to="url + '/basket'"
        >
          Save To {{ stockPartsStore.buttonName }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useStockPartsStore } from '@/pinia/stock-parts';
import StockPartCart from '@/components/shared/chooseProduct/StockPartCart.vue';
import StockPartDetails from '@/components/shared/chooseProduct/StockPartDetails.vue';
import StockPartsProductSelection from '@/components/shared/chooseProduct/StockPartsProductSelection.vue';
import SearchBox from '@/components/shared/SearchBox.vue';

export default {
  components: {
    'search-box': SearchBox,
    'stock-part-cart': StockPartCart,
    'stock-part-details': StockPartDetails,
    'stock-parts-product-selection': StockPartsProductSelection,
  },
  props: {
    url: String,
  },
  computed: {
    ...mapStores(useStockPartsStore),
  },
  watch: {
    '$route.query': {
      handler(query) {
        this.stockPartsStore.setSearchQuery(query.search || '')
        if (query.search) {
          this.stockPartsStore.runItemQuery(query.search)
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    this.stockPartsStore.initialize()

    await Promise.all([
      this.stockPartsStore.loadStockPartCategories(),
      this.stockPartsStore.loadStockPartSubcategories()
    ]);

    this.stockPartsStore.selectValidCategory()
  },
};
</script>

<style scoped></style>
