<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[['Dashboard', '/'], ['My Account', '/my-account'], ['Custom Styles']]"
      />
    </template>
    <template #header_page_title> Custom Styles</template>
    <Menu />
    <div class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch">
      <div class="p-5 xs:p-10 min-h-screen">
        <div class="bg-white p-3 xs:p-10 border">
          <div class="flex flex-col xs:flex-row gap-10 border-b pb-10">
            <div class="flex-1 flex flex-col gap-5 max-w-3xl">
              <div>
                <div class="text-base">Requote Email Address</div>
                <div class="text-gray-600 text-base mb-3">This is the email address where your re-quote will be sent from.</div>
                <input
                  v-if="authStore.admin"
                  v-model="customer.customer.requoteEmail"
                  class="form-control max-w-sm"
                />
                <input
                  v-if="!authStore.admin"
                  v-model="customer.customer.requoteEmail"
                  class="form-control bg-gray-200 text-gray-500 max-w-sm"
                  readonly
                />
              </div>
              <div>
                <div class="text-base">Requote Email Subject</div>
                <div class="text-gray-600 text-base mb-3">Personalise the subject line for re-quote emails that are sent to consumers.</div>
                <input
                  v-if="authStore.admin"
                  v-model="customer.customer.requoteEmailSubject"
                  class="form-control max-w-sm"
                />
                <input
                  v-if="!authStore.admin"
                  v-model="customer.customer.requoteEmailSubject"
                  class="form-control bg-gray-200 text-gray-500 max-w-sm"
                  readonly
                />
              </div>
              <div>
                <div class="text-base">Requote Email Body Text</div>
                <div class="text-gray-600 text-base mb-3">Personalise the message in your email, this could be a nice welcoming message or terms and conditions of the re-quote.</div>
                <textarea
                  v-if="authStore.admin"
                  v-model="customer.customer.requoteEmailBodyText"
                  class="form-control max-w-sm"
                ></textarea>
                <textarea
                  v-if="!authStore.admin"
                  v-model="customer.customer.requoteEmailBodyText"
                  class="form-control bg-gray-200 text-gray-500 max-w-sm"
                  readonly
                ></textarea>
              </div>
            </div>
            <div
              class="flex-1 flex flex-col xs:mx-auto md:mr-0 justify-center border border-gray-300 w-full lg:w-8/12"
              style="min-height: 300px; max-width: 400px;"
            >
              <img src="/images/guides/requote-email.jpg" />
            </div>
          </div>
          <div>
            <div v-if="styleStore.isCompanyStylingEnabled" class="flex-1 flex flex-col gap-5 xs:flex-row mt-10 border-b gap-10 pb-10">
              <div class="flex-1">
                <strong class="text-base">Quote PDF First Page Image.</strong>
                <div class="text-gray-600 text-base my-3">
                  This will be displayed on the first page of the quote PDF.
                </div>
                <Dropzone
                  :current_file="quotePdfFirstImage.src"
                  @add-file="images.find((x) => x.imageType === quotePdfFirstImage.imageType).newImage = $event"
                  @remove-file="resetImage(quotePdfFirstImage.imageType)"
                ></Dropzone>
                <div class="text-gray-500 py-3">
                  <div>Required file type: JPG, PNG.</div>
                  <div>Recommended size: 1157 x 760px.</div>
                  <div>Max File Size: 1MB</div>
                </div>
              </div>
              <div class="flex flex-col gap-5 w-full flex-1">
                <div
                  v-if="quotePdfFirstImage.src"
                  class="flex flex-col xs:mx-auto md:mr-0 justify-center border border-gray-300 w-full lg:w-8/12"
                  style="min-height: 300px; max-width: 400px;"
                >
                  <img :src="quotePdfFirstImage.src" />
                </div>
                <img
                  v-else
                  class="flex flex-col xs:mx-auto md:mr-0 justify-center border border-gray-300 w-full lg:w-8/12"
                  style="min-height: 300px; max-width: 400px;"
                  src="/images/quotePdfFrontPageImageTemplate.png"
                />
              </div>
            </div>
            <div v-if="styleStore.isCompanyStylingEnabled" class="flex-1 flex flex-col gap-10 sm:flex-row mt-10 pb-5">
              <div class="flex-1">
                <strong class="text-base">Quote PDF Last Page Image.</strong>
                <div class="text-gray-600 text-base my-3">
                  This will be displayed on the last page of the quote PDF.
                </div>
                <Dropzone
                  :current_file="quotePDFLastImage.src"
                  @add-file="images.find((x) => x.imageType === quotePDFLastImage.imageType).newImage = $event"
                  @remove-file="resetImage(quotePDFLastImage.imageType)"
                ></Dropzone>
                <div class="text-gray-500 py-3">
                  <div>Required file type: JPG, PNG.</div>
                  <div>Recommended size: 1157 x 760px.</div>
                  <div>Max File Size: 1MB</div>
                </div>
              </div>
              <div class="flex flex-col gap-5 w-full flex-1">
                <div
                  v-if="quotePDFLastImage.src"
                  class="flex flex-col xs:mx-auto md:mr-0 justify-center border border-gray-300 w-full lg:w-8/12"
                  style="min-height: 300px; max-width: 400px;"
                >
                  <img :src="quotePDFLastImage.src" />
                </div>
                <img
                  v-else
                  class="flex flex-col xs:mx-auto md:mr-0 justify-center border border-gray-300 w-full lg:w-8/12"
                  style="min-height: 300px; max-width: 400px;"
                  src="/images/quotePdfBackPageImageTemplate.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="is_admin_user"
        class="sticky bottom-0 bg-white p-3 xs:p-4 text-right border-gray-300 border-t"
      >
        <div class="flex flex-col xs:flex-row justify-between">
          <div class="ml-auto mt-2 xs:mt-0">
            <button class="btn-action btn-lg w-full xs:w-auto" @click.prevent.stop="save()">
              Save all Changes
            </button>
          </div>
        </div>
      </div>
    </div>
  </touch-layout>
</template>

<script>

import Menu from '@/components/portal/myAccount/Menu.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';
import { useCustomerStore } from '@/pinia/customer';
import { useStyleStore } from '@/pinia/style';
import Dropzone from '@/components/shared/Dropzone.vue';

export default {
  components: {
    Menu,
    Breadcrumbs,
    Dropzone
  },
  data() {
    return {
      images: [
        {
          imageType: 1,
          src: '',
          newImage: '',
          reset: false,
          default: '',
        },
        {
          imageType: 2,
          src: '',
          newImage: '',
          reset: false,
          default: '',
        },
      ],
      customer: undefined,
    };
  },
  computed: {
    ...mapStores(useAuthStore, useCustomerStore, useStyleStore),
    quotePdfFirstImage() {
      return this.images.find((x) => x.imageType === this.enums.ImageTypeEnum.quotePDFFirstImage);
    },
    quotePDFLastImage() {
      return this.images.find((x) => x.imageType === this.enums.ImageTypeEnum.quotePDFLastImage);
    },
    deletedImages() {
      return typeof this.images.find((x) => x.reset) !== 'undefined';
    },
  },
  created() {
    this.customer = JSON.parse(JSON.stringify(this.customerStore.customer))
  },
  async mounted() {
    await this.updateCurrentImages();
  },
  methods: {
    resetImage(imageType) {
      const index = this.images.findIndex((x) => x.imageType === imageType)
      this.images[index] = { ...this.images[index], reset: true, newImage: null }
    },
    async save() {
      this.loading = true;
      if (this.deletedImages) {
        await this.styleStore.deleteCompanyStyling({
          quotePDFFirstImage: this.quotePdfFirstImage.reset,
          quotePDFLastImage: this.quotePDFLastImage.reset,
        });
        this.images = this.images.map((x) => {
          x.reset = false;
          return x;
        });
      }
      await this.styleStore.updateCompanyStyles({
        quotePDFFirstImage: this.images.find((x) => x.imageType === this.enums.ImageTypeEnum.quotePDFFirstImage)?.newImage,
        quotePDFLastImage: this.images.find((x) => x.imageType === this.enums.ImageTypeEnum.quotePDFLastImage)?.newImage,
      });

      await this.customerStore.updateCustomerRequoteDetails({
        customerId: this.customer.customerId,
        requoteEmail: this.customer.customer.requoteEmail,
        requoteEmailSubject: this.customer.customer.requoteEmailSubject,
        requoteEmailBodyText: this.customer.customer.requoteEmailBodyText,
      });
      const customer = await this.customerStore.customerGet(this.customer.customerId)
      this.customerStore.setCustomer(customer)
      await this.updateCurrentImages();
    },
    resetSrcsToDefault() {
      this.images = this.images.map((x) => {
        x.src = '';
        return x;
      });
    },
    async updateCurrentImages() {
      const styles = await this.styleStore.getCompanyStyles();
      this.resetSrcsToDefault();
      const indexQuotePDFFirstImage = this.images.findIndex((x) => x.imageType === this.enums.ImageTypeEnum.quotePDFFirstImage)
      this.images[indexQuotePDFFirstImage] = {
        src: styles.quoteReportStyling.firstPageImageUri,
        imageType: this.enums.ImageTypeEnum.quotePDFFirstImage,
        reset: false
      }
      const indexQuotePDFLastImage = this.images.findIndex((x) => x.imageType === this.enums.ImageTypeEnum.quotePDFLastImage)
      this.images[indexQuotePDFLastImage] = {
        src: styles.quoteReportStyling.lastPageImageUri,
        imageType: this.enums.ImageTypeEnum.quotePDFLastImage,
        reset: false
      }
    },
  },
};
</script>
