<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Dealer Network Mode Setup', '/dealer-network-setup'],
          ['Products'],
        ]"
      />
    </template>
    <template #header_page_title> Products </template>
    <div class="flex bg-white px-5 border-t-4 border-white whitespace-nowrap">
      <router-link
        to="/dealer-network-setup/products"
        class="cursor-pointer border-b-4 py-2 px-1 mx-5 border-black"
      >
        Products
      </router-link>
      <router-link
        v-if="false"
        to="/dealer-network-setup/option-control"
        class="cursor-pointer border-b-4 py-2 px-1 mx-5 hover:border-black border-white"
      >
        Option Control 
      </router-link>
    </div>  
    <div class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch">
      <ProductsList v-if="product_overrides" :product_overrides="product_overrides" setup="Dealer Network Mode" @enable="enable">
        <template #show_products="{ show_products_data }">
          <div class="mt-2 flex items-center gap-2">
            <div
              :class="{ 'pointer-events-none opacity-50': demo_mode }"
              class="relative cursor-pointer"
              @click.stop="show_products_data.customerEnabledRetail = !show_products_data.customerEnabledRetail"
            >
              <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner"></div>
              <div class="switch-toggle" :class="{ _active: show_products_data.customerEnabledRetail }"></div>
            </div>
            <span>Show to Consumers</span>
          </div>
        </template>
      </ProductsList>
    </div>
  </touch-layout>
</template>

<script>
import ProductsList from '@/components/shared/setup/products/ProductsList.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import { mapStores } from 'pinia'
import { useCustomerStore } from '@/pinia/customer';

export default {
  components: {
    Breadcrumbs,
    ProductsList,
  },
  data() {
    return {
      customerId: undefined,
      product_overrides: undefined,
    };
  },
  computed: {
    ...mapStores(useCustomerStore),
  },
  async mounted() {
    this.customerId = await window.touch.staffGetOrganisationCustomerId();
    this.product_overrides = await this.customerStore.customerGetAllProductsWithOverrides(this.customerId);
    this.product_overrides.sort((a, b) =>
      a.productName.localeCompare(b.productName, undefined, {
        numeric: true,
        sensitivity: 'base',
      }),
    );
  },
  methods: {
    enable() {
      this.product_overrides.forEach((product) => {
        if (product.customerEnabledRetail) {
          product.enabledRetail = true;
          product.customerEnabled = true;
          product.enabled = true;
        }
      });
    }
  },
};
</script>
