<template>
  <div
    class="w-full items-center relative border-b"
    :style="{ height: '65px' }"
    :class="touch_portal_installation ? 'bg-blue-900 text-white' : 'bg-white text-black'"
  >
    <div class="px-3 md:px-10">
      <slot>
        <h2>{{ readableDate() }}</h2>
      </slot>
    </div>
    <div class="ml-auto p-4 hidden sm:flex">
      <router-link to="/">
        <div class="bg-white rounded-full h-8 w-8 text-center mr-3">
          <i class="fal fa-home text-black" :style="{ 'line-height': '2rem' }"></i>
        </div>
      </router-link>
      <div
        class="border-solid border-l opacity-50 mr-3"
        :class="touch_portal_installation ? 'border-gray-700' : 'border-gray-400'"
      ></div>
      <div class="flex items-center cursor-pointer" @click="isDropdown = !isDropdown">
        <div class="relative bg-white rounded-full h-8 w-8 text-center mr-3 overflow-hidden">
          <img
            id="user_profile"
            :src="logo_src"
            class="m-auto top-1/2 absolute transform -translate-y-1/2"
            @error="defaultImage"
          />
        </div>
        <i class="fal fa-chevron-down" :style="{ 'line-height': '2rem' }"></i>
      </div>
    </div>
    <div
      v-if="isDropdown"
      class="bg-white text-black absolute right-0 shadow-lg z-50 p-6 rounded-bl-lg border-t hidden sm:block"
      :style="{ top: '100%' }"
    >
      <div class="ml-8">
        <span class="font-light">{{ fullName }}</span>
      </div>
      <div class="ml-8 mb-4">
        <span class="font-light">{{ email }}</span>
      </div>
      <router-link
        v-if="touch_portal_installation"
        :to="'/my-account'"
      >
        <div class="flex items-center mb-4">
          <i class="fal fa-user w-8"></i>
          <span>My Account</span>
        </div>
      </router-link>
      <router-link
        v-else-if="touch_business_installation && is_admin_user"
        :to="'/manage-touch-account/company-details'"
      >
        <div class="flex items-center mb-4">
          <i class="fal fa-user w-8"></i>
          <span>My Account</span>
        </div>
      </router-link>
      <div class="border-solid border-t border-gray-500 mb-4"></div>
      <router-link :to="authStore.superuser ? '/login-admin' : '/login'">
        <div class="flex items-center">
          <i class="fal fa-sign-out w-8"></i>
          <span>Sign Out</span>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';

export default {
  data() {
    return {
      logo_src: undefined,
      isDropdown: false,
      fullName: undefined,
      email: undefined,
    };
  },
  computed: {
    ...mapStores(useAuthStore),
  },
  created() {
    const { firstName, lastName, email, image } = this.authStore;
    this.fullName = `${firstName} ${lastName}`;
    this.email = email;
    if (image) {
      this.logo_src = window.touch.imageBaseURL + image;
    }
  },
  methods: {
    readableDate() {
      return new Date().toDateString();
    },
    defaultImage(event) {
      const element = document.getElementById(event.target.id);
      element.src = '/images/blank-user.png';
    },
  },
};
</script>
