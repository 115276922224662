import { errorCallback, successCallback } from '@/composables/callback';

export async function setupUpdateProducts(products) {
  return (
    await this.authenticatedPost('setup/UpdateProducts', {
      Products: products,
    })
  ).data;
}

export async function setupOrderBusinessProducts(products) {
  return this.parseResponse(
    await this.authenticatedPost('setup/OrderBusinessProducts', {
      Products: products,
    }),
    errorCallback,
    () => successCallback('Save'),
  );
}
