import ChooseProduct from '@/views/Branded/ChooseProduct.vue';
import Designer from '@/views/Branded/Designer.vue';
import Index from '@/views/Branded/Basket.vue';
import ThankYou from '@/views/Branded/ThankYou.vue';
import VisualiserUpload from '@/views/Shared/Visualiser.vue';
import VisualiserPlace from '@/views/Shared/Visualiser/Place.vue';

import { useVisualiserStore } from '@/pinia/visualiser';
import { useBrandedStore } from '@/pinia/branded';

const routes = [
  {
    path: '/:slug/choose-product',
    name: 'Design Online - Choose Product',
    component: ChooseProduct,
  },
  {
    path: '/:slug/design/:id',
    name: 'Design Online - Designer',
    component: Designer,
  },
  {
    path: '/:slug/basket',
    name: 'Design Online - Basket',
    component: Index,
  },
  {
    path: '/:slug/thanks',
    name: 'Design Online - Thank You',
    component: ThankYou,
  },
  {
    path: '/:slug/visualiser',
    name: 'Design Online - Visualiser Setup',
    meta: { showSidebar: false },
    component: VisualiserUpload,
  },
  {
    path: '/:slug/visualiser/place',
    name: 'Design Online - Visualiser',
    meta: { showSidebar: false },
    component: VisualiserPlace,
    beforeEnter: (to, from, next) => {
      const visualiserStore = useVisualiserStore()
      if (visualiserStore.images.length > 0) {
        next()
      } else {
        const brandedStore = useBrandedStore()
        next(brandedStore.slug + '/visualiser');
      }
    }
  },
];

export default routes.map((route) => {
  const meta = { ...route.meta, brandedModeRoute: true };
  return { ...route, meta };
});
