<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['My Account', '/manage-touch-account/company-details'],
          ['Payment Gateway'],
        ]"
      />
    </template>
    <template #header_page_title> Stripe Payment Gateway Setup </template>
    <Menu></Menu>
    <div class="p-8 bg-gray-200 h-screen">
      <loading :loading="isLoading">
        <div class="bg-white p-6">
          <div>
            <span class="btn-action" @click="createAccount()">Create Stripe Account</span>
          </div>
        </div>
      </loading>
    </div>
  </touch-layout>
</template>

<script>

import { useStyleStore } from '@/pinia/style';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import Menu from '@/components/business/manageTouchAccount/Menu.vue';

export default {
  components: {
    Breadcrumbs,
    Menu
  },
  async beforeRouteEnter(to, from, next) {
    const styleStore = useStyleStore()
    next(async () => {
      if (styleStore.fabricator.name === 'Virtuoso') {
        next('/manage-touch-account/payment-gateway/firstdata');
      } else {
        const account = await window.touch.getAccount();
        if (account.id) {
          next('/manage-touch-account/payment-gateway/stripe');
        } else {
          next();
        }
      }
    });
  },
  data() {
    return {
      contractId: 0,
      account: undefined,
      isLoading: false,
    };
  },
  methods: {
    async createAccount() {
      this.isLoading = true;
      await window.touch.createAccount();
      const { url } = await window.touch.createAccountLink();
      window.open(url, 'blank');
      this.routerReplace('/manage-touch-account/payment-gateway/stripe');
      this.isLoading = false;
    },
  },
};
</script>
