<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs :routes="[['Dashboard', '/'], ['My Account', '/my-account'], ['My Details']]" />
    </template>
    <template #header_page_title> My Details </template>
    <template #header_page_actions>
      <div class="px-3 md:px-10 flex items-center">
        <span class="font-medium inline-flex flex-col justify-around pr-3">Account Code</span>
        <div class="text-brand-primary inline-flex flex-col justify-around">
          {{ customer.financial.accountCode }}
        </div>
      </div>
    </template>
    <customer-navigation-menu></customer-navigation-menu>
    <loading
      :loading="loading"
      class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch"
    >
      <company-details
        v-if="edit_mode_section === 'company_details'"
        :customer="customer"
        :staff-contacts="staffContacts"
        :logged-in-user="loggedInUser"
        :logo_src="logo_src"
        @close="(edit_mode_section = 'none'), loadCustomer()"
        @is-loading="loading = $event"
      />
      <financial-details
        v-else-if="edit_mode_section === 'financial_details'"
        :customer="customer"
        :logged-in-user="loggedInUser"
        :tax_rates="tax_rates"
        @close="(edit_mode_section = 'none'), loadCustomer()"
        @is-loading="loading = $event"
      />
      <div class="flex flex-wrap bg-white border m-3 md:m-10 relative pr-16">
        <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
          <div class="font-bold mb-2">Company</div>
          <div class="flex items-center w-full">
            <div v-if="!company_logo_missing">
              <div
                class="rounded-full border flex flex-col justify-around overflow-hidden relative mr-4"
                style="width: 50px; height: 50px"
              >
                <img
                  :id="'userImg_' + customer.id"
                  :src="logo_src"
                  class="m-auto"
                  @error="company_logo_missing = true"
                  @load="positionAvatar"
                />
              </div>
            </div>
            <div class="flex-grow">
              <div class="flex flex-col justify-around">{{ customer.company.name }}</div>
            </div>
          </div>
        </div>
        <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
          <div class="font-bold mb-2">Contact Number</div>
          <div class="flex flex-col justify-around">{{ customer.company.phone }}</div>
        </div>
        <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
          <div class="font-bold mb-2">Sales Contact</div>
          <div
            class="flex flex-col justify-around"
            v-text="customer.customer.salesContact
              ? customer.customer.salesContact.firstName + ' ' + customer.customer.salesContact.lastName + ' - ' + customer.customer.salesContact.email
              : 'None'"
          ></div>
        </div>
        <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
          <div class="font-bold mb-2">Email</div>
          <div class="flex flex-col justify-around">{{ customer.company.email }}</div>
        </div>
        <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
          <div class="font-bold mb-2">Website</div>
          <div class="flex flex-col justify-around">{{ customer.company.web }}</div>
        </div>
        <div
          v-if="customer.eCommerceIntegration.companyRegNumber"
          class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4"
        >
          <div class="font-bold mb-2">Company Registration Number</div>
          <div class="flex flex-col justify-around">
            {{ customer.eCommerceIntegration.companyRegNumber }}
          </div>
        </div>
        <div
          v-if="customer.eCommerceIntegration.vatRegistrationNumber"
          class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4"
        >
          <div class="font-bold mb-2">VAT Registration Number</div>
          <div class="flex flex-col justify-around">
            {{ customer.eCommerceIntegration.vatRegistrationNumber }}
          </div>
        </div>
        <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
          <div class="font-bold mb-2">Use 3rd Party Glass</div>
          <div class="flex flex-col justify-around">
            {{ customer.customer.useCustomerOnlyGlass ? 'Yes' : 'No' }}
          </div>
        </div>
        <div v-if="availableBranches.length > 0 && customer.branches?.length > 0" class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
          <div class="font-bold mb-2">Linked Branches</div>
          <div class="flex flex-col">
            <span v-for="branch in customer.branches" :key="branch.id">
              {{ availableBranches.find(x => x.id === branch.branchCompanyId)?.name }}
            </span>
          </div>
        </div>   
        <div class="absolute bottom-0 right-0 p-5">
          <div
            v-if="edit_mode_section === 'company_details'"
            class="px-2 py-1 text-2xs rounded text-gray-600 border bordergray-600 cursor-pointer hover:text-white hover:bg-gray-600"
            @click="edit_mode_section = 'none'"
          >
            <i class="fa fa-times"></i>
          </div>
          <div
            v-if="edit_mode_section !== 'company_details'"
            class="px-2 py-1 text-2xs rounded text-green-600 border border-green-600 cursor-pointer hover:text-white hover:bg-green-600"
            @click="edit_mode_section = 'company_details'"
          >
            <i class="fa fa-pencil"></i>
          </div>
        </div>
      </div>

      <div
        v-if="showRequotePricingScreen"
        class="flex flex-wrap bg-white border m-3 md:m-10 relative pr-16"
      >
        <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
          <div class="font-bold mb-2">Currency</div>
          <manage-currency
            v-model="customer.financial.currencyId"
            :edit_mode="false"
          ></manage-currency>
        </div>
        <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
          <div class="font-bold mb-2">Requote Markup</div>
          <div class="flex flex-col justify-around">
            {{ customer.financial.requoteMarkup }}%
          </div>
        </div>
        <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
          <div class="font-bold mb-2">Apply tax to requotes</div>
          <div
            class="flex flex-col justify-around"
            v-text="customer.financial.vatEnabled ? 'Yes' : 'No'"
          ></div>
        </div>
        <div v-if="customer.financial.vatEnabled" class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
          <div class="font-bold mb-2">Requote Tax Value</div>
          <div class="flex flex-col justify-around">{{ customer.financial.taxValue }}%</div>
        </div>
        <div v-if="authStore.superuser" class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
          <div class="font-bold mb-2">Allow Product Requote Markups</div>
          <div
            class="flex flex-col justify-around"
            v-text="customer.financial.allowProductRequoteMarkups ? 'Yes' : 'No'"
          ></div>
        </div>
        <div class="absolute bottom-0 right-0 p-5">
          <div
            class="px-2 py-1 text-2xs rounded text-green-600 border border-green-600 cursor-pointer hover:text-white hover:bg-green-600"
            @click="edit_mode_section = 'financial_details'"
          >
            <i class="fa fa-pencil"></i>
          </div>
        </div>
      </div>
    </loading>
  </touch-layout>
</template>

<script>

import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';
import { useCustomerStore } from '@/pinia/customer';
import { useFeatureStore } from '@/pinia/feature';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import Menu from '@/components/portal/myAccount/Menu.vue';
import CompanyDetails from '@/components/portal/myAccount/CompanyDetails.vue';
import FinancialDetails from '@/components/portal/myAccount/FinancialDetails.vue';
import manageCurrency from '@/components/shared/ManageCurrency.vue';

export default {
  components: {
    Breadcrumbs,
    'company-details': CompanyDetails,
    'financial-details': FinancialDetails,
    'customer-navigation-menu': Menu,
    'manage-currency': manageCurrency,
  },
  data() {
    return {
      showRequotePricingScreen: undefined,
      loggedInUser: {
        id: 0,
        isAdmin: false,
      },
      customer: {
        company: {},
        customer: {},
        financial: {},
        eCommerceIntegration: {},
      },
      staffContacts: [],
      tax_rates: [],
      loading: true,
      logo_src: false,
      edit_mode_section: 'none',
      company_logo_missing: false,
      availableBranches: []
    };
  },
  computed: {
    ...mapStores(useAuthStore, useCustomerStore, useFeatureStore),
    hasDebugToggle() {
      return typeof this.customer?.customer?.debug !== 'undefined';
    },
    selected_tax_rate_label() {
      const taxRate = this.tax_rates.find(
        (rate) => rate.id === this.customer.financial.defaultTaxRateId,
      );
      if (taxRate === undefined) {
        return '';
      }
      return `${taxRate.name} (${taxRate.value}%)`;
    },
  },
  async mounted() {
    const { branches } = await window.touch.customerListBranches(1000, 0);
    this.availableBranches = branches
    await this.loadCustomer();
    this.staffContacts = await this.customerStore.customerListContacts();
    this.showRequotePricingScreen = await this.featureStore.isRequotePriceChangeEnabled;
  },
  methods: {
    async loadCustomer() {
      this.loading = true;
      this.customer = await this.customerStore.customerGet(this.$route.params.id);
      this.customerStore.setCustomer(this.customer)
      this.customer.newLogo = null;
      this.logo_src = window.touch.imageBaseURL + this.customer.styling.logo;
      if (this.authStore.loggedIn) {
        this.loggedInUser.id = this.authStore.id;
        this.loggedInUser.isAdmin = this.authStore.admin;
      }
      this.loading = false;
    },
  },
};
</script>
