<template>
  <div v-if="products.length > 0 || isLoading" class="pb-40">
    <div
      v-for="(product, i) in products"
      :key="i"
      class="flex text-white hover:bg-blue-700 cursor-pointer transition duration-500 ease-in-out group p-2"
      :class="{ 'bg-blue-700': selectedProductId === product.productId }"
      @click="$emit('select-product', product)"
    >
      <div
        class="flex transition duration-500 ease-in-out transform group-hover:translate-x-2"
        :class="{ 'translate-x-2': selectedProductId === product.productId }"
      >
        <div class="flex flex-col justify-around text-sm">{{ product.name }}</div>
      </div>
      <div
        class="ml-auto p-1 text-sm text-gray-800 transition duration-500 ease-in-out opacity-0 group-hover:opacity-100"
        :class="{ 'opacity-100': selectedProductId === product.productId }"
      >
        <i class="fa fa-caret-right mt-2"></i>
      </div>
    </div>
  </div>
  <div v-else class="p-6">
    <h1>No Products Available</h1>
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useTouchStore } from '@/pinia/touch';
import { useSandboxStore } from '@/pinia/sandbox';

export default {
  props: ['typeId', 'groupId', 'selectedProductId'],
  data() {
    return {
      products: [],
      isLoading: true,
    };
  },
  computed: {
    ...mapStores(useTouchStore, useSandboxStore),
  },
  watch: {
    typeId: {
      async handler() {
        this.getProducts();
      },
      immediate: true,
    },
    groupId() {
      this.getProducts();
    },
  },
  methods: {
    async getProducts() {
      this.products = (await this.touchStore.processingGetAvailableProductsToSelect({
        typeId: this.typeId, groupId: this.groupId, sandbox: this.sandboxStore.sandboxIsActive
      }))
        .map((product) => product.products)
        .sort((a, b) => a.listOrder - b.listOrder);
      this.isLoading = false;
      if (this.products.length > 0) {
        this.$emit('select-product', this.products[0]);
      }
    },
  },
};
</script>

<style scoped></style>
