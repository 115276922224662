<template>
  <touch-layout class="bg-gray-200">
    <main class="home-custom overflow-y-auto">
      <div class="md:flex gap-5 p-5 flex-no-wrap">
        <div class="flex flex-col md:w-2/3">
          <Welcome :first-name="authStore.user.firstName" :company-name="authStore.user.companyName" />
          <Enquiries />
          <div class="xs:flex md:flex-col gap-5 md:gap-0 mt-5 md:mt-0 md:hidden">
            <News :company-name="styleStore.fabricator.name" :news-items="newsItems" class="w-full" />
            <Hero />
          </div>
          <KnowledgeBase />
        </div>
        <div class="hidden md:flex md:flex-col gap-5 md:gap-0 mt-5 md:mt-0 md:w-1/3">
          <News :company-name="styleStore.fabricator.name" :news-items="newsItems" class="w-full" />
          <Hero />
        </div>
      </div>
    </main>
  </touch-layout>
</template>

<script>

import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';
import { useStyleStore } from '@/pinia/style';
import { useTouchStore } from '@/pinia/touch';
import Welcome from '@/components/portal/home/Welcome.vue';
import Enquiries from '@/components/portal/home/Enquiries.vue';
import KnowledgeBase from '@/components/portal/home/KnowledgeBase.vue';
import Hero from '@/components/portal/home/Hero.vue';
import News from '@/components/portal/home/News.vue';

export default {
  name: 'Home',
  components: {
    Welcome,
    Enquiries,
    KnowledgeBase,
    News,
    Hero,
  },
  data() {
    return {
      active_news_slide: 'news',
      user_img_url: '',
      twitter_handle: '',
      seatCounts: 0,
      news_widget_hovered: false,
      news_interval: null,
      branded_banner_popup_open: false,
      instagram_connected: false,
      feeds_available: ['news'],
      vendorModalOpen: false,
      dontShowVendorModalAgain: false,
      newsItems: []
    };
  },
  computed: {
    ...mapStores(useAuthStore, useStyleStore, useTouchStore),
    show_news_link() {
      return this.feeds_available.includes('news');
    },
  },
  async mounted() {
    const { news_items } = await window.touch.latestNews({ limit: 1, offset: 0 });
    this.newsItems = news_items
    this.setInitialQuoteEnquiriesWidgetTab();

    this.user_img_url = window.touch.imageBaseURL + this.authStore.image;
    if (window.location.hostname.split('.')[0] === 'demo') {
      await window.touch.checkInstagramAuth({
        fabricatorName: this.styleStore.fabricator_name,
      })
        .then(() => {
          this.instagram_connected = true;
          this.feeds_available.push('instagram');
        })
        .catch(() => {
          this.instagram_connected = false;
        });
    }
    this.active_news_slide = this.feeds_available[0];
  },
  methods: {
    openWidget() {
      window.openFreshdeskWidget('101000478837');
    },
    userImgFallback() {
      this.user_img_url = '/images/blank-user.png';
    },
    async loadSeatsAvailable() {
      this.seatCounts = await this.touchStore.checkSeats();
    },
    setInitialQuoteEnquiriesWidgetTab() {
      if (this.branded_mode_enabled) {
        this.quotes_and_enquiries_tab = 'enquiries';
      } else {
        this.quotes_and_enquiries_tab = 'networkenquiries';
      }
    },
  },
};
</script>

<style scoped>
@media (min-width: 1024px) {
  .home-custom {
    width: calc(100vw - 250px);
  }
}
</style>
