import i18n from '@/i18n';
import { defineStore } from 'pinia';
import { useCustomerStore } from '@/pinia/customer';
import { useBrandedStore } from '@/pinia/branded';
import { useAuthStore } from '@/pinia/auth';

export const useStyleStore = defineStore('style', {
  persist: true,
  state: () => ({
    fabricator: null,
    pageName: null,
    companyStyles: null,
  }),
  actions: {
    setPageName(name) {
      this.pageName = name;
    },
    async loadCustomerStyle() {
      const customerStore = useCustomerStore()
      let customer = customerStore?.customer
      if (customer) {
        const currencies = await window.touch.commonGetCurrencies();
        try {
          customer.currency = currencies.find((currency) => currency.id === customer.financial.currencyId,).code;
        } catch {
          try {
            customer.currency = currencies.filter((currency) => currency.isDefault)[0].code;
          } catch {
            customer.currency = 'GBP';
          }
        }
      }
      customerStore.setCustomer(customer)
    },
    async getCompanyStyles(companyId) {
      const customerStore = useCustomerStore()
      companyId = companyId || customerStore?.customer?.companyId;
      if (companyId) {
        const styles = await window.touch.getCompanyStyling(companyId);
        this.companyStyles = styles;
      } else if (!this.companyStyles) {
        return this.fabricator?.styling;
      }
      return this.companyStyles;
    },
    async loadFabricatorStyle() {
      const details = await window.touch.commonOrganisationDetails();
      this.fabricator = details;
    },
    async refresh() {
      this.loadFabricatorStyle()

      if (window.touch.hasToken() && window.VITE_APP_INSTALLATION_TYPE !== 'business') {
        this.loadCustomerStyle()
      }
    },
    async updateCompanyStyles(
      {
        enquiryWelcomeImage = undefined,
        enquiryCompletionImage = undefined,
        virtualHomeBanner = undefined,
        virtualHomeBackground = undefined,
        portalPageLoginImage = undefined,
        portalPageLoginSecondaryImage = undefined,
        portalPageLoginHeader = undefined,
        portalPageSubHeader = undefined,
        portalPageLoginWelcomeText = undefined,
        enquiryHeader = undefined,
        enquirySubHeader = undefined,
        enquiryWelcome = undefined,
        thankYouText = undefined,
        thankYouHeadingText = undefined,
        thankYouSubHeadingText = undefined,
        enquiryPDFFirstImage = undefined,
        enquiryPDFLastImage = undefined,
        enquiryPdfFooterHeader = undefined,
        enquiryPdfFooterText = undefined,
        quotePDFFirstImage = undefined,
        quotePDFLastImage = undefined,
        emailEquiryImage = undefined,
        enquiryEmailInstallerSubject = undefined,
        enquiryEmailInstallerBodyText = undefined,
        enquiryPageDesignNames = undefined,
        enquiryEmailDealerConfirmationText = undefined,
        enquiryEmailBrandedConfirmationText = undefined,
        siteTitle = undefined,
      },
    ) {
      const customerStore = useCustomerStore()
      let companyId = customerStore?.customer.companyId;
      if (companyId !== null) {
        await window.touch.setCompanyStyling(
          companyId,
          enquiryWelcomeImage,
          enquiryCompletionImage,
          virtualHomeBanner,
          virtualHomeBackground,
          portalPageLoginImage,
          portalPageLoginSecondaryImage,
          portalPageLoginHeader,
          portalPageSubHeader,
          portalPageLoginWelcomeText,
          enquiryHeader,
          enquirySubHeader,
          enquiryWelcome,
          enquiryPDFFirstImage,
          enquiryPDFLastImage,
          enquiryPdfFooterHeader,
          enquiryPdfFooterText,
          quotePDFFirstImage,
          quotePDFLastImage,
          emailEquiryImage,
          enquiryEmailInstallerSubject,
          enquiryEmailInstallerBodyText,
          enquiryPageDesignNames,
          enquiryEmailDealerConfirmationText,
          enquiryEmailBrandedConfirmationText,
          siteTitle,
          thankYouText,
          thankYouHeadingText,
          thankYouSubHeadingText,
        );
      }
    },
    async deleteCompanyStyling(
      {
        enquiryWelcomeImage = undefined,
        enquiryCompletionImage = undefined,
        virtualHomeBanner = undefined,
        virtualHomeBackground = undefined,
        portalPageLoginImage = undefined,
        portalPageLoginSecondaryImage = undefined,
        enquiryPDFFirstImage = undefined,
        enquiryPDFLastImage = undefined,
        quotePDFFirstImage = undefined,
        quotePDFLastImage = undefined,
        emailEquiryImage = undefined,
      },
    ) {
      const customerStore = useCustomerStore()
      let companyId = customerStore.customer?.companyId;
      if (window.VITE_APP_INSTALLATION_TYPE === 'business') {
        const customerId = await window.touch.staffGetOrganisationCustomerId();
        companyId = (await window.touch.customerGet(customerId, true))?.companyId;
      }
      if (companyId !== null) {
        await window.touch.deleteCompanyStyling(
          companyId,
          enquiryWelcomeImage,
          enquiryCompletionImage,
          virtualHomeBanner,
          virtualHomeBackground,
          portalPageLoginImage,
          portalPageLoginSecondaryImage,
          enquiryPDFFirstImage,
          enquiryPDFLastImage,
          quotePDFFirstImage,
          quotePDFLastImage,
          emailEquiryImage,
        );
        this.companyStyles = null
      }
    },
  },
  getters: {
    isCompanyStylingEnabled() {
      return true;
    },
    back_to_site_link(state) {
      try {
        const url = state.fabricator.web;

        return !url.includes('//') ? `//${url}` : url;
      } catch (error) {
        return false;
      }
    },
    fabricator_logo(state) {
      try {
        return `${window.touch.imageBaseURL}${state.fabricator.styling.logo}`;
      } catch (error) {
        return '/images/logo.svg';
      }
    },
    customer_logo(state) {
      try {
        return `${window.touch.imageBaseURL}${state.companyStyles.logo}`;
      } catch (error) {
        return '';
      }
    },
    fabricator_name(state) {
      try {
        return `${state.fabricator.name}`;
      } catch (error) {
        return 'Touch';
      }
    },
    page_title(state) {
      let installationType = window.VITE_APP_INSTALLATION_TYPE.charAt(0).toUpperCase() + window.VITE_APP_INSTALLATION_TYPE.slice(1)
      const authStore = useAuthStore()
      try {
        if (authStore.usingBrandedModeToken) {
          const brandedStore = useBrandedStore()
          return `${brandedStore.brandedSettings.companyName} - ${state.pageName}`
        } else if (state.fabricator.siteTitle) {
          return `${state.fabricator.siteTitle} - ${state.pageName} - ${installationType}`;
        } else {
          return `${i18n.global.t(`title.Touch ${installationType}`)} - ${state.pageName} - ${installationType}`;
        }
      } catch (error) {
        return 'Loading';
      }
    },
  },
});
