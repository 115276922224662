<template>
  <div
    class="border mb-3 shadow rounded"
    :data-specification-group-id="uuid"
    :data-open-specification-group="!collapsed"
  >
    <button
      class="p-2 text-base w-full flex justify-between"
      :class="{ 'border-b': !collapsed }"
      @click="togglePanel()"
    >
      <div class="font-medium" :class="{ 'text-red-600 font-bold': isForcedQuestions }">
        {{ title }}
      </div>
      <span
        v-if="collapsed"
        class="text-gray-700 flex-1 text-right mx-4 truncate text-sm"
        :title="tickerDetail"
        v-html="ticker"
      ></span>
      <template v-if="!noCollapse">
        <i v-if="collapsed" class="fal fa-arrow-circle-down mt-1"></i>
        <i v-else class="fal fa-arrow-circle-up mt-1"></i>
      </template>
    </button>

    <animation-staggered-fade>
      <div v-show="!collapsed" :key="title" class="p-2 flex flex-col gap-2">
        <question
          v-for="specification in specificationList"
          :key="specification[0].optionHeadingId"
          :spec="specification"
          :loading="loading"
          @change="$emit('change', $event)"
          @component-highlight="$emit('component-highlight', $event)"
        />
      </div>
    </animation-staggered-fade>
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useDesignerStore } from '@/pinia/designer';
import Question from '@/components/shared/designer/SpecificationGroup/Question.vue';

let uuid = 0;

export default {
  components: {
    question: Question,
  },
  props: ['group', 'loading', 'selectionMode', 'noCollapse'],
  data() {
    return {
      uuid: undefined,
      openInSelection: true,
    };
  },
  computed: {
    ...mapStores(useDesignerStore),
    isForcedQuestions() {
      return this.designerStore.listOfUnansweredForcedQuestions.some(
        (spec) => spec.optionGroupName === this.title,
      );
    },
    ticker() {
      return Object.values(this.specificationList)
        .map((spec) => {
          try {
            return spec[0].mustBeSelected && !spec[0].hasBeenSelected
              ? `<span class="font-bold text-red-600">${spec[0].name}</span>`
              : spec[0].options.find((option) => option.isCurrentOption).description;
          } catch (e) {
            return false;
          }
        })
        .filter(Boolean)
        .join(', ');
    },
    tickerDetail() {
      return Object.values(this.specificationList)
        .map((spec) => {
          try {
            return spec[0].mustBeSelected && !spec[0].hasBeenSelected
              ? `${spec[0].name}: <not selected>`
              : `${spec[0].name}: ${spec[0].options.find((option) => option.isCurrentOption).description}`;
          } catch (e) {
            return false;
          }
        })
        .filter(Boolean)
        .join('\n');
    },
    collapsed() {
      if (this.noCollapse) {
        return false;
      }

      if (this.selectionMode) {
        return !this.openInSelection;
      }

      return this.designerStore.openSpecificationGroup !== this.uuid;
    },
    title() {
      return this.group[0];
    },
    specificationList() {
      return this.group[1];
    },
  },
  mounted() {
    this.uuid = uuid;
    uuid += 1;
  },
  unmounted() {
    this.designerStore.CLEAR_SHOW_SELECTION_FOR_HEADING_ID();
  },
  methods: {
    togglePanel() {
      if (this.selectionMode) {
        this.openInSelection = !this.openInSelection;
        return;
      }

      if (this.collapsed) {
        this.designerStore.setOpenSpecificationGroup(this.uuid);
      } else {
        this.designerStore.clearSpecificationGroup();
      }

      this.designerStore.CLEAR_SHOW_SELECTION_FOR_HEADING_ID();
    },
  },
};
</script>
