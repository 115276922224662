<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Customer Management', '/customer'],
          [`Customer (${customer.company.name})`, `/customer/${customer.customerId}`],
          ['Sales Dashboard'],
        ]"
      />
    </template>
    <template v-if="!selectedSandbox && customer && customer.company.name" #header_page_title>
      Sales Dashboard
      <span v-if="customer.financial.account_code">[{{ customer.financial.account_code }}]</span>
    </template>
    <Teleport v-if="!selectedSandbox" defer to="#portal_search">
      <SearchBox class="flex" placeholder="Search for reference..." />
    </Teleport>
    <template #header_page_actions>
      <div class="px-3 md:px-10 flex gap-4 items-center">
        <select
          v-if="sandboxes.length > 0"
          v-model="selectedSandbox"
          class="text-xs bg-gray-200 px-2 py-1 rounded-lg hidden md:inline"
        >
          <option :value="null">Select Sandbox</option>
          <option
            v-for="sandbox in sandboxes"
            :key="sandbox.id"
            :value="sandbox"
            v-text="sandbox.name"
          ></option>
        </select>
        <a v-if="selectedSandbox" class="btn hidden xs:inline" @click.stop.prevent="$router.push('sales-dashboard/new-test')">
          Create Test Using {{ selectedSandbox.name }}
        </a>
        <a
          v-if="selectedSandbox"
          class="btn-danger hidden xs:inline"
          @click.stop.prevent="acceptSandboxChanges"
        >
          Approve {{ selectedSandbox.name }} for Live
        </a>
        <div class="hidden xs:flex gap-4">
          <a class="btn-action" @click.stop.prevent="$router.push('sales-dashboard/new-quote')"> Create Quote </a>
          <a class="btn-action" @click.stop.prevent="$router.push('sales-dashboard/new-order')"> Create Order </a>
        </div>
        <div v-if="branches.length > 0">
          <FilterDropdown
            label="Branch"
            query-label="branchFilter"
            :filters="branches"
            :default-required="true"
          />
        </div>
      </div>
    </template>
    <Menu></Menu>
    <div id="customer_index_scrolling_outer_wrapper" class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch">
      <sales-dashboard-table
        v-if="customer"
        ref="salesDashboardTable"
        :customer-id="$route.params.id"
        :type="$route.query.type"
        :status="$route.query.status"
      >
        <template #tabs>
          <div class="flex flex-wrap-reverse 2xs:flex-nowrap">
            <div
              class="py-2 px-4 border border-b-0 cursor-pointer"
              :class="$route.query.type === 'order' && $route.query.status != 3 ? 'bg-white' : 'border-gray-400 bg-gray-300 text-gray-600'"
              @click="$router.push('/customer/' + $route.params.id + '/sales-dashboard?type=order').catch(() => { })"
            >
              <span class="hidden xs:inline">Show </span>Orders
            </div>
            <div
              class="py-2 px-4 border border-b-0 cursor-pointer"
              :class="$route.query.type === 'quote' ? 'bg-white' : 'border-gray-400 bg-gray-300 text-gray-600'"
              @click="$router.push('/customer/' + $route.params.id + '/sales-dashboard?type=quote').catch(() => { })"
            >
              <span class="hidden xs:inline">Show </span>Quotes
            </div>
            <div
              class="py-2 px-4 border border-b-0 cursor-pointer"
              :class="$route.query.type === 'order' && $route.query.status == 3 ? 'bg-white' : 'border-gray-400 bg-gray-300 text-gray-600'"
              @click="$router.push('/customer/' + $route.params.id + '/sales-dashboard?type=order&status=3').catch(() => { })"
            >
              <span class="hidden xs:inline">Show </span>Cancelled Orders
            </div>
            <div class="ml-auto hidden xs:flex">
              <QuoteStagesDropdown
                v-if="$route.query.type === 'quote'"
                class="border-b-0"
                placeholder="Any"
                @set-custom-quote-stage-id="(salesDashboardTable.customQuoteStageId = $event), salesDashboardTable.loadContracts()"
              />
            </div>
          </div>
        </template>
      </sales-dashboard-table>
    </div>
  </touch-layout>
</template>

<script>
import Menu from '@/components/business/customer/Menu.vue';
import SalesDashboardTable from '@/components/shared/SalesDashboardTable.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';
import { useBasketStore } from '@/pinia/basket';
import { useSandboxStore } from '@/pinia/sandbox';
import { useTouchStore } from '@/pinia/touch';
import SearchBox from '@/components/shared/SearchBox.vue';
import QuoteStagesDropdown from '@/components/shared/QuoteStagesDropdown.vue';
import FilterDropdown from '@/components/shared/FilterDropdown.vue';

export default {
  components: {
    Menu,
    SalesDashboardTable,
    Breadcrumbs,
    SearchBox,
    QuoteStagesDropdown,
    FilterDropdown
  },
  props: {
    customer: Object,
  },
  data() {
    return {
      loading: true,
      sandboxes: [],
      salesDashboardTable: undefined
    };
  },
  computed: {
    ...mapStores(useAuthStore, useBasketStore, useSandboxStore, useTouchStore),
    selectedSandbox: {
      get() {
        return this.sandboxStore.selectedSandbox;
      },
      set(value) {
        this.sandboxStore.SET_SANDBOX(value);
      },
    },
    branches() {
      return this.authStore.branches.map(x => ({
        id: x.branchCompanyId,
        name: x.name,
        isPrimary: x.isPrimary
      }))
    }
  },
  async mounted() {
    this.basketStore.setSalesSectorType(this.customer.customer.salesSectorType);
    if (this.authStore.admin) {
      this.sandboxes = await window.touch.testListSandboxes();
    }
    if (!this.$route.query.type || (!this.$route.query.type && !this.$route.query.status)) {
      this.$router.replace({ query: { ...this.$route.query, type: 'order' } }).catch(() => { })
    }
    this.loading = false;
    this.salesDashboardTable = this.$refs.salesDashboardTable
  },
  methods: {
    acceptSandboxChanges() {
      this.alertBox()
        .fire({
          title: 'Are you sure?',
          text: `Are you sure you want to approve this sandbox?  This will mark "${this.selectedSandbox.name}" as ready to be published to live customers.`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        })
        .then((result) => {
          if (result.value) {
            this.touchStore.testAcceptChanges(this.selectedSandbox.id)
              .then(async () => {
                this.alertBox().fire({
                  title: 'Approval submitted',
                  icon: 'success',
                });

                this.sandboxes = await window.touch.testListSandboxes();
                this.sandboxStore.SET_SANDBOX(null);
              })
              .catch(() => {
                window.alertBox.fire('Error submitting approval saving note');
              });
          }
        });
    },
  },
};
</script>
