import { useAuthStore } from '@/pinia/auth';
import { useTouchStore } from '@/pinia/touch';
import { useCustomerStore } from '@/pinia/customer';
import { useBasketStore } from '@/pinia/basket';

export default async (to, from, next) => {
  const authStore = useAuthStore()
  const customerStore = useCustomerStore()
  customerStore.freshdeskWidgetEnabled = false;
  const isLoginValid = await authStore.validateLogin('public')
  if (isLoginValid) {
    const basketStore = useBasketStore()
    if (from.name === null && basketStore.contractIdentifier?.contractId) {
      const touchStore = useTouchStore()
      await touchStore.setBrandedContract(basketStore.contractIdentifier.contractId);
    }
    next();
  } else {
    next({
      path: `/launch/${to.params.slug}`,
    });
  }
};
