import { defineStore } from 'pinia';
import { useBasketStore } from '@/pinia/basket';

export const useStockPartsStore = defineStore('stockParts', {
  persist: true,
  state: () => ({
    searchQuery: '',
    minimumQueryLength: 2,
    openStockPartSubcategory: undefined,
    openStockPartCategory: undefined,
    allStockParts: [],
    queriedStockParts: [],
    stockPartCategories: [],
    stockPartSubcategories: [],
    license: {
      status: undefined,
      lastChecked: undefined
    }
  }),
  actions: {
    initialize() {
      this.openStockPartSubcategory = undefined;
      this.searchQuery = '';
      this.openStockPartCategory = undefined;
      this.allStockParts = [];
      this.queriedStockParts = [];
      this.stockPartCategories = [];
      this.stockPartSubcategories = [];
    },
    setSearchQuery(query) {
      this.searchQuery = query;
    },
    setOpenStockPartSubcategory(subcategory) {
      this.openStockPartSubcategory = subcategory;
    },
    setOpenStockPartCategory(category) {
      this.openStockPartCategory = category;
    },
    setStockPartSubcategories(list) {
      this.stockPartSubcategories = list;
    },
    setStockParts(list) {
      this.allStockParts = list;
    },
    setQueriedStockParts({ query, results }) {
      if (this.searchQuery === query) {
        this.queriedStockParts = results;
      }
    },
    setStockPartCategories(list) {
      this.stockPartCategories = list;
    },
    updateItem({ itemId, quantity }) {
      const basketStore = useBasketStore()
      return basketStore.updateStockItemQty({itemId, quantity });
    },
    deleteItem(lineItemId) {
      const basketStore = useBasketStore()
      window.alertBox
        .fire({
          title: 'Are you sure?',
          text: 'Delete this stock item?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Continue',
        })
        .then((result) => {
          if (result.isConfirmed) {
            basketStore.deleteLineItem({ lineItemId });
          }
        });
    },
    updateQtyForItem({ itemId, quantity }) {
      const basketStore = useBasketStore()
      return basketStore.updateStockItemQty({ itemId, quantity });
    },
    async selectValidSubcategory() {
      if (
        !this.stockPartSubcategoriesForOpenCategory.some(
          (subcategory) => subcategory.id === this.openStockPartSubcategoryId,
        )
      ) {
        this.setOpenStockPartSubcategory(this.stockPartSubcategoriesForOpenCategory[0])
      }
    },
    async selectValidCategory() {
      if (
        !this.stockPartCategoriesWithSubcategories.some(
          (category) => category.id === this.openStockPartCategoryId
        )
      ) {
        this.setOpenStockPartCategory(this.stockPartCategoriesWithSubcategories[0])
      }
    },
    async loadItemsForSubcategory({ subCategoryId, customerId }) {
      let customerPrices;
      if (customerId) {
        customerPrices = await window.touch.stockListStockCustomerPrice(customerId);
      }
      let res = [];
      if (this.searchQuery) {
        res = this.activeStockParts.filter(
          (part) => part.subCategoryId === this.openStockPartSubcategoryId,
        );
      } else {
        res = await window.touch.stockListStockItems(subCategoryId);
      }
      res.forEach((r) => {
        const customerPricesForItem = customerPrices?.find((p) => p.stockItemId === r.id);
        if (customerPricesForItem) {
          // eslint-disable-next-line
          r.customerPrices = customerPricesForItem;
        } else {
          // eslint-disable-next-line
          r.customerPrices = {
            foc: undefined,
            nettPrice: undefined,
            customerId,
            id: undefined,
            stockItemId: r.id,
          };
        }
      });
      return res;
    },
    async loadStockPartSubcategories() {
      this.setStockPartSubcategories(await window.touch.stockListStockPartSubCategories())
    },
    async loadStockParts() {
      this.setStockParts(await window.touch.stockListStockParts())
    },
    async loadStockPartCategories() {
      this.setStockPartCategories(await window.touch.stockListStockPartCategories())
    },
    async runItemQuery(search) {
      this.setSearchQuery(search)
      if (
        this.searchQuery &&
        this.searchQuery.length >= this.minimumQueryLength
      ) {
        const stockListStockItems = await window.touch.stockListStockItems(undefined, search);
        this.setQueriedStockParts({
          query: this.searchQuery,
          results: stockListStockItems,
        });
      }
    },
  },
  getters: {
    activeStockParts(state) {
      if (!state.searchQuery || state.searchQuery.length < state.minimumQueryLength) {
        return state.allStockParts;
      }
      return state.queriedStockParts;
    },
    stockPartCategoriesWithSubcategories(state) {
      if (!state.searchQuery || state.searchQuery.length < state.minimumQueryLength) {
        return state.stockPartCategories;
      }
      return state.stockPartCategories.filter((category) =>
        this.stockPartSubcategoriesWithParts.some(
          (subcategory) => subcategory.partCategoryId === category.id,
        ),
      );
    },
    stockPartSubcategoriesWithParts(state) {
      if (!state.searchQuery || state.searchQuery.length < state.minimumQueryLength) {
        return state.stockPartSubcategories;
      }
      return state.stockPartSubcategories.filter((subcategory) =>
        this.activeStockParts.some((part) => part.subCategoryId === subcategory.id),
      );
    },
    stockPartSubcategoriesForOpenCategory(state) {
      try {
        return this.stockPartSubcategoriesWithParts.filter(
          (subcategory) => subcategory.partCategoryId === state.openStockPartCategory.id,
        );
      } catch (e) {
        return [];
      }
    },
    filteredStockParts(state) {
      try {
        return this.activeStockParts.filter(
          (part) => part.subCategoryId === state.openStockPartSubcategory.id,
        );
      } catch (e) {
        return [];
      }
    },
    openStockPartSubcategoryId(state) {
      if (!state.openStockPartSubcategory) {
        return null;
      }

      return state.openStockPartSubcategory.id;
    },
    openStockPartCategoryId(state) {
      if (!state.openStockPartCategory) {
        return null;
      }

      return state.openStockPartCategory.id;
    },
    cartItems() {
      const basketStore = useBasketStore()
      return basketStore.stockItems;
    },
  },
});
