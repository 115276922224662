<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Branch Management', '/branch'],
          [`Branch (${branch.name})`, `/branch/${branch.id}`],
          ['Details'],
        ]"
      />
    </template>
    <template #header_page_title>
      <span v-if="touch_business_user">Branch Details</span>
      <span v-else>My Details</span>
    </template>
    <branch-navigation-menu></branch-navigation-menu>
    <loading
      id="customer_index_scrolling_outer_wrapper"
      :loading="loading"
      class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch"
    >
      <edit-details-branch-details
        v-if="edit_mode_section === 'branch_details'"
        :branch="branch"
        :staff-contacts="staffContacts"
        :logged-in-user="loggedInUser"
        @save="saveBranchDetails()"
        @close="edit_mode_section = 'none'"
      ></edit-details-branch-details>
      <div class="flex flex-wrap bg-white border m-3 md:m-10 relative pr-16">
        <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
          <div class="font-bold">Branch</div>
          <div class="flex w-full">
            <div class="flex-grow">
              <div class="h-10 flex flex-col justify-around">{{ branch.name }}</div>
            </div>
          </div>
        </div>
        <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
          <div class="font-bold">Contact Number</div>
          <div class="h-10 flex flex-col justify-around">{{ branch.phone }}</div>
        </div>
        <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
          <div class="font-bold">Email</div>
          <div class="h-10 flex flex-col justify-around break-words">{{ branch.email }}</div>
        </div>
        <div class="absolute bottom-0 right-0 p-5">
          <div
            v-if="edit_mode_section === 'branch_details'"
            class="px-2 py-1 text-2xs rounded text-gray-600 border bordergray-600 cursor-pointer hover:text-white hover:bg-gray-600"
            @click="edit_mode_section = 'none'"
          >
            <i class="fa fa-times"></i>
          </div>
          <div
            v-if="edit_mode_section !== 'branch_details'"
            class="px-2 py-1 text-2xs rounded text-green-600 border border-green-600 cursor-pointer hover:text-white hover:bg-green-600"
            @click="edit_mode_section = 'branch_details'"
          >
            <i class="fa fa-pencil"></i>
          </div>
        </div>
      </div>
    </loading>
  </touch-layout>
</template>

<script>
import validate from 'validate.js';
import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';
import { useCustomerStore } from '@/pinia/customer';
import BranchNavigationMenu from '@/components/business/branch/BranchNavigationMenu.vue';
import EditDetailsBranchDetails from '@/components/business/branch/EditBranchDetails.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';

export default {
  components: {
    Breadcrumbs,
    'edit-details-branch-details': EditDetailsBranchDetails,
    BranchNavigationMenu,
  },
  data() {
    return {
      loggedInUser: {
        id: 0,
        isAdmin: false,
      },
      branch: {
        company: {},
        customer: {},
      },
      staffContacts: [],
      loading: true,
      edit_mode_section: 'none',
      limit: 20,
      offset: 0,
    };
  },
  computed: {
    ...mapStores(useAuthStore, useCustomerStore),
    hasDebugToggle() {
      return typeof this.customer?.customer?.debug !== 'undefined';
    },
  },
  async mounted() {
    await this.loadBranch();
    await this.loadStaffContacts();
  },
  methods: {
    async loadBranch() {
      this.loading = true;
      const response = await this.customerStore.customerGetCompany(parseInt(this.$route.params.id, 10));
      this.branch = response.data;
      if (this.authStore.loggedIn) {
        this.loggedInUser.id = this.authStore.id;
        this.loggedInUser.isAdmin = this.authStore.admin;
      }
      this.loading = false;
    },
    async loadStaffContacts() {
      this.staffContacts = await this.customerStore.customerListContacts();
    },
    async saveBranchDetails() {
      return this.saveSettings(() =>
        validate(
          {
            name: this.branch.name,
            email: this.branch.email,
            phone: this.branch.phone,
          },
          {
            name: {
              presence: { allowEmpty: false },
              length: {
                maximum: 64,
              },
            },
            phone: {
              presence: { allowEmpty: false },
              length: {
                maximum: 64,
              },
            },
            email: {
              presence: { allowEmpty: false },
              length: {
                maximum: 255,
              },
              email: true,
            },
          },
        ),
      );
    },
    async saveSettings(validation) {
      this.loading = true;
      const validationErrors = validation();

      if (validationErrors) {
        this.loading = false;
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        return;
      }
      await this.customerStore.customerBranchUpdate(this.branch).then(async () => {
        this.loading = false;
        this.edit_mode_section = 'none';
        await this.loadBranch();
      });
    },
  },
};
</script>
