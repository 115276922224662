<template>
  <div v-if="stockPartsStore.openStockPartSubcategory" class="border border-gray-300 p-6 rounded-lg">
    <div class="xs:text-base mb-3">
      Stock Part Details - {{ stockPartsStore.openStockPartSubcategory.description }}
    </div>
    <div v-for="stockPart in itemsGroupedByPart" :key="stockPart[0].partId" class="mb-1">
      <div class="text-center p-2 w-full bg-green-600 text-white rounded-t">
        {{ stockPart[0].part }}
      </div>
      <stock-part-items-for-part :items="stockPart"></stock-part-items-for-part>
    </div>
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useStockPartsStore } from '@/pinia/stock-parts';
import StockPartItemsForPart from '@/components/shared/chooseProduct/StockPartItemsForPart.vue';
import _ from 'lodash';

export default {
  components: {
    'stock-part-items-for-part': StockPartItemsForPart,
  },
  props: ['openSubcategoryId'],
  data() {
    return {
      stockItems: [],
    };
  },
  computed: {
    ...mapStores(useStockPartsStore),
    itemsGroupedByPart() {
      return Object.values(_.groupBy(this.stockItems, (item) => item.partId));
    },
  },
  watch: {
    openSubcategoryId: {
      async handler() {
        this.stockItems = await this.stockPartsStore.loadItemsForSubcategory({
          subCategoryId: this.openSubcategoryId,
        });
      },
      immediate: true,
    },
    'stockPartsStore.searchQuery': {
      async handler(to) {
        if (to === '') {
          this.stockItems = await this.stockPartsStore.loadItemsForSubcategory({
            subCategoryId: this.openSubcategoryId,
          });
        }
      },
    }
  },
};
</script>
