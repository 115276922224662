<template>
  <touch-layout :page_override_class="{ 'trade-designer-choose-product': true }">
    <template #header_page_breadcrumb>
      <Breadcrumbs :routes="[['Dashboard', '/'], ['Choose Product'], [description]]" />
    </template>
    <template #header_page_title>
      <div>
        <template v-if="basketStore.currentItem && currentImageType">
          <template v-if="!designerStore.somethingIsSelected">
            {{ description }}
            <HelpPoint v-if="isBowOrBayWindow" class="ml-2" freshdesk-article-id="101000456240" />
          </template>
          <template v-if="designerStore.somethingIsSelected">Edit Individual Components</template>
        </template>
      </div>
    </template>
    <Designer
      v-if="basketStore.currentItem"
      :item="basketStore.currentItem"
      :something-is-selected="designerStore.somethingIsSelected"
      :current-image-type="currentImageType"
      @set-current-item="setCurrentItem"
      @set-breakdown="setCurrentItem"
      @set-current-image-type-parent="currentImageType = $event"
    />
  </touch-layout>
</template>

<script>
import Designer from '@/components/shared/designer/Designer.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import HelpPoint from '@/components/shared/HelpPoint.vue';
import { mapStores } from 'pinia'
import { useDesignerStore } from '@/pinia/designer';
import { useBasketStore } from '@/pinia/basket';

export default {
  components: {
    Designer,
    Breadcrumbs,
    HelpPoint,
  },
  props: {
    defaultDesignView: {
      default: undefined,
      type: Number,
    },
  },
  data() {
    return {
      customerId: undefined,
      description: undefined,
      customer: {
        customerId: null,
      },
      currentImageType: null
    };
  },
  computed: {
    ...mapStores(useBasketStore, useDesignerStore),
    isBowOrBayWindow() {
      return (
        this.basketStore.currentItem.images.find((image) => image.type === window.enum.imageType.PLAN) !== undefined
      );
    },
  },
  async mounted() {
    this.currentImageType = null;

    if (!this.basketStore.hasContract) {
      this.routerPush('/new-quote');
      return;
    }

    this.designerStore.SET_OPEN_MENU_TYPE('sizes');
    this.designerStore.CLEAR_SELECTED_COMPONENTS()
    this.designerStore.clearSpecificationGroup()
    this.designerStore.CLEAR_SHOW_SELECTION_FOR_HEADING_ID()

    const item = await this.basketStore.loadItem({
      itemKey: this.$route.params.id,
      customerID: this.$route.params.customerId,
    });

    if (item) {
      await this.setCurrentItem(item);
      this.description = this.basketStore.currentItem.description;
    } else {
      this.alertBox().fire({
        title: 'Item not found.',
        showCancelButton: false,
        showConfirmButton: false,
        html: `
          <button id="back" class="btn m-1">Go Back</button>
          <button id="new-quote" class="btn-action m-1">New Quote</button>
          <button id="new-order" class="btn-action m-1">New Order</button>
        `,
        onBeforeOpen: () => {
          document.getElementById('back').addEventListener('click', () => {
            this.alertBox().close();
            this.$router.go(-1);
          });
          document.getElementById('new-quote').addEventListener('click', () => {
            this.routerReplace('/new-quote');
            this.alertBox().close();
          });
          document.getElementById('new-order').addEventListener('click', () => {
            this.routerReplace('/new-order');
            this.alertBox().close();
          });
        },
      });
      return;
    }

    if (typeof this.defaultDesignView !== 'undefined') {
      this.currentImageType = this.defaultDesignView;
    } else {
      this.currentImageType = this.basketStore.currentItem.images[0].type;
      if (
        this.basketStore.currentItem.images.find((image) => image.type === window.enum.imageType.PLAN) !== undefined
      ) {
        this.currentImageType = window.enum.imageType.EXTERNAL;
      }
    }
  },
  methods: {
    async setCurrentItem(item) {
      this.basketStore.SET_CURRENT_ITEM(item);
    },
  },
};
</script>
