<template>
  <div class="p-2 h-10 w-full border flex gap-2 items-center bg-white">
    <div class="flex-shrink-0">
      <span> {{ placeholder }}:</span>
    </div>
    <select
      :ref="'select-' + placeholder"
      v-model="staffId"
      class="flex-auto font-bold text-right"
      :title="staffId === 0 ? 'None' : getFullName(contacts.find(x => x.id === staffId))"
      required
      @change="addStaffToContract(staffId)"
      @click="isActive = !isActive"
    >
      <option :value="0">None</option>
      <option v-for="contact in contacts" :key="contact.id" :title="getFullName(contact)" class="font-bold" :value="contact.id">
        {{ truncateText(getFullName(contact), 20) }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    role: Number,
    placeholder: String,
    contractId: String,
    jobKey: Number,
    currentStaffId: Number,
  },
  data() {
    return {
      staffId: 0,
      contacts: [],
      isActive: false,
    };
  },
  async mounted() {
    if (this.currentStaffId) {
      this.staffId = this.currentStaffId;
    }
    this.contacts = await window.touch.ListOrganisationContacts(this.role);
    if (this.currentStaffId !== 0 && !this.contacts.some((x) => x.id === this.currentStaffId)) {
      const customer = await window.touch.customerGetContact(this.currentStaffId);
      this.contacts.push(customer);
    }
  },
  methods: {
    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
      }
      return text;
    },
    getFullName(contact) {
      return `${contact?.lastName} ${contact?.lastName}`
    },
    async addStaffToContract(staffId) {
      await window.touch.contractAddStaffToContract(
        this.contractId,
        this.jobKey,
        this.role,
        staffId,
      );
    },
  },
};
</script>
