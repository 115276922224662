
// eslint-disable-next-line class-methods-use-this
export function newOrders() {
  return new Promise((resolve) => setTimeout(() => resolve([]), 250 + Math.random() * 1000));
}

// eslint-disable-next-line class-methods-use-this
export function newTickets() {
  return new Promise((resolve) => setTimeout(() => resolve([]), 250 + Math.random() * 1000));
}
