
function ytRegex(url) {
  return url.match(/.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/);
}

function vimeoRegex(url) {
  return url.match(/\/([0-9]+)$/);
}

export function getYoutubeThumbnail(url) {
  const id = this.ytRegex(url)[1];
  return `https://img.youtube.com/vi/${id}/sddefault.jpg`;
}

export function getVimeoThumbnail(url) {
  const id = vimeoRegex(url)[1];
  return `https://vumbnail.com/${id}_medium.jpg`;
}

export function getYoutubeEmbedUrl(videoUrl) {
  const urlBreakdown = ytRegex(videoUrl);
  if (urlBreakdown.length <= 1) {
    return undefined;
  }
  return `https://www.youtube.com/embed/${ytRegex(videoUrl)[1]}`;
}

export function getVideoType(url) {
  if (this.ytRegex(url)) {
    return this.enums.documentVideoType.YOUTUBE;
  }

  if (this.vimeoRegex(url)) {
    return this.enums.documentVideoType.VIMEO;
  }

  return this.enums.documentVideoType.NONE;
}