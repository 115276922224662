<template>
  <touch-layout>
    <create-staff v-if="add_staff_popup_open" @close="add_staff_popup_open = false" @saved="loadBranch"></create-staff>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Branch Management', '/branch'],
          [`Branch (${branch.name})`, `/branch/${branch.id}`],
          ['Staff & Licenses'],
        ]"
      />
    </template>
    <template #header_page_title> Branch Staff & Licenses </template>
    <template v-if="!loading" #header_page_actions>
      <div class="px-3 md:px-10 flex items-center">
        <a
          v-if="!demo_mode && (touch_business_user || loggedInUser.isAdmin)"
          class="btn-action"
          @click.stop.prevent="add_staff_popup_open = true"
        >
          Add Staff
        </a>
      </div>
    </template>
    <branch-navigation-menu></branch-navigation-menu>
    <loading
      id="customer_index_scrolling_outer_wrapper"
      :loading="loading"
      class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch"
    >
      <div class="bg-white border m-3 md:m-10 p-3">
        <table v-if="users.length > 0" class="table-lined xs:table-unstacked _with-hover w-full">
          <thead>
            <tr>
              <th colspan="2">Name</th>
              <th>Email</th>
              <th>Mobile</th>
              <th>License Status</th>
              <th>Admin</th>
              <th v-if="touch_business_user || loggedInUser.isAdmin"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(user, index) in users"
              :key="index"
              :class="{ _hovered: hovered === index }"
              @click.stop.prevent="editStaff(user.id)"
              @mouseover="hovered = index"
              @mouseleave="hovered = null"
            >
              <td style="width: 60px">
                <div
                  class="rounded-full border flex flex-col justify-around overflow-hidden relative"
                  style="width: 35px; height: 35px"
                >
                  <img
                    v-if="user.image"
                    :id="'userImg_' + user.id"
                    :src="staffImageUrl(user.image)"
                    class="m-auto"
                    @error="imgError"
                    @load="positionAvatar"
                  />
                </div>
              </td>
              <td>
                <div class="td-title w-24">Name:</div>
                <div class="td-content">{{ user.firstName }} {{ user.lastName }}</div>
              </td>
              <td>
                <div class="td-title w-24">Email:</div>
                <div class="td-content break-all">{{ user.email }}</div>
              </td>
              <td>
                <div class="td-title w-24">Mobile:</div>
                <div class="td-content">{{ user.mobile }}</div>
              </td>
              <td>
                <div class="td-title w-24">License Status:</div>
                <div class="td-content">
                  <template v-if="user.licenseStatusType === enums.licenseStatusType.ENABLED">
                    Enabled
                  </template>
                  <template v-else> Disabled </template>
                </div>
              </td>
              <td>
                <div class="td-title w-24">Admin:</div>
                <div class="td-content">
                  <template v-if="user.isAdmin"> Yes </template>
                  <template v-else> No </template>
                </div>
              </td>
              <td class="td-action-buttons">
                <div v-if="touch_business_user || loggedInUser.isAdmin" class="table-edit-btn">
                  <i class="fa fa-pencil"></i>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else>No Staff to Show</div>
      </div>
    </loading>
    <edit-staff
      v-if="editing_user"
      :user_id="editing_user"
      :customer-id="customerId"
      :logged-in-user="loggedInUser"
      @close="editing_user = null"
      @user-saved="loadBranch"
    ></edit-staff>
  </touch-layout>
</template>

<script>

import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';
import { useCustomerStore } from '@/pinia/customer';
import BranchNavigationMenu from '@/components/business/branch/BranchNavigationMenu.vue';
import EditStaff from '@/views/Business/ManageTouchAccount/Staff/Edit.vue';
import CreateStaff from '@/views/Business/ManageTouchAccount/Staff/Create.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';

export default {
  components: {
    BranchNavigationMenu,
    EditStaff,
    CreateStaff,
    Breadcrumbs,
  },
  data() {
    return {
      loggedInUser: {
        id: 0,
        isAdmin: false,
      },
      branch: {},
      users: [],
      loading: true,
      hovered: null,
      editing_user: null,
      add_staff_popup_open: false,
      options: {
        limit: 20,
        offset: 0,
      },
      customerId: 0,
    };
  },
  computed: {
    ...mapStores(useAuthStore, useCustomerStore),
  },
  async mounted() {
    await this.loadBranch();
  },
  methods: {
    async loadBranch() {
      this.loading = true;
      const response = await this.customerStore.customerGetCompany(this.$route.params.id);
      this.branch = response.data;
      const staffResponse = await this.customerStore.staffListStaff({
        options: this.options,
        branchCompanyId: this.branch.id,
      });
      this.users = staffResponse.staff;
      this.customerId = await window.touch.staffGetOrganisationCustomerId();
      if (this.authStore.loggedIn) {
        this.loggedInUser.id = this.authStore.id;
        this.loggedInUser.isAdmin = this.authStore.admin;
      }
      this.loading = false;
    },
    staffImageUrl(image) {
      return window.touch.imageBaseURL + image;
    },
    imgError(event) {
      const element = document.getElementById(event.target.id);
      element.src = '/images/blank-user.png';
    },
    editStaff(userId) {
      if (this.touch_business_user || this.loggedInUser.isAdmin) {
        this.editing_user = userId;
      }
    },
  },
};
</script>
