<template>
  <Teleport defer to="#portal_popup">
    <modal-window
      v-if="modal_open"
      :modal_open="true"
      title="Edit Staff Member"
      @close="$emit('close')"
    >
      <div class="text-green-600 pl-3 flex flex-col justify-around font-medium text-base">
        * Required Fields
      </div>
      <div class="flex flex-wrap mt-5">
        <div class="w-full xs:w-1/2 xs:pr-5">
          <div class="font-medium">First Name <span class="text-green-600">*</span></div>
          <input v-model="user.firstName" type="text" class="form-control" />
        </div>
        <div class="w-full mt-4 xs:w-1/2 xs:pl-5 xs:mt-0">
          <div class="font-medium">Last Name <span class="text-green-600">*</span></div>
          <input v-model="user.lastName" type="text" class="form-control" />
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full mt-5 xs:w-1/2 xs:pr-5 xs:mt-8">
          <div class="font-medium">Email <span class="text-green-600">*</span></div>
          <input v-model="user.email" type="text" disabled class="form-control text-gray-400" />
          <a
            v-if="!user.hasBeenActivated && user.email === userEmail"
            :class="activationEmailResent
              ? 'text-gray-600 cursor-not-allowed'
              : 'text-green-600 cursor-pointer'"
            @click.prevent.stop="resendActivationEmail"
          >
            {{ activationEmailResent ? 'Activation Email Resent' : 'Resend Activation Email' }}
          </a>
          <span
            v-if="!user.hasBeenActivated && user.email !== userEmail"
            class="text-gray-600 cursor-not-allowed"
          >
            Must save user before resending activation
          </span>
        </div>
        <div class="w-full mt-5 xs:w-1/2 xs:pl-5 xs:mt-8">
          <div class="font-medium">Mobile</div>
          <input v-model="user.mobile" type="text" class="form-control" />
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full mt-5 xs:w-1/2 xs:pr-5 xs:mt-8">
          <div class="font-medium">Departments <span class="text-green-600">*</span></div>
          <select
            v-if="user.departments"
            v-model="user.departments"
            multiple
            class="form-control multiple"
          >
            <option
              v-for="department in touchStore.contactDepartments"
              :key="department.id"
              :value="department.id"
            >
              {{ department.name }}
            </option>
          </select>
        </div>
        <div class="w-full mt-5 xs:w-1/2 xs:pl-5 xs:mt-8">
          <ContactRoles
            :roles="user.roles"
            @set-contact-roles="$event === 0 ? (user.roles = $event) : (user.roles += $event)"
          />
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full mt-5 xs:w-1/2 xs:pr-5 xs:mt-8">
          <div class="font-medium">Processing Level <span class="text-green-600">*</span></div>
          <select v-model="user.processingLevelId" class="form-control">
            <option disabled="disabled">Please Select</option>
            <option
              v-for="processingLevel in touchStore.processingLevels"
              :key="processingLevel.id"
              :value="processingLevel.id"
            >
              {{ processingLevel.name }}
            </option>
          </select>
        </div>
        <div v-if="authStore.isBranchManagerAvailable" class="w-full mt-5 xs:w-1/2 xs:pl-5 xs:mt-8">
          <Branch :available-branches="branches" :branches="user.branches" @set-branches="user.branches = $event" />
        </div>
      </div>
      <div class="w-full mt-4 xs:w-1/2 xs:pr-5 xs:mt-8">
        <div class="w-full">
          <Dropzone
            :removable="false"
            :current_file="staffImageUrl(user.image)"
            @add-file="new_user_image = $event"
            @remove-file="new_user_image = null"
          ></Dropzone>
        </div>
        <div class="mt-1">Photos help your teammates recognise you in TOUCH.</div>
      </div>
      <div class="flex gap-5">
        <div class="flex flex-col">
          <div class="flex flex-col justify-around h-10 md:h-20 lg:h-10 mt-3">
            <div
              class="flex items-center cursor-pointer"
              @click.prevent="user.isAdmin = !user.isAdmin"
            >
              <div class="relative">
                <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner"></div>
                <div class="switch-toggle" :class="{ _active: user.isAdmin }"></div>
              </div>
              <div class="ml-2 text-gray-700 font-medium whitespace-nowrap w-32">
                <span v-text="user.isAdmin ? 'Admin User' : 'Non Admin User'"></span>
              </div>
            </div>
          </div>
          <div
            v-show="notEditingSelf"
            class="flex flex-col justify-around h-10 md:h-20 lg:h-10 mt-3"
          >
            <div
              class="flex items-center cursor-pointer"
              @click.prevent="toggleUserLicenseStatusType()"
            >
              <div class="relative">
                <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner"></div>
                <div
                  class="switch-toggle"
                  :class="{ _active: user.licenseStatusType === enums.licenseStatusType.ENABLED }"
                ></div>
              </div>
              <div class="ml-2 text-gray-700 font-medium whitespace-nowrap">
                Account
                <span
                  v-text="user.licenseStatusType === enums.licenseStatusType.ENABLED
                    ? 'Enabled'
                    : 'Disabled'"
                ></span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col">
          <div class="flex flex-col justify-around h-10 md:h-20 lg:h-10 mt-3">
            <div
              class="flex items-center cursor-pointer"
              @click.prevent="toggleTicketContactStatus()"
            >
              <div class="relative">
                <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner"></div>
                <div class="switch-toggle" :class="{ _active: isTicketContact }"></div>
              </div>
              <div class="ml-2 text-gray-700 font-medium whitespace-nowrap">
                This user <span v-text="isTicketContact ? 'is' : 'is not'"></span> a ticket contact
              </div>
            </div>
          </div>
          <div
            v-show="isTicketContact"
            class="flex flex-col justify-around h-10 md:h-20 lg:h-10 mt-3"
          >
            <div
              class="flex items-center cursor-pointer"
              @click.prevent="toggleDefaultContactStatus()"
            >
              <div class="relative">
                <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner"></div>
                <div class="switch-toggle" :class="{ _active: isDefaultTicketContact }"></div>
              </div>
              <div class="ml-2 text-gray-700 font-medium whitespace-nowrap">
                This user <span v-text="isDefaultTicketContact ? 'is' : 'is not'"></span> the
                default contact
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col">
          <div class="flex flex-col justify-around h-10 md:h-20 lg:h-10 mt-3">
            <div
              class="flex items-center cursor-pointer"
              @click.prevent="user.noLogin = !user.noLogin"
            >
              <div class="relative">
                <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner"></div>
                <div class="switch-toggle" :class="{ _active: user.noLogin }"></div>
              </div>
              <div class="ml-2 text-gray-700 font-medium whitespace-nowrap">No Login</div>
            </div>
          </div>
          <div class="flex flex-col justify-around h-10 md:h-20 lg:h-10 mt-3">
            <div
              class="flex items-center cursor-pointer"
              @click.prevent="user.archived = !user.archived"
            >
              <div class="relative">
                <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner"></div>
                <div class="switch-toggle" :class="{ _active: user.archived }"></div>
              </div>
              <div class="ml-2 text-gray-700 font-medium whitespace-nowrap">Archived</div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
        <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
          Cancel
        </button>
        <button class="btn-action btn-lg" @click.prevent.stop="saveUser()">Save Changes</button>
      </div>
    </modal-window>
  </Teleport>
</template>

<script>
import validate from 'validate.js';
import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';
import { useCustomerStore } from '@/pinia/customer';
import { useTouchStore } from '@/pinia/touch';
import Dropzone from '@/components/shared/Dropzone.vue';
import ContactRoles from '@/components/shared/ContactRoles.vue';
import Branch from '@/components/business/manageTouchAccount/staff/branch.vue';

export default {
  components: {
    Dropzone,
    ContactRoles,
    Branch
  },
  props: ['user_id'],
  data() {
    return {
      user: {
        departments: [],
      },
      userEmail: '',
      loading: true,
      modal_open: false,
      user_image: null,
      new_user_image: null,
      isTicketContact: false,
      isDefaultTicketContact: false,
      activationEmailResent: false,
      options: {
        limit: 20,
        offset: 0,
      },
      branches: [],
    };
  },
  computed: {
    ...mapStores(useAuthStore, useCustomerStore, useTouchStore),
    editingSelf() {
      return this.user.id === this.authStore.id;
    },
    notEditingSelf() {
      return !this.editingSelf;
    },
  },
  async mounted() {
    this.loading = true;
    this.touchStore.loadProcessingLevels();
    this.loadUser(this.user_id);
    const response = await this.customerStore.customerListBranches(this.options);
    this.branches = response.branches;
    const orgDetails = await window.touch.organisationGet();
    this.isTicketContact =
      orgDetails.users.find((x) => x.id === this.user_id)?.isTicketContact ?? false;
    this.isDefaultTicketContact = orgDetails.company.defaultTicketContact === this.user_id;
    this.loading = false;
    this.modal_open = true;
  },
  methods: {
    resendActivationEmail() {
      if (this.activationEmailResent) {
        return;
      }
      this.activationEmailResent = true;
      window.touch.resendActivationEmail(this.user.id);
    },
    async toggleTicketContactStatus() {
      this.loading = true;
      this.isTicketContact = !this.isTicketContact;
      await window.touch.organisationUpdate(this.customerStore.customer?.companyId, [
        {
          id: this.user_id,
          isTicketContact: this.isTicketContact,
        },
      ]);
      this.loading = false;
    },
    async toggleDefaultContactStatus() {
      const setContact = async () => {
        this.loading = true;
        this.isDefaultTicketContact = !this.isDefaultTicketContact;
        await window.touch.organisationUpdate(this.customerStore.customer?.companyId, [], this.user_id);
        this.loading = false;
      };
      if (!this.isDefaultTicketContact) {
        await this.alertBox()
          .fire({
            title: 'Warning!',
            text: 'There can only be one default ticket contact. This user will now be the only default contact for users without a bespoke contact set. Continue?',
            showDenyButton: true,
            confirmButtonText: 'Continue',
            denyButtonText: 'Cancel',
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              await setContact();
            }
          });
      } else {
        await setContact();
      }
    },
    async loadUser(id) {
      const resp = await this.customerStore.customerGetContact(id);
      this.user = resp;
      this.userEmail = this.user.email;
      this.user_image = resp.image;
    },
    async saveUser() {
      this.loading = true;
      const validationErrors = validate(
        {
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          email: this.user.email,
        },
        {
          firstName: {
            presence: { allowEmpty: false },
            length: {
              maximum: 32,
            },
          },
          lastName: {
            presence: { allowEmpty: false },
            length: {
              maximum: 32,
            },
          },
          email: {
            presence: { allowEmpty: false },
            email: true,
            length: {
              maximum: 255,
            },
          },
        },
      );

      if (validationErrors) {
        this.alertBox().fire({
          title: 'Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        return;
      }

      if (this.user.branches.length > 0 && this.user.isAdmin) {
        this.alertBox().fire({
          title: 'Branch users are non-admins',
        });
        this.loading = false;
        return;
      }

      if (!this.customerStore.customer.users.some(x => !x.archived && x.licenseStatusType === this.enums.licenseStatusType.ENABLED)) {
        this.alertBox().fire({
          title: 'At least one staff member must not be archived.',
        });
        this.loading = false;
        return;
      }

      await this.customerStore.customerEditContact({
        ...this.user,
        image: this.new_user_image !== null ? this.new_user_image : undefined,
      }).then(() => {
        this.modal_open = false;
        this.loading = false;
        this.$emit('updated');
        this.$emit('close');
      });
    },
    staffImageUrl(image) {
      if (image !== undefined) {
        return window.touch.imageBaseURL + image;
      }
      return '/images/blank-user.png';
    },
    toggleUserLicenseStatusType() {
      if (this.user.licenseStatusType === window.enum.licenseStatusType.ENABLED) {
        this.user.licenseStatusType = window.enum.licenseStatusType.DISABLED;
      } else {
        this.user.licenseStatusType = window.enum.licenseStatusType.ENABLED;
      }
    },
  },
};
</script>
