<template>
  <Teleport defer to="#portal_popup">
    <modal-window :modal_open="true" title="Add New Video" @close="$emit('close')">
      <ul>
        <li v-for="(error, i) in errors" :key="i" class="text-red-600 font-bold">{{ error }}</li>
      </ul>
      <div class="flex flex-wrap -mx-3 mb-3">
        <div class="p-3 w-full 2xs:w-1/2">
          <div class="font-medium">Name</div>
          <div>
            <input v-model="video.name" class="form-control" />
          </div>
        </div>
        <div class="p-3 w-full 2xs:w-1/2">
          <div class="font-medium">Category</div>
          <div>
            <select v-model="video.categoryId" class="form-control">
              <option v-for="category in categories" :key="category.id" :value="category.id">
                {{ category.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap -mx-3 mb-3">
        <div class="p-3 w-full 2xs:w-1/2">
          <div class="font-medium">Video URL</div>
          <div>
            <input v-model="video.url" class="form-control" />
          </div>
        </div>
        <div class="p-3 w-full 2xs:w-1/2">
          <div class="font-medium">Visible to?</div>
          <div>
            <select v-model="video.DealerNetworkPartnerOnly" class="form-control">
              <option :value="false">All Customers</option>
              <option :value="true">Dealer Network Partner Only</option>
            </select>
          </div>
        </div>
      </div>
      <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
        <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
          Cancel
        </button>
        <button class="btn-action btn-lg" @click.prevent.stop="save()">Save</button>
      </div>
    </modal-window>
  </Teleport>
</template>

<script>

import { getVideoType } from '@/composables/video';

export default {
  props: ['categories'],
  data() {
    return {
      video: {
        name: '',
        categoryId: null,
        videoType: window.enum.documentVideoType.NONE,
        type: window.enum.documentType.RESOURCE_VIDEO,
        DealerNetworkPartnerOnly: false,
        url: '',
      },
      saving: false,
      errors: [],
    };
  },
  methods: {
    async save() {
      this.errors = [];

      if (this.video.CategoryId === null) {
        this.errors.push('Please choose a Video Category');
        return;
      }

      if (this.video.VideoId === null) {
        this.errors.push('Please add a video id');
        return;
      }

      if (getVideoType(this.video.url) === this.enums.documentVideoType.NONE) {
        this.errors.push('We only support youtube or vimeo URLs.');
        return;
      }

      this.saving = true;
      await window.touch.customerUploadDocumentWithUrl(this.video).then(() => {
        this.saving = false;
        this.$emit('added');
        this.$emit('close');
      });
    },
  },
};
</script>
