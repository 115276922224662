<template>
  <div>
    <Teleport v-if="editing" defer to="#portal_popup"> 
      <modal-window :modal_open="editing" title="Edit Company Details" @close="editing = false">
        <Update @close="editing = false"></Update>
      </modal-window>
    </Teleport>
    <div class="flex flex-wrap bg-white border m-5 xs:m-10 relative pr-16">
      <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4 flex flex-col gap-2">
        <div class="font-bold">Company</div>
        <div class="flex w-full items-center">
          <div
            v-if="!company_logo_missing"
            class="rounded-full border flex flex-col justify-around overflow-hidden relative mr-3"
            style="width: 35px; height: 35px"
          >
            <img
              :id="'fabricator_details_logo'"
              :src="style.fabricator_logo"
              class="m-auto"
              @error="company_logo_missing = true"
              @load="positionAvatar"
            />
          </div>
          <div class="flex-grow">
            <div class="flex flex-col justify-around">{{ fabricator.name }}</div>
          </div>
        </div>
      </div>
      <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4 flex flex-col gap-2">
        <div class="font-bold">Contact Number</div>
        <div class="flex flex-col justify-around">{{ fabricator.phone }}</div>
      </div>
      <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4 flex flex-col gap-2">
        <div class="font-bold">Email</div>
        <div class="flex flex-col justify-around">{{ fabricator.email }}</div>
      </div>
      <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4 flex flex-col gap-2">
        <div class="font-bold">Website</div>
        <div class="flex flex-col justify-around">{{ fabricator.web }}</div>
      </div>
      <div v-if="customer.eCommerceIntegration.companyRegNumber" class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4 flex flex-col gap-2">
        <div class="font-bold">Company Registration Number</div>
        <div class="flex flex-col justify-around">
          {{ customer.eCommerceIntegration.companyRegNumber }}
        </div>
      </div>
      <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4 flex flex-col gap-2">
        <div class="font-bold">Address</div>
        <div class="flex flex-col justify-around">
          {{ customer.eCommerceIntegration.addressId ? formattedAddress(selected_address) : "You have no saved addresses" }}
        </div>
      </div>
      <div class="absolute bottom-0 right-0 p-5">
        <div
          v-if="is_admin_user"
          class="px-2 py-1 text-2xs rounded text-green-600 border border-green-600 cursor-pointer hover:text-white hover:bg-green-600"
          @click="editing = true"
        >
          <i class="fa fa-pencil"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Update from '@/components/business/manageTouchAccount/companyDetails/Update.vue';
import { mapStores } from 'pinia'
import { useCustomerStore } from '@/pinia/customer';
import { useStyleStore } from '@/pinia/style';

export default {
  components: {
    Update,
  },
  data() {
    return {
      editing: false,
      company_logo_missing: false,
      show_address_popup: false,
    };
  },
  computed: {
    ...mapStores(useCustomerStore, useStyleStore),
    fabricator() {
      return this.styleStore.fabricator
    },
    customer() {
      return this.customerStore.customer
    },
    selected_address() {
      return this.customer.addresses.filter(
        (address) => address.id === this.customer.eCommerceIntegration.addressId,
      )[0];
    },
  },
  methods: {
    formattedAddress(address) {
      return [
        address.line1,
        address.line2,
        address.line3,
        address.town,
        address.county,
        address.code,
      ]
        .filter((val) => val)
        .join(', ');
    },
  }
};
</script>
