<template>
  <Teleport defer to="#portal_popup">
    <modal-window
      :modal_open="create_tax_rate_popup_open"
      title="Add Tax Rate"
      @close="$emit('close')"
    >
      <div class="flex flex-wrap">
        <div class="w-full xs:w-1/2 xs:pr-5">
          <div class="font-medium">Name</div>
          <input v-model="tax_rate.name" class="form-control" />
        </div>
        <div class="w-full mt-4 xs:w-1/2 xs:pl-5 xs:mt-0">
          <div class="font-medium">Value</div>
          <input v-model="tax_rate.value" class="form-control" />
        </div>
      </div>
      <div
        class="flex items-center cursor-pointer mt-5"
        @click.prevent="tax_rate.isDefault = !tax_rate.isDefault"
      >
        <div class="relative">
          <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner"></div>
          <div class="switch-toggle" :class="{ _active: tax_rate.isDefault }"></div>
        </div>
        <div class="ml-2 text-gray-700 font-medium whitespace-nowrap">
          This <span v-text="tax_rate.isDefault ? 'is' : 'is not'"></span> the default tax rate
        </div>
      </div>
      <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
        <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
          Cancel
        </button>
        <button class="btn-action btn-lg" @click.prevent.stop="saveTaxRate">Save Tax Rate</button>
      </div>
    </modal-window>
  </Teleport>
</template>

<script>
import validate from 'validate.js';
import { mapStores } from 'pinia'
import { useCustomerStore } from '@/pinia/customer';

export default {
  props: ['create_tax_rate_popup_open'],
  data() {
    return {
      tax_rate: {
        isDefault: false,
      },
    };
  },
  computed: {
    ...mapStores(useCustomerStore),
  },
  methods: {
    async saveTaxRate() {
      const validationErrors = validate(
        {
          name: this.tax_rate.name,
          value: this.tax_rate.value,
        },
        {
          name: {
            presence: { allowEmpty: false },
          },
          value: {
            presence: { allowEmpty: false },
          },
        },
      );

      if (validationErrors) {
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        return;
      }

      this.customerStore.staffAddTaxRate(this.tax_rate).then(() => {
        this.$emit('created');
        this.$emit('close');
      });
    },
  },
};
</script>
